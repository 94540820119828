import React from "react";
import { Frame, RowWrapper } from "../ui-kit/styled-templates";
import { SectionHeader } from "../pages/museum-page";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled, { css } from "styled-components";
import { BASE_URL } from "../../constants/config";
import EventItem from "../ui-kit/eventItem";
import SeeMore from "../ui-kit/see-more";

const EventsBlock = ({ dictionary = {}, events = [], variant = "dark" }) => {
    // Sort by update data
    const sortedEvents = events.sort((a, b) => new Date(b.event_date) - new Date(a.event_date));
    console.log(`sortedEvents ==>`, sortedEvents);
    return (
        <>
            <RowWrapper
                extra={css`
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 15px;
                    box-sizing: border-box;
                    @media (max-width: 768px) {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 40px;
                    }
                `}
            >
                <SectionHeader
                    serif
                    extra={css`
                        width: min-content;
                    `}
                    variant={variant}
                >
                    {dictionary?.events_heading}
                </SectionHeader>
                {events?.length >= 2 && <SeeMore to={`/events`}>{dictionary?.see_more_button}</SeeMore>}
            </RowWrapper>
            <RowWrapper
                extra={css`
                    gap: 1rem;
                    justify-content: space-between;
                    align-items: flex-start;
                    @media (max-width: 768px) {
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                `}
            >
                {sortedEvents?.slice?.(0, 2)?.map(({ id, title, description, event_date, event_location, event_price, photo }, index) => {
                    return (
                        <EventItem
                            key={index}
                            title={title}
                            description={description}
                            event_date={event_date}
                            event_location={event_location}
                            event_price={event_price}
                            photo={photo}
                            index={index}
                            event_id={id}
                        />
                    );
                })}
            </RowWrapper>
        </>
    );
};

export default EventsBlock;
