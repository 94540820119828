/*eslint-disable*/
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";

import { Frame, H1, Image, RowWrapper } from "../ui-kit/styled-templates";
import PopUpWrapper from "./pop-up-wrapper";

import { MODALS } from "../../constants/config";

import useModal from "../../hooks/useModal";
import { useStorageListener } from "../../hooks/useStorage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ModalsHelper from "../../utils/modals-helper";
import useLocale from "../../hooks/useLocale";

const EventsCalendarModal = () => {
    const state = useStorageListener((state) => state?.modals?.EVENTS_CALENDAR_MODAL?.state ?? {});
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const { locale } = useLocale();
    // console.log(`state ==>`, state);
    return (
        <PopUpWrapper
            name={MODALS.EVENTS_CALENDAR_MODAL}
            extra={css`
                border-radius: ${({ theme }) => theme.borderRad.small};
                padding: 2rem 1rem;

                @media only screen and (max-width: 600px) {
                    border-radius: ${({ theme }) => theme.borderRad.small};
                    padding: 1.5rem 0.75rem;
                }
            `}
        >
            <H1 serif extra={`text-align: center;`}>
                {state?.date}
            </H1>
            <p>
                {state?.events?.length} {locale === "en" ? "events" : locale === "lv" ? "notikumi" : locale === "ru" ? "событий" : "events"}
            </p>
            <Frame
                extra={css`
                    width: 100%;
                    gap: 1rem;
                    max-height: 490px;
                    overflow: auto;
                    border-radius: ${({ theme }) => theme.borderRad.small};
                    justify-content: flex-start;

                    a {
                        width: 100%;
                    }
                `}
            >
                {state?.events?.map((event) => {
                    return (
                        <Link
                            to={`/museum/event/${event.title.toLowerCase().replaceAll(" ", "_")}?id=${event.id}`}
                            onClick={() => ModalsHelper.hideModal(MODALS.EVENTS_CALENDAR_MODAL)}
                            key={event.id}
                        >
                            <RowWrapper
                                extra={css`
                                    border-radius: ${({ theme }) => theme.borderRad.small};
                                    overflow: hidden;
                                    max-height: 150px;
                                    height: 100%;
                                    color: ${({ theme }) => theme.text.secondary};

                                    @media only screen and (max-width: 500px) {
                                        flex-direction: column;
                                        max-height: unset;
                                    }
                                `}
                            >
                                <Image
                                    src={event.photo.formats.small}
                                    alt={event.photo.alternativeText}
                                    extra={css`
                                        max-width: 30%;
                                        min-height: 150px;

                                        @media only screen and (max-width: 500px) {
                                            max-width: 100%;
                                            min-height: unset;
                                            max-height: 150px;
                                        }
                                    `}
                                />
                                <Frame
                                    extra={css`
                                        width: 100%;
                                        background: ${({ theme }) => theme.background.light};
                                        height: 100%;
                                        justify-content: space-between;
                                        box-sizing: border-box;
                                        align-items: flex-start;
                                    `}
                                >
                                    <Frame
                                        extra={css`
                                            box-sizing: border-box;
                                            padding: 1rem;
                                            width: 100%;
                                            h3 {
                                                margin: 0;
                                                padding: 0;
                                                font-family: "Constantia";
                                                font-style: normal;
                                                font-weight: 500;

                                                width: 100%;
                                                box-sizing: border-box;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                            }
                                        `}
                                    >
                                        <h3>{event.title}</h3>
                                    </Frame>
                                    <InfoGrid>
                                        {[
                                            [dictionary?.date_subheading, event.event_date],
                                            [dictionary?.price_subheading, event.event_price],
                                            [dictionary?.location_subheading, event.event_location],
                                        ].map(([label, value], index) => (
                                            <Frame
                                                extra={css`
                                                    align-items: flex-start;
                                                    justify-content: flex-start;
                                                    gap: 0.5rem;

                                                    span {
                                                        font-family: "Constantia";
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        font-size: 14px;

                                                        /* Color */
                                                        color: #523634;
                                                    }

                                                    p {
                                                        margin: 0;
                                                        padding: 0;
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                    }
                                                `}
                                                key={index}
                                            >
                                                <span>{label}</span>
                                                <p>{value}</p>
                                            </Frame>
                                        ))}
                                    </InfoGrid>
                                </Frame>
                            </RowWrapper>
                        </Link>
                    );
                })}
            </Frame>
        </PopUpWrapper>
    );
};

const InfoGrid = styled(RowWrapper)`
    gap: 0.5rem;
    width: 100%;
    background: #8d7363;
    padding: 1rem;
    box-sizing: border-box;
`;

export default EventsCalendarModal;
/*eslint-enable*/
