import React from "react";
import { Frame, Image, RowWrapper } from "../ui-kit/styled-templates";
import styled, { css } from "styled-components";
import { BASE_URL } from "../../constants/config";

const ContactsBlock = ({ people = [] }) => {
    return (
        <ContactsBlockWrapper>
            {people?.map?.((contact, index) => {
                return (
                    <ContactsItem.Wrapper key={index}>
                        <Image
                            src={contact?.photo}
                            alt={contact?.photo.alternativeText.length === 0 ? contact?.name + " photo" : contact?.photo.alternativeText}
                            extra={css`
                                max-width: 235px;
                                max-height: 325px;

                                @media only screen and (max-width: 500px) {
                                    max-width: unset;
                                    max-height: unset;
                                }
                            `}
                        />
                        <ContactsItem.Name serif>{contact?.name}</ContactsItem.Name>
                        <Frame>{contact?.description}</Frame>
                        <Frame>{contact?.email}</Frame>
                        <Frame>{contact?.phone}</Frame>
                    </ContactsItem.Wrapper>
                );
            })}
        </ContactsBlockWrapper>
    );
};

const ContactsBlockWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const ContactsItem = {
    Wrapper: styled(Frame)`
        justify-content: flex-start;
        align-items: flex-start;
        color: ${({ theme }) => theme.text.secondary};
        gap: 10px;
        font-style: normal;
        font-weight: normal;
        max-width: 235px;

        @media only screen and (max-width: 500px) {
            max-width: unset;
        }
    `,

    Name: styled(Frame)`
        font-size: ${({ theme }) => theme.font.contacts};
        line-height: ${({ theme }) => theme.font.contactsLh};
        color: ${({ theme }) => theme.background.secondary};
    `,
};

export default ContactsBlock;
