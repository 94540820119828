/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import moment from "moment-timezone";
import CircleType from "circletype";
import CircledText from "../tools/circled-text";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import {
    Page,
    ShortInfoHeading,
    ShortInfoDescription,
    ShortInfoRightImage,
    CircleText,
    aboutCircleText,
    SectionHeader,
    Collections,
    OpeningImage,
    PedagogicalProgrammsImage,
    PedagogicalProgrammsButton,
    GallerArrow,
    PhotoItem,
    Events,
    NewsItem,
    ContactsItem,
    BottomButton,
    BottomButtonSVG,
} from "../pages/museum-page";
import GoogleMapByIframe from "../tools/google-map-by-iframe";

import { BASE_URL, MODALS } from "../../constants/config";
import ModalsHelper from "../../utils/modals-helper";

import { putStorage, useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import useLocale from "../../hooks/useLocale";
import theme from "../../constants/theme-constants";
import ContactsBlock from "../museums-ui-blocks/contacts-block";
import MuseumContactBlock from "../museums-ui-blocks/museum-contact-block";
import NewsBlock from "../museums-ui-blocks/news-block";
import GalleryBlock from "../museums-ui-blocks/gallery-block";
import AboutBlock, { SectionParagraph } from "../museums-ui-blocks/about-block";
import MuseumsAPI from "../../api/museums-api";

const AboutMuseumPage = () => {
    const { pathname } = useLocation();
    const { locale } = useLocale();
    const path = pathname.split(`/`).slice(1);
    const museumAlias = path[1];

    useEffect(() => {
        MuseumsAPI.getAboutMuseum();
        MuseumsAPI.getMuseumsNewsData();
        MuseumsAPI.getEvents();
        MuseumsAPI.getDictionary();
        MuseumsAPI.getMuseumsData();
    }, [locale]);

    const museum = useStorageListener((state) => state?.["about-museum"] ?? {});
    const museums = useStorageListener((state) => state?.museums ?? {});
    const news = useStorageListener((state) => state?.news ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const galleryPagination = usePagination(museum?.gallery_photos ?? [], {
        perPage: window.innerWidth <= 425 ? 1 : window.innerWidth <= 768 ? 2 : window.innerWidth <= 1024 ? 4 : 6,
        scrollDisabled: true,
    });
    const [objectiveId, setObjectiveId] = useState(museum?.objectives?.[0]?.id);
    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);

    // Circle text
    // const accreditationCircleText = useRef();
    // const aboutCircleText = useRef();
    // const servicesCircleText = useRef();
    // const pedagogicalCircleText = useRef();

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () =>
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text }),
    };

    useEffect(() => {
        setObjectiveId(museum?.objectives?.[0]?.id);
    }, [locale, museum]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    // console.log(museum);
    // console.log(museums);
    return (
        <Page extra={`overflow: hidden;`}>
            {/* ABOUT MUSEUM */}
            <Container variant={`light`} extra={`overflow: hidden; position: relative;`} id="about">
                <AboutBlock
                    heading={dictionary?.about_museum_heading}
                    paragraph={museum?.about_museum_description}
                    imageSrc={museum?.about_museum_photo}
                    variant={`light`}
                />
                <a href={`/about-museum/#${buttonBottomToggle ? `top` : `bottom`}`}>
                    <BottomButton>
                        <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                    </BottomButton>
                </a>
            </Container>
            {/* OBJECTIVES */}
            <Container variant={`dark`} id="objectives" extra={`overflow: hidden;`}>
                <SectionHeader serif variant={`dark`}>
                    {dictionary?.objectives_heading}
                </SectionHeader>
                <RowWrapper
                    extra={css`
                        width: 100%;
                        position: relative;
                        gap: 80px 100px;
                        z-index: 2;
                        align-items: flex-start;

                        @media (max-width: 1024px) {
                            flex-direction: column;
                            align-items: center;
                        }
                    `}
                >
                    <Frame
                        serif
                        extra={css`
                            width: 100%;
                            align-items: flex-start;
                            gap: 1rem;

                            @media (max-width: 1024px) {
                                align-items: center;
                            }
                        `}
                    >
                        {museum?.objectives?.map?.((objective, index) => {
                            return (
                                <Collections.NavItem key={index} id={index} onClick={() => setObjectiveId(objective?.id)}>
                                    {objective.objective}
                                </Collections.NavItem>
                            );
                        })}
                    </Frame>
                    <Collections.TextWrapper>
                        <Collections.Back></Collections.Back>
                        <Collections.Content>
                            <Markdown>{museum?.objectives?.find?.((i) => i?.id === objectiveId)?.objective_description}</Markdown>
                        </Collections.Content>
                    </Collections.TextWrapper>
                    <Collections.BackText serif extra={`bottom:-20%; z-index: -1;`}>
                        {museum?.objectives_heading}
                    </Collections.BackText>
                </RowWrapper>
            </Container>
            {/* STRATEGY */}
            <Container variant={`dark`} id="strategy">
                <RowWrapper
                    extra={css`
                        flex-direction: row;
                        align-items: flex-start;
                        gap: 2rem;

                        @media only screen and (max-width: 1030px) {
                            flex-direction: column;
                        }
                    `}
                >
                    <Image
                        src={museum?.strategy_photo}
                        extra={css`
                            border-radius: ${({ theme }) => theme.borderRad.primary} 0;

                            @media only screen and (max-width: 770px) {
                                border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
                            }
                        `}
                    />
                    <Strategy.Desc
                        extra={css`
                            max-width: unset;
                        `}
                    >
                        <SectionHeader serif variant={`dark`}>
                            {dictionary.strategy_heading}
                        </SectionHeader>
                        <SectionParagraph
                            extra={css`
                                color: ${({ theme }) => theme.text.secondary};
                            `}
                        >
                            <Markdown>{museum?.strategy_description}</Markdown>
                        </SectionParagraph>
                    </Strategy.Desc>
                </RowWrapper>
            </Container>
            {/* ACCREDETATION */}
            <Container variant={`dark`} id="accreditation">
                <Frame extra={`align-items: flex-start; position: relative;`}>
                    <AboutBlock
                        heading={dictionary?.accreditation_heading}
                        paragraph={museum?.accreditation_description ?? ``}
                        imageSrc={museum?.accreditation_photo}
                        paragraphStyles={css`
                            color: ${({ theme }) => theme.text.secondary};
                        `}
                        variant={`dark`}
                    />

                    <Accreditation.ButttonWrapper>
                        {museum?.accreditation_documents?.map?.((document) => {
                            return (
                                <PedagogicalProgrammsButton
                                    key={document.id}
                                    onClick={handlers.openMuseumModal({ title: document?.document_name, text: document?.document_text })}
                                >
                                    {document.document_name}
                                </PedagogicalProgrammsButton>
                            );
                        })}
                    </Accreditation.ButttonWrapper>
                    {/* <CircleText
                    serif
                    ref={accreditationCircleText}
                    extra={({ theme }) => `top:0; right:20%; color: ${theme.text.secondary}; opacity:0.5; z-index: 2;`}
                    >
                    {dictionary?.accreditation_heading} {dictionary?.accreditation_heading}{" "}
                </CircleText> */}
                    <CircledText text={dictionary?.accreditation_heading} top={150} left={1000} radius={100} color={`#ffffff`} opacity={0.5} />
                </Frame>
            </Container>
            {/* GALLERY */}
            {galleryPagination?.visibleItems?.length > 0 && (
                <Container variant={`light`} extra={`align-items: flex-start; overflow:hidden;`} id="gallery">
                    <GalleryBlock dictionary={dictionary} galleryPagination={galleryPagination} variant={`light`} />
                </Container>
            )}
            {/* NEWS */}
            {museum?.news?.length > 0 && (
                <Container variant={`dark`} extra={`align-items: flex-start; overflow:hidden;`} id="news">
                    <NewsBlock dictionary={dictionary} news={news} variant={`dark`} />
                </Container>
            )}
            {/* CONTACTS */}
            <Container variant={`dark`} extra={`align-items: flex-start; `} id="contacts">
                <SectionHeader serif variant={`dark`}>
                    {dictionary?.contacts_heading}
                </SectionHeader>
                {museum?.people?.length > 0 && <ContactsBlock people={museum?.people} />}
            </Container>
            {/* CONTACT MAP */}
            <Container variant={`dark`} extra={`align-items: flex-start; `} id="contacts">
                <MuseumContactBlock light contact={museum?.contact} sendMessageText={dictionary?.send_message_button} />
            </Container>
        </Page>
    );
};

const Accreditation = {
    ButttonWrapper: styled.div`
        display: grid;
        width: 100%;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);

        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    `,
};

export const Strategy = {
    Image: styled(OpeningImage)`
        max-width: 600px;
        width: 100%;
        max-height: 660px;
        height: ${({ src = {} }) => src?.height}px;
        border-radius: ${({ theme }) => theme.borderRad.primary} 0;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 425px) {
            border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
        }

        /* div {
            width: 100%;
            height: 100%;
            border-radius: ${({ theme }) => theme.borderRad.primary} 0px 0px 0px;
            position: absolute;
            top: 0;
            left: 10%;
            background: ${({ theme }) => theme.background.light};
            z-index: -1;
            @media only screen and (max-width: 425px) {
                border-radius: ${({ theme }) => theme.borderRad.primarySm} 0px 0px 0px;
            }
        } */

        ${({ src = {} }) =>
            src?.url &&
            css`
                background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / cover;
            `}
    `,
    Desc: styled(Frame)`
        width: 100%;
        height: 100%;
        border: 1px solid ${({ theme }) => theme.background.secondary};
        box-sizing: border-box;
        border-radius: ${({ theme }) => theme.borderRad.primary} 0px;
        padding: 5rem 3rem 3rem 3rem;

        @media screen and (max-width: 768px) {
            padding: 2rem 1rem 1rem 1rem;
            border-radius: ${({ theme }) => theme.borderRad.primarySm} 0px;
        }
        ${({ extra }) => extra}
    `,
};

export const AccreditationImage = styled(Strategy.Image)`
    border-radius: ${({ theme }) => theme.borderRad.primary} 0;

    @media only screen and (max-width: 425px) {
        border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
    }

    /* max-width: 750px;
    width: 100%;
    height: 477px;
    border-radius: 0 100px;
    background: url("${BASE_URL}${({ url }) => url?.url}") no-repeat center center / cover;
    background-position: 100% auto;
    position: relative;

    &:after {
        display: none;
    } */
`;

export default AboutMuseumPage;
/*eslint-enable*/
