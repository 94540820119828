import React from "react";
import { Frame } from "../ui-kit/styled-templates";
import styled, { css } from "styled-components";

const CircledText = ({ text = ``, fontSize = 16, radius = 100, color, top, left, right, bottom, opacity }) => {
    return (
        <Wrapper
            radius={radius}
            extra={`position: absolute;
        top: ${top}%;
        left: ${left}%;
        right: ${right}%;
        bottom: ${bottom}%;`}
        >
            {text?.split?.(``)?.map?.((i, j) => (
                <P
                    key={j}
                    serif
                    extra={({ theme }) => css`
                        position: absolute;
                        top: 0;
                        font-size: 2em;
                        color: ${color};
                        transform-origin: 0 100%;
                        height: ${radius}px;
                        opacity: ${opacity};
                        transform: rotate(${(j * 360) / text.length}deg);
                    `}
                >
                    {i}
                </P>
            ))}
        </Wrapper>
    );
};

const Wrapper = styled(Frame)`
    width: ${({ radius }) => radius * 2}px;
    height: ${({ radius }) => radius * 2}px;
    animation: rotating 20s linear infinite;
    z-index: -1;

    @media screen and (max-width: 425px) {
        display: none;
    }
    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
const P = styled.p`
    margin: 0;
    min-width: 30px;

    ${({ serif }) =>
        serif &&
        css`
            /* font-family: Crimson Text; */
            font-family: Playfair Display;
        `}
    ${({ extra }) => extra}
`;

export default CircledText;
