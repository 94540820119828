import React from "react";
import styled, { css } from "styled-components";
import { Container, Frame, RowWrapper } from "../ui-kit/styled-templates";
import { Collections, SectionHeader } from "../pages/museum-page";
import Markdown from "../tools/markdown";
import Button from "../ui-kit/button";

const PricesBlock = ({
    museumAlias,
    museum,
    pricesHeading,
    prices,
    priceToggle,
    setPriceToggle,
    publicationButtonName,
    buyButtonName,
    ticketLinks,
}) => {
    const renderRailwayPrices = (direction, directionPricesItems) => {
        return (
            <Frame
                extra={css`
                    align-items: flex-start;
                `}
            >
                <Frame
                    serif
                    extra={({ theme }) =>
                        css`
                            h3 {
                                font-size: ${theme.font.railwayHistoryBottomHeader};
                                color: ${theme.background.secondary};
                                margin-top: 0;
                            }
                            p {
                                font-size: ${theme.font.railwayHistoryBottomSubHeader};
                                color: ${theme.text.secondary};
                                margin: 0;
                            }
                            margin-bottom: 20px;
                        `
                    }
                >
                    <Markdown>{direction ?? ``}</Markdown>
                </Frame>
                <PricesRow>
                    {directionPricesItems.map((price, pricesIndex) => {
                        return (
                            <PricesItem.Wrapper
                                key={pricesIndex}
                                extra={`&:nth-child(2n){margin-top:50%;} @media (max-width:768px){&:nth-child(2n){margin-top:0%;}}`}
                            >
                                <Frame serif extra={`font-size: 24px; line-height: 29px; margin-bottom: 44px; font-weight: bold;`}>
                                    {price?.name}
                                </Frame>
                                <Frame extra={`font-size: 40px; line-height: 59px; margin-bottom: 20px;`}>{price?.sum}</Frame>
                                <Frame>
                                    <Markdown>{price?.description ?? ``}</Markdown>
                                </Frame>
                            </PricesItem.Wrapper>
                        );
                    })}
                </PricesRow>
            </Frame>
        );
    };

    const filterTicketLinks = (ticketLinks, museumAlias) => {
        let museumID;

        if (museumAlias === "livonijas") {
            museumID = 1;
        } else if (museumAlias === "seaside") {
            museumID = 2;
        } else if (museumAlias === "amatu") {
            museumID = 3;
        } else museumID = 4;

        return ticketLinks?.filter((item) => item?.id === museumID)?.[0]?.mobilly_link;
    };

    const renderPriceItem = (price, isLastPrice, index) => {
        if (isLastPrice) {
            return (
                <PricesItem.Wrapper
                    key={index}
                    extra={
                        priceToggle
                            ? `display: flex; margin-top: 0px; width: min-content !important; @media (max-width: 500px) {width: 100% !important;}`
                            : `display: none;`
                    }
                >
                    <Frame serif extra={`font-size: 24px; line-height: 29px; margin-bottom: 44px; font-weight: bold;`}>
                        {price?.name}
                    </Frame>
                    <Frame extra={`font-size: 40px; line-height: 59px; width: 100%; margin-bottom: 20px;`}>{price?.sum}</Frame>
                    <Frame>
                        <Markdown>{price?.description ?? ``}</Markdown>
                    </Frame>
                </PricesItem.Wrapper>
            );
        }

        return (
            <PricesItem.Wrapper key={index} extra={`&:nth-child(2n){margin-top:50%;} @media (max-width:768px){&:nth-child(2n){margin-top:0%;}}`}>
                <Frame serif extra={`font-size: 24px; line-height: 29px; margin-bottom: 44px; font-weight: bold;`}>
                    {price?.name}
                </Frame>
                <Frame extra={`font-size: 40px; line-height: 59px; width: 100%; margin-bottom: 20px;`}>{price?.sum}</Frame>
                <Frame>
                    <Markdown>{price?.description ?? ``}</Markdown>
                </Frame>
                <Button
                    link
                    external
                    type="primary"
                    href={filterTicketLinks(ticketLinks, museumAlias)}
                    target="_blank"
                    rel="noreferrer"
                    extra={css`
                        min-width: unset;
                        width: 100%;
                    `}
                >
                    {buyButtonName}
                </Button>
            </PricesItem.Wrapper>
        );
    };

    if (museumAlias === "dorbes") {
        return null;
    }

    return (
        <Container variant={`dark`} extra={`align-items: flex-start; overflow: hidden; position:relative;`} id="prices">
            <SectionHeader serif variant={`dark`}>
                {pricesHeading}
            </SectionHeader>

            <Frame
                extra={css`
                    gap: 2rem;
                    @media only screen and (max-width: 500px) {
                        gap: 1rem;
                    }
                `}
            >
                {museumAlias === "railway" && (
                    <>
                        {renderRailwayPrices(museum?.railway_direction_1, museum?.prices_railway_direction_1s)}
                        {renderRailwayPrices(museum?.railway_direction_2, museum?.prices_railway_direction_2s)}
                    </>
                )}
            </Frame>

            {prices?.length > 0 && (
                <Frame
                    extra={css`
                        gap: 50px;
                        align-items: flex-start;
                    `}
                >
                    <PricesRow>
                        {prices?.map((price, index) => {
                            // const isLastPrice = index === prices.length - 1;

                            // if (isLastPrice) {
                            //     return;
                            // }
                            return renderPriceItem(price, false, index);
                        })}
                    </PricesRow>

                    {museumAlias === "livonijas" && (
                        <Frame key={prices.length - 1} extra={`gap: 1rem; z-index: 5; align-items:flex-start;`}>
                            <Button
                                type="primary"
                                onClick={() => setPriceToggle(!priceToggle)}
                                extra={css`
                                    color: ${({ theme }) => theme.text.secondary};
                                    font-family: "Playfair Display";
                                    padding: 0;
                                    margin: 0;
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                `}
                            >
                                {publicationButtonName} <PlusIcon src={require(`../../assets/icons/plus.svg`).default} alt="Plus icon" />
                            </Button>

                            {renderPriceItem(prices[prices.length - 1], true, prices.length - 1)}
                        </Frame>
                    )}
                </Frame>
            )}

            <Collections.BackText serif extra={`bottom:0;`}>
                {pricesHeading}
            </Collections.BackText>
        </Container>
    );
};

const PlusIcon = styled.img`
    width: 20px;
    height: 20px;
    display: block;
`;

const PricesRow = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    z-index: 2;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 770px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

export const PricesItem = {
    Wrapper: styled(Frame)`
        width: 100%;
        height: min-content;
        padding: 15px 37px;
        box-sizing: border-box;
        background: ${({ theme }) => theme.background.secondary};
        font-family: Roboto;
        font-size: 16px;
        line-height: 23px;
        color: ${({ theme }) => theme.text.primary};

        &:nth-child(2n) {
            border-bottom-right-radius: ${({ theme }) => theme.borderRad.primarySm};
        }
        &:nth-child(2n + 1) {
            border-top-right-radius: ${({ theme }) => theme.borderRad.primarySm};
        }
    `,
    Button: styled.a`
        width: 100%;
        height: 58px;
        background: ${({ theme }) => theme.background.primary};
        border-radius: 5px;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        color: ${({ theme }) => theme.background.secondary};
        cursor: pointer;
        padding: 15px 10px;
        box-sizing: border-box;
        text-align: center;

        &:hover {
            color: ${({ theme }) => theme.text.secondary};
        }
    `,
};

export default PricesBlock;
