/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import moment from "moment-timezone";
import CircledText from "../tools/circled-text";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";

import { BASE_URL, MODALS } from "../../constants/config";
import { togglePush } from "../../utils/common-helper";

import { useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import ModalsHelper from "../../utils/modals-helper";
import GoogleMapByIframe from "../tools/google-map-by-iframe";
import theme from "../../constants/theme-constants";
import ContactsBlock from "../museums-ui-blocks/contacts-block";
import MuseumContactBlock from "../museums-ui-blocks/museum-contact-block";
import NewsBlock from "../museums-ui-blocks/news-block";
import EventsBlock from "../museums-ui-blocks/events-block";
import GalleryBlock from "../museums-ui-blocks/gallery-block";
import AboutBlock, { SectionParagraph } from "../museums-ui-blocks/about-block";
import PricesBlock from "../museums-ui-blocks/prices-block";
import OpeningHoursBlock from "../museums-ui-blocks/opening-hours-block";
import { TableOuterWrapper, TableWrapper, TimeTable } from "../museums-ui-blocks/railway-schedule";
import Button from "../ui-kit/button";
import SeeMore from "../ui-kit/see-more";

export const getHtmlFromText = (txt) => {
    if (txt == undefined) {
        return "";
    }
    return txt.replace(/\n/g, "<br/>");
};

export const getFormattedComponentFromText = (txt) => {
    let html = getHtmlFromText(txt);
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

const MuseumPage = () => {
    const [selectedPhotoRange, setSelectedPhotoRange] = useState(0);
    const [selectedExpositionsRange, setSelectedExpositionsRange] = useState(0);
    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);
    const [priceToggle, setPriceToggle] = useState(false);
    const [railwayObjectSeeMore, setRailwayObjectSeeMore] = useState([]);

    const { pathname } = useLocation();
    const path = pathname.split(`/`).slice(1);
    const museumAlias = path[1];

    const museums = useStorageListener((state) => state?.museums ?? []);
    const museum = museums?.find?.((i) => i?.alias === museumAlias ?? {}) ?? {};
    const news = useStorageListener((state) => state?.news?.filter?.((i) => i?.museum?.id === museum?.id) ?? []);
    const ticketLinks = useStorageListener((state) => state?.[`ticket-links`] ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const { publications = [], people = [], prices = [] } = museum;

    // Service group choise
    const [serviceGroup, setServiceGroup] = useState(museum?.services);

    const servicesPagination = usePagination(serviceGroup ?? [], {
        perPage: museumAlias === "seaside" ? 4 : museumAlias === "dorbes" ? 2 : window.innerWidth <= 1024 ? 1 : 2,
        scrollDisabled: true,
    });

    const galleryPagination = usePagination(museum?.gallery_photos ?? [], {
        perPage: window.innerWidth <= 425 ? 1 : window.innerWidth <= 768 ? 2 : window.innerWidth <= 1024 ? 4 : 6,
        scrollDisabled: true,
    });
    const expositionsPagination = usePagination(museum?.expositions?.sort?.((a, b) => a?.order - b?.order) ?? [], {
        perPage: window.innerWidth <= 425 ? 1 : window.innerWidth <= 768 ? 2 : window.innerWidth <= 1024 ? 4 : 6,
        scrollDisabled: true,
    });

    const handleRailwayObjectsSeeMore = (index) => {
        if (!railwayObjectSeeMore.includes(index)) {
            setRailwayObjectSeeMore([...railwayObjectSeeMore, index]);
        } else {
            setRailwayObjectSeeMore(railwayObjectSeeMore.filter((item) => item !== index));
        }
    };
    // Circle text
    // const mainCircleText = useRef();
    // const aboutCircleText = useRef();
    // const servicesCircleText = useRef();
    // const pedagogicalCircleText = useRef();

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () => {
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text });
            },
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    // useEffect(() => {
    //     if (mainCircleText.current) {
    //         new CircleType(mainCircleText.current);
    //     }
    //     if (aboutCircleText.current) {
    //         new CircleType(aboutCircleText.current);
    //     }
    //     if (servicesCircleText.current) {
    //         new CircleType(servicesCircleText.current);
    //     }
    //     if (pedagogicalCircleText.current) {
    //         new CircleType(pedagogicalCircleText.current);
    //     }
    // }, [mainCircleText, aboutCircleText, servicesCircleText, pedagogicalCircleText]);

    // Choose collection description
    const [collectionDesc, setCollectionDesc] = useState([museum?.collections[0]]);

    // Read more
    const [readMore, setReadMore] = useState([]);

    // console.log(ticketLinks);
    // console.log(museums);
    // console.log(news);
    // console.log(museumAlias);
    // console.log(window.innerWidth <= 1024 ? 4);

    // console.log(`museum ==>`, museum);

    const subBarData = [
        {
            name: museum?.short_info_heading,
            href: "#about",
            ariaLabel: "Link to about block",
            renderCase: museum?.short_info_description?.length > 0,
        },
        {
            name: dictionary?.rail_facilities_heading,
            href: "#railwayobjects",
            ariaLabel: "Link to railway objects block",
            renderCase: museum?.railway_objects?.length > 0,
        },
        {
            name: dictionary?.railway_history_heading,
            href: "#railwayhistory",
            ariaLabel: "Link to railway history block",
            renderCase: museum?.railway_histories?.length > 0,
        },
        {
            name: dictionary?.expositions_heading,
            href: "#expositions",
            ariaLabel: "Link to expositions block",
            renderCase: museum?.expositions?.length > 0 || museum?.expositions_text?.length > 0,
        },
        {
            name: dictionary?.collections_heading,
            href: "#collections",
            ariaLabel: "Link to collections block",
            renderCase: museum?.collections?.length > 0,
        },
        {
            name: dictionary?.services_heading,
            href: "#services",
            ariaLabel: "Link to services block",
            renderCase: museum?.services?.length > 0,
        },
        {
            name: dictionary?.pedagogical_programs_heading,
            href: "#pedagogical",
            ariaLabel: "Link to pedagogical block",
            renderCase: museum?.pedagogical_program?.program_text?.length > 0,
        },
        {
            name: dictionary?.prices_heading,
            href: "#prices",
            ariaLabel: "Link to prices block",
            renderCase: prices?.length > 0,
        },
        {
            name: dictionary?.prices_heading,
            href: "#prices",
            ariaLabel: "Link to prices block",
            renderCase: museum?.prices_railway_direction_1s?.length > 0 || museum?.prices_railway_direction_2s?.length > 0,
        },
        {
            name: dictionary?.opening_hours_heading,
            href: "#openinghours",
            ariaLabel: "Link to opening hours block",
            renderCase: museum?.opening_hours?.length > 0,
        },
        {
            name: dictionary?.gallery_heading,
            href: "#gallery",
            ariaLabel: "Link to gallery block",
            renderCase: museum?.gallery_photos?.length > 0,
        },
        {
            name: dictionary?.news_heading,
            href: "#news",
            ariaLabel: "Link to news block",
            renderCase: news?.length > 0,
        },
        {
            name: dictionary?.events_heading,
            href: "#events",
            ariaLabel: "Link to events block",
            renderCase: museum?.events?.length > 0,
        },
        {
            name: dictionary?.railway_schedule_heading,
            href: "#railwaymovement",
            ariaLabel: "Link to railway movement block",
            renderCase: museum?.railway_schedules?.length > 0,
        },
        {
            name: dictionary?.contacts_heading,
            href: "#people",
            ariaLabel: "Link to people block",
            renderCase: museum?.people?.length > 0,
        },
    ];

    return (
        <>
            <Page extra={`position: relative;`} id="top">
                {/* MAIN */}
                <Container variant={`dark`}>
                    <RowWrapper
                        extra={css`
                            gap: 1rem;
                            align-items: center;
                            @media (max-width: 1024px) {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                            }
                        `}
                    >
                        <Frame
                            extra={css`
                                position: relative;
                                z-index: 0;
                                width: 100%;
                            `}
                        >
                            <TopBanner.Wrapper>
                                <Image
                                    src={museum?.tob_banner_image}
                                    alt={museum?.tob_banner_image?.alternativeText}
                                    extra={css`
                                        max-width: 660px;
                                        max-height: 440px;
                                        border-radius: ${theme.borderRad.primary} 0 ${theme.borderRad.primary} 0;

                                        @media screen and (max-width: 768px) {
                                            border-radius: ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm} 0;
                                        }
                                    `}
                                />
                                <TopBanner.Subtext>{museum?.top_banner_sub_text}</TopBanner.Subtext>
                            </TopBanner.Wrapper>

                            <CircledText
                                text={museum?.name}
                                top={-15}
                                left={-25}
                                radius={museum?.name?.length <= 5 ? 50 : museum?.name?.length <= 10 ? 100 : museum?.name?.length <= 20 ? 150 : 200}
                                color={`#86554C`}
                            />
                        </Frame>
                        <Name>{museum?.name}</Name>

                        <BottomButton
                            href={`/museum/${museumAlias}#${buttonBottomToggle ? `top` : `bottom`}`}
                            rel="nofollow"
                            aria-label="Toogle scroll to bottom and to top of the page"
                        >
                            <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                        </BottomButton>
                    </RowWrapper>
                </Container>
                <PageNav.Submenu>
                    <PageNav.SubmenuWrapper>
                        {subBarData.map((link) => {
                            if (link.renderCase) {
                                return (
                                    <a href={link.href} aria-label={link.ariaLabel}>
                                        {link.name}
                                    </a>
                                );
                            }
                            return null;
                        })}
                    </PageNav.SubmenuWrapper>
                </PageNav.Submenu>
                {/* ABOUT MUSEUM */}
                <Container variant={`light`} extra={`overflow: hidden; position: relative; z-index: 0;`} id="about">
                    <AboutBlock
                        heading={museum?.short_info_heading}
                        paragraph={museum?.short_info_description}
                        imageSrc={museumAlias === "railway" ? museum?.short_info_left_image : museum?.short_info_right_image}
                        circleText={museum?.short_info_heading}
                        museumAlias={museumAlias}
                    />
                </Container>
                {/* RAILWAY OBJECTS */}
                {museumAlias === "railway" && (
                    <Container variant={`dark`} extra={``} id="railwayobjects">
                        <Frame extra={`width: 100%; align-items: flex-start;`}>
                            <SectionHeader serif variant={`dark`}>
                                {dictionary?.rail_facilities_heading}
                            </SectionHeader>
                            <RailwayObjectsWrapper>
                                {museum?.railway_objects?.map?.((railwayObject, index) => {
                                    return (
                                        <RailwayObjectItem.Wrapper key={index}>
                                            <Frame
                                                extra={css`
                                                    justify-content: flex-end;
                                                    min-height: 4rem;
                                                    align-items: center;
                                                    width: 100%;
                                                `}
                                            >
                                                <RailwayObjectItem.Title>{railwayObject?.title?.trim()}</RailwayObjectItem.Title>
                                            </Frame>
                                            <RailwayObjectItem.Content>
                                                <Image
                                                    src={railwayObject?.object_photo}
                                                    extra={
                                                        index % 2 === 0
                                                            ? `max-width: 400px; max-height: 280px; aspect-ratio: 2 / 1.4; border-top-left-radius: 6.25rem; border-top-left-radius:${theme.borderRad.primary}; @media (max-width: 770px) {border-top-left-radius:${theme.borderRad.primarySm};}`
                                                            : `max-width: 400px; max-height: 280px; aspect-ratio: 2 / 1.4; border-top-right-radius: ${theme.borderRad.primary}; @media (max-width: 770px) {border-top-right-radius:${theme.borderRad.primarySm};}`
                                                    }
                                                />
                                                <RailwayObjectItem.List
                                                    extra={
                                                        railwayObject?.objects_list.length > 180 && !railwayObjectSeeMore.includes(index)
                                                            ? `display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden;`
                                                            : ``
                                                    }
                                                >
                                                    <Markdown>{railwayObject?.objects_list ?? ``}</Markdown>
                                                </RailwayObjectItem.List>
                                                {railwayObject?.objects_list.length > 180 && (
                                                    <Button type="secondary" onClick={() => handleRailwayObjectsSeeMore(index)}>
                                                        {!railwayObjectSeeMore.includes(index) ? dictionary?.read_more : dictionary?.read_less}
                                                    </Button>
                                                )}
                                            </RailwayObjectItem.Content>
                                        </RailwayObjectItem.Wrapper>
                                    );
                                })}
                            </RailwayObjectsWrapper>
                            <Frame extra={`align-items: flex-start; width:100%; margin-top: 2rem; gap: 2rem;`}>
                                <RailwayTicketOffice.Title serif extra={({ theme }) => `color: ${theme.background.secondary}; text-align:left;`}>
                                    {dictionary?.railway_ticket_office_subheading}
                                </RailwayTicketOffice.Title>
                                <RowWrapper extra={`align-items: flex-start; gap:2rem; @media (max-width: 770px){flex-direction:column;}`}>
                                    <SectionParagraph
                                        extra={css`
                                            color: ${({ theme }) => theme.text.secondary};
                                            max-width: 500px;
                                            font-weight: normal;
                                            font-size: ${theme.font.paragraph};
                                            align-items: flex-start;
                                            border: 1px solid ${theme.background.secondary};
                                            border-radius: 0px ${theme.borderRad.primarySm};
                                            padding: 2.5rem 2rem;
                                            box-sizing: border-box;

                                            @media (max-width: 770px) {
                                                max-width: unset;
                                                width: 100%;
                                            }

                                            @media (max-width: 425px) {
                                                border-radius: 0px ${theme.borderRad.primarySm};
                                                padding: 2rem 1rem;
                                            }
                                        `}
                                    >
                                        {museum?.railway_ticket_office_description}
                                    </SectionParagraph>

                                    <Image
                                        src={museum?.railway_ticket_office_photo}
                                        extra={`width: 60%; max-width: 700px; @media (max-width: 770px){width: 100%;}`}
                                    />
                                </RowWrapper>
                            </Frame>
                        </Frame>
                    </Container>
                )}
                {/* RAILWAY HYSTORY */}
                {museumAlias === "railway" && (
                    <Container variant={`dark`} id="railwayhistory">
                        <Frame extra={`gap: 3rem;`}>
                            <Frame extra={`align-items:flex-start;  width: 100%; `}>
                                <SectionHeader serif variant={`dark`}>
                                    <Frame>{dictionary?.railway_history_heading}</Frame>
                                </SectionHeader>
                                <RowWrapper
                                    extra={css`
                                        gap: 1rem;
                                        align-items: flex-start;
                                        @media (max-width: 1438px) {
                                            justify-content: center;

                                            flex-direction: column;
                                        }
                                    `}
                                >
                                    <RailwayHistory.Text extra={`max-width: 610px; width: 100%;`}>
                                        <Markdown>{museum?.railway_history_text}</Markdown>
                                    </RailwayHistory.Text>
                                    <Image
                                        src={museum?.railway_history_main_image}
                                        extra={css`
                                            border-radius: 0 ${theme.borderRad.primary} 0 ${theme.borderRad.primary};
                                            @media (max-width: 1438px) {
                                                border-radius: 0px ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm};
                                            }
                                        `}
                                    />
                                </RowWrapper>
                            </Frame>
                            <Frame
                                extra={css`
                                    align-items: flex-start;
                                    width: 100%;
                                    gap: 2.5rem;
                                `}
                            >
                                {museum?.railway_histories?.map?.((history, index, self) => {
                                    return (
                                        <RowWrapper
                                            key={index}
                                            extra={`align-items:flex-start; gap: 2rem; @media (max-width: 1024px){flex-direction: column-reverse; align-items: center;}`}
                                        >
                                            <Frame
                                                extra={css`
                                                    gap: 3rem;
                                                    @media only screen and (max-width: 500px) {
                                                        gap: 1rem;
                                                    }
                                                `}
                                            >
                                                <Frame
                                                    extra={css`
                                                        position: relative;
                                                        z-index: 1;
                                                    `}
                                                >
                                                    <RailwayHistories.ImageBackground imageIndex={index} />
                                                    <Image
                                                        src={history.photo}
                                                        extra={
                                                            index % 2 === 0
                                                                ? `border-top-left-radius: ${theme.borderRad.primary}; width: 100%; height: 100%;`
                                                                : `border-radius: 0px ${theme.borderRad.primary} 0px ${theme.borderRad.primary}; `
                                                        }
                                                    />
                                                </Frame>
                                                {museum?.railway_histories?.length !== index + 1 && <RailwayHistories.ImageLine />}
                                            </Frame>

                                            <RailwayHistories.Wrapper index={index}>
                                                <RailwayHistories.Title serif>{history.title}</RailwayHistories.Title>
                                                <RailwayHistories.Description
                                                    index={index}
                                                    extra={
                                                        !readMore?.includes?.(history?.id) &&
                                                        `display: -webkit-box; -webkit-line-clamp: 10; -webkit-box-orient: vertical; overflow: hidden;`
                                                    }
                                                >
                                                    <Markdown>{history.description}</Markdown>
                                                </RailwayHistories.Description>
                                                <SeeMore as="button" onClick={() => setReadMore(togglePush(readMore, history?.id))}>
                                                    {readMore?.includes?.(history?.id) ? dictionary?.read_less : dictionary?.read_more}
                                                </SeeMore>
                                            </RailwayHistories.Wrapper>
                                        </RowWrapper>
                                    );
                                })}
                            </Frame>

                            <RowWrapper
                                extra={css`
                                    justify-content: space-between;
                                    width: 100%;
                                    gap: 2rem;
                                    @media (max-width: 1438px) {
                                        justify-content: center;
                                        flex-wrap: wrap;
                                    }
                                `}
                            >
                                <Frame
                                    extra={css`
                                        width: 100%;
                                        gap: 1rem;

                                        h3 {
                                            margin: 0;
                                            font-family: "Constantia";
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: ${theme.font.railwayHistoryBottomHeader};
                                        }
                                    `}
                                >
                                    <Image
                                        src={museum?.railway_history_bottom_left_image}
                                        alt={museum?.railway_history_bottom_left_image?.alternativeText}
                                        extra={css`
                                            max-width: 610px;
                                            max-height: 380px;
                                            border-radius: 0px ${theme.borderRad.primary} 0px 0px;
                                            @media (max-width: 768px) {
                                                border-radius: 0px ${theme.borderRad.primarySm} 0px 0px;
                                            }
                                        `}
                                    />
                                    <h3>{museum?.railway_history_bottom_left_title}</h3>
                                </Frame>
                                <Frame
                                    extra={css`
                                        width: 100%;
                                        gap: 1rem;

                                        h3 {
                                            margin: 0;
                                            font-family: "Constantia";
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: ${theme.font.railwayHistoryBottomHeader};
                                        }
                                    `}
                                >
                                    <Image
                                        src={museum?.railway_history_bottom_right_image}
                                        alt={museum?.railway_history_bottom_right_image?.alternativeText}
                                        extra={css`
                                            max-width: 610px;
                                            max-height: 380px;
                                            border-radius: ${theme.borderRad.primary} 0px 0px 0px;
                                            @media (max-width: 768px) {
                                                border-radius: ${theme.borderRad.primarySm} 0px 0px 0px;
                                            }
                                        `}
                                    />
                                    <h3>{museum?.railway_history_bottom_right_title}</h3>
                                </Frame>
                            </RowWrapper>
                        </Frame>
                    </Container>
                )}
                {/* EXPOSITIONS */}
                {![`railway`, `amatu`]?.includes?.(museumAlias) && (
                    <Container variant={`dark`} extra={`align-items: flex-start;`} id="expositions">
                        <SectionHeader serif variant={`dark`}>
                            {dictionary.expositions_heading}
                        </SectionHeader>
                        <RowWrapper
                            extra={css`
                                position: relative;
                                align-items: flex-start;
                                gap: 2rem;
                                width: 100%;

                                @media (max-width: 1435px) {
                                    flex-direction: column;
                                }
                            `}
                        >
                            {/* Seaside Expositions */}
                            {museumAlias === "seaside" ? (
                                <Frame extra={`display: none;`}></Frame>
                            ) : (
                                <Frame
                                    extra={({ theme }) =>
                                        css`
                                            padding: 30px 40px;
                                            box-sizing: border-box;
                                            border: 1px solid ${theme.background.secondary};
                                            color: #ffffff;
                                            border-radius: 0px 100px;
                                            ${museumAlias === "dorbes" ? `max-width: 560px;` : `max-width: 400px;`}
                                            width: 100%;
                                            max-width: 400px;
                                            @media (max-width: 1438px) {
                                                max-width: 100%;
                                            }
                                            @media (max-width: 425px) {
                                                padding: 1rem;
                                                border-radius: 0px 50px;
                                            }
                                        `
                                    }
                                >
                                    <SectionParagraph
                                        extra={css`
                                            color: ${({ theme }) => theme.text.secondary};
                                        `}
                                    >
                                        <Markdown>{museum?.expositions_text}</Markdown>
                                    </SectionParagraph>
                                </Frame>
                            )}
                            <Frame extra={`width: 100%; z-index:5;`}>
                                {/* Dorbes expositions photo */}
                                {museumAlias === "dorbes" ? (
                                    <DorbesExpositions.Photo>
                                        <Image
                                            extra={css`
                                                border-radius: ${theme.borderRad.primary} ${theme.borderRad.primary} 0 0;
                                                @media only screen and (max-width: 768px) {
                                                    border-radius: ${theme.borderRad.primarySm} ${theme.borderRad.primarySm} 0 0;
                                                }
                                            `}
                                            src={museum?.expositions_photo}
                                            alt={museum?.expositions_photo?.alternativeText}
                                        />
                                    </DorbesExpositions.Photo>
                                ) : (
                                    <Expositions.Wrapper museumAlias={museumAlias}>
                                        {expositionsPagination?.visibleItems?.map?.(
                                            ({ photo = {}, name = ``, exposition_description = `` }, index, self) => {
                                                return (
                                                    <Expositions.Photo
                                                        key={index}
                                                        onClick={handlers.openMuseumModal({ title: name, text: exposition_description })}
                                                    >
                                                        <Image
                                                            src={photo}
                                                            alt={photo?.alternativeText}
                                                            extra={css`
                                                                max-width: 400px;
                                                                max-height: 255px;
                                                                @media only screen and (max-width: 500px) {
                                                                    max-width: unset;
                                                                    max-height: unset;
                                                                }
                                                            `}
                                                        />
                                                        <Expositions.PhotoSubText>{name}</Expositions.PhotoSubText>
                                                    </Expositions.Photo>
                                                );
                                            }
                                        )}
                                    </Expositions.Wrapper>
                                )}
                                {museumAlias === "dorbes" ? null : (
                                    <Frame extra={`flex-direction: row; position: relative; gap: 2rem;`}>
                                        <GallerArrow
                                            visible={!expositionsPagination.isFirstPage}
                                            onClick={expositionsPagination.handlePrevPage}
                                            extra={`transform: rotate(180deg); z-index: 10; width:60px; height: 60px;`}
                                        />
                                        <GallerArrow
                                            visible={!expositionsPagination.isLastPage}
                                            onClick={expositionsPagination.handleNextPage}
                                            extra={`right: 0; z-index: 10; width:60px; height: 60px;`}
                                        />
                                    </Frame>
                                )}
                            </Frame>
                            <Collections.BackText serif extra={`bottom: 0%;`}>
                                {dictionary?.expositions_heading}
                            </Collections.BackText>
                        </RowWrapper>
                    </Container>
                )}
                {/* COLLECTIONS */}
                {![`seaside`, `railway`, `amatu`]?.includes?.(museumAlias) && (!!museum?.collections_text || museum?.collections?.length) && (
                    <Container variant={`dark`} id="collections" extra={`overflow: hidden;`}>
                        <SectionHeader serif variant={`dark`}>
                            {dictionary?.collections_heading}
                        </SectionHeader>
                        <RowWrapper
                            extra={css`
                                width: 100%;
                                position: relative;
                                gap: 80px 100px;
                                z-index: 2;
                                flex-direction: column;
                                align-items: center;

                                @media (min-width: 768px) {
                                    flex-direction: row;
                                }
                            `}
                        >
                            <Frame
                                extra={css`
                                    width: 100%;
                                    align-items: flex-start;
                                    @media (max-width: 1024px) {
                                        align-items: center;
                                    }
                                `}
                            >
                                {museum?.collections?.map?.(({ collection_item = `` }, index, self) => {
                                    return (
                                        <Collections.NavItem
                                            key={index}
                                            serif
                                            onClick={(e) => {
                                                setCollectionDesc(
                                                    museum?.collections.filter((element) => {
                                                        return element.collection_item === e.target.innerHTML;
                                                    })
                                                );
                                            }}
                                        >
                                            {collection_item}
                                        </Collections.NavItem>
                                    );
                                })}
                            </Frame>
                            <Collections.TextWrapper>
                                <Collections.Back></Collections.Back>
                                <Collections.Content>
                                    <Markdown>{collectionDesc[0].collection_item_description}</Markdown>
                                </Collections.Content>
                            </Collections.TextWrapper>
                            <Collections.BackText serif extra={`bottom:-20%; z-index: -1;`}>
                                <Markdown>{dictionary?.collections_heading}</Markdown>
                            </Collections.BackText>
                        </RowWrapper>
                    </Container>
                )}
                {/* seaside */}
                {/* SERVICES */}
                {[`railway`]?.includes?.(museumAlias) ? null : museumAlias === "" ? (
                    <Container variant={`light`} extra={`align-items: flex-start; position: relative;`} id="services">
                        <RowWrapper>
                            <SectionHeader
                                serif
                                extra={css`
                                    color: ${theme.text.primary};
                                `}
                            >
                                {dictionary?.services_heading}
                            </SectionHeader>
                        </RowWrapper>
                        <RowWrapper
                            extra={css`
                                position: relative;
                                align-items: flex-start;
                                z-index: 5;
                            `}
                        >
                            <Frame extra={`flex-direction: row; width:100%;`}>
                                <Frame
                                    extra={css`
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: flex-start;
                                        gap: 0px 20px;
                                        @media (max-width: 1438px) {
                                            gap: 50px 20px;
                                            flex-wrap: wrap;
                                        }
                                    `}
                                >
                                    {servicesPagination?.visibleItems?.map?.((item, index) => (
                                        <Services.Item key={index} {...item}>
                                            <Markdown>{item?.description ?? ``}</Markdown>
                                        </Services.Item>
                                    ))}
                                </Frame>
                                <Frame extra={``}>
                                    <Expositions.Arrow
                                        visible={!servicesPagination.isFirstPage}
                                        onClick={servicesPagination.handlePrevPage}
                                        extra={`transform: rotate(180deg); position: relative !important;`}
                                    />
                                    <Expositions.Arrow visible={!servicesPagination.isLastPage} onClick={servicesPagination.handleNextPage} />
                                </Frame>
                            </Frame>
                        </RowWrapper>
                        <CircledText
                            text={dictionary?.services_heading}
                            bottom={0}
                            left={0}
                            radius={
                                dictionary?.services_heading?.length <= 5
                                    ? 25
                                    : dictionary?.services_heading?.length <= 10
                                    ? 100
                                    : dictionary?.services_heading?.length <= 20
                                    ? 100
                                    : 150
                            }
                            color={`#523634`}
                        />
                    </Container>
                ) : (
                    <Container variant={`light`} extra={`align-items: flex-start; position: relative;`} id="services">
                        <SectionHeader serif variant={`light`}>
                            {dictionary?.services_heading}
                        </SectionHeader>
                        <RowWrapper
                            extra={css`
                                position: relative;
                                align-items: flex-start;
                                justify-content: space-between;
                                gap: 50px 22px;
                                z-index: 5;
                                @media (max-width: 765px) {
                                    flex-direction: column;
                                    align-items: center;
                                }
                            `}
                        >
                            {museum?.sevices_navs.length === 0 ? null : (
                                <Frame
                                    extra={css`
                                        width: 100%;
                                        max-width: 400px;
                                    `}
                                >
                                    {museum?.sevices_navs?.map?.(({ servise_item, id }, index) => (
                                        <Services.NavItem
                                            key={index}
                                            serif
                                            onClick={() => {
                                                setServiceGroup(
                                                    museum?.services?.filter?.((element) => {
                                                        return element.services_list === id;
                                                    })
                                                );
                                                servicesPagination.resetCurrentPage();
                                            }}
                                        >
                                            {servise_item}
                                        </Services.NavItem>
                                    ))}
                                </Frame>
                            )}
                            <Frame
                                extra={
                                    museum?.services.length === 2
                                        ? `justify-content: space-between; flex-direction: row; width: 100%;`
                                        : `justify-content: center; gap: 40px 0px; max-width: 780px; width: 100%;`
                                }
                            >
                                <RowWrapper
                                    extra={css`
                                        gap: 50px 10px;
                                        width: 100%;
                                        align-items: stretch;
                                        flex-wrap: wrap;
                                        @media (max-width: 1024px) {
                                            flex-direction: column;
                                            align-items: center;
                                        }
                                    `}
                                >
                                    {servicesPagination?.visibleItems?.map?.((item, index) => (
                                        <Services.Item
                                            key={index}
                                            {...item}
                                            extra={
                                                museum?.services.length === 2
                                                    ? `max-width: 606px; width: 100%; height: auto; padding: 46px 50px 70px 73px; box-sizing: border-box; &:nth-child(2) {border-radius: 0px 100px 0px 0px;}`
                                                    : `flex-basis: calc(50% - 10px);`
                                            }
                                        >
                                            <Markdown>{item?.description ?? ``}</Markdown>
                                        </Services.Item>
                                    ))}
                                </RowWrapper>
                                <Frame extra={museum?.services.length === 2 ? `display: none;` : `flex-direction: row; gap: 0px 18px;`}>
                                    <Expositions.Arrow
                                        visible={!servicesPagination.isFirstPage}
                                        onClick={servicesPagination.handlePrevPage}
                                        extra={`transform: rotate(180deg); position: relative !important;`}
                                    />
                                    <Expositions.Arrow visible={!servicesPagination.isLastPage} onClick={servicesPagination.handleNextPage} />
                                </Frame>
                            </Frame>
                        </RowWrapper>
                        <CircledText
                            text={dictionary?.services_heading}
                            bottom={0}
                            left={0}
                            radius={
                                dictionary?.services_heading?.length <= 5
                                    ? 25
                                    : dictionary?.services_heading?.length <= 10
                                    ? 100
                                    : dictionary?.services_heading?.length <= 20
                                    ? 150
                                    : 150
                            }
                            color={`#523634`}
                        />
                    </Container>
                )}
                {/* PEDAGOGICAL PROGRAMMS */}
                {["seaside", "dorbes", "livonijas", "amatu"]?.includes?.(museumAlias) && (
                    <Container variant={`dark`} extra={`align-items: flex-start; position: relative; overflow: hidden;`} id="pedagogical">
                        <Frame
                            extra={css`
                                align-items: flex-start;
                            `}
                        >
                            <SectionHeader serif variant={`dark`}>
                                <Frame>{dictionary?.pedagogical_programs_heading}</Frame>
                            </SectionHeader>
                            <RowWrapper
                                extra={css`
                                    position: relative;
                                    align-items: flex-start;
                                    gap: 50px 20px;
                                    z-index: 5;
                                    @media (max-width: 1438px) {
                                        flex-direction: column;
                                    }
                                `}
                            >
                                <Frame
                                    extra={({ theme }) => css`
                                        * {
                                            color: #ffffff;
                                        }
                                        max-width: 762px;
                                        width: 100%;
                                        box-sizing: border-box;
                                        font-size: ${theme.font.paragraph};
                                        line-height: ${theme.font.paragraphLh};
                                        font-weight: normal;
                                        align-items: flex-start;
                                        p > strong {
                                            color: ${theme.background.secondary};
                                            font-size: ${theme.font.paragraph};
                                            line-height: ${theme.font.paragraphLh};
                                        }
                                        @media (max-width: 425px) {
                                            font-size: ${theme.font.paragraphSm};
                                            line-height: ${theme.font.paragraphLh};
                                            p > strong {
                                                font-size: ${theme.font.paragraphSm};
                                                line-height: ${theme.font.paragraphLh};
                                            }
                                        }
                                    `}
                                >
                                    <Markdown>{museum?.pedagogical_program?.program_text ?? ``}</Markdown>
                                </Frame>
                                <PedagogicalProgrammsImage url={museum?.pedagogical_program} />
                            </RowWrapper>
                            <PedagogicalProgrammsWrapper>
                                {museum?.pedagogical_programs_options?.map?.((item) => {
                                    return (
                                        <PedagogicalProgrammsButton
                                            key={item.id}
                                            onClick={handlers.openMuseumModal({ title: item?.program_option, text: item?.option_info })}
                                        >
                                            {item.program_option}
                                        </PedagogicalProgrammsButton>
                                    );
                                })}
                            </PedagogicalProgrammsWrapper>
                            <CircledText
                                text={dictionary?.pedagogical_programs_heading}
                                top={0}
                                right={0}
                                radius={
                                    dictionary?.services_heading?.length <= 5
                                        ? 100
                                        : dictionary?.services_heading?.length <= 10
                                        ? 150
                                        : dictionary?.services_heading?.length <= 20
                                        ? 200
                                        : 300
                                }
                                color={`#ffffff`}
                                opacity={0.5}
                            />
                        </Frame>
                    </Container>
                )}
                {/* PRICES */}
                <PricesBlock
                    museumAlias={museumAlias}
                    museum={museum}
                    pricesHeading={dictionary?.prices_heading}
                    prices={prices}
                    priceToggle={priceToggle}
                    setPriceToggle={setPriceToggle}
                    publicationButtonName={dictionary?.publications_button}
                    buyButtonName={dictionary.buy_button}
                    ticketLinks={ticketLinks}
                />
                {/* OPENING HOURS */}
                {museum?.alias !== "railway" && <OpeningHoursBlock museum={museum} openingHoursHeading={dictionary?.opening_hours_heading} />}
                {/*Gallery */}
                {galleryPagination?.visibleItems?.length > 0 && (
                    <Container
                        variant={["seaside", "railway", "amatu", "dorbes"].includes(museumAlias) ? `light` : `dark`}
                        extra={`align-items: flex-start; overflow:hidden;`}
                        id="gallery"
                    >
                        <GalleryBlock dictionary={dictionary} galleryPagination={galleryPagination} />
                    </Container>
                )}
                {/* NEWS */}
                {news.length > 0 && (
                    <Container variant={`dark`} extra={`align-items: flex-start;`} id="news">
                        <NewsBlock dictionary={dictionary} news={news} variant={`dark`} />
                    </Container>
                )}
                {/* EVENTS */}
                {museum?.events.length > 0 && (
                    <Container variant={`dark`} id="events">
                        <EventsBlock dictionary={dictionary} events={museum?.events} variant={`dark`} />
                    </Container>
                )}
                {/* CONTACTS */}
                {museumAlias !== "railway" && (
                    <>
                        <Container
                            variant={`dark`}
                            extra={css`
                                align-items: flex-start;
                                gap: 100px;
                                @media (max-width: 425px) {
                                    gap: 50px;
                                }
                            `}
                            id="people"
                        >
                            <SectionHeader serif variant={`dark`}>
                                {dictionary?.contacts_heading}
                            </SectionHeader>
                            {people?.length > 0 && <ContactsBlock people={people} />}
                        </Container>
                        {/* CONTACT MAP */}
                        <Container variant={`dark`}>
                            <MuseumContactBlock light contact={museum?.contact} sendMessageText={dictionary?.send_message_button} />
                        </Container>
                    </>
                )}
                {/* RAILWAY MOVEMENT LIST */}
                {museumAlias === "railway" && (
                    <Container variant={`light`} id="railwaymovement">
                        <SectionHeader serif>{dictionary?.railway_schedule_heading}</SectionHeader>
                        <Frame
                            extra={css`
                                gap: 4rem;
                                @media only screen and (max-width: 500px) {
                                    gap: 3rem;
                                }
                            `}
                        >
                            <Frame
                                extra={css`
                                    width: 100%;
                                    gap: 50px;

                                    @media (min-width: 768px) {
                                        flex-direction: row;
                                        justify-content: space-between;
                                    }
                                `}
                            >
                                <Frame
                                    serif
                                    extra={`font-weight: normal; font-size: 20px; line-height: 140%; max-width: 579px; width:100%;
                                    @media (max-width: 425px) {
                                        font-size: 18px;
                                    }`}
                                >
                                    <Markdown>{museum?.railway_schedule_text}</Markdown>
                                </Frame>
                                <Frame extra={`position: relative; width: 100%; max-width: 610px;`}>
                                    <RailwayMovementList.ImageWrapper src={museum?.railway_schedule_image}></RailwayMovementList.ImageWrapper>
                                    <RailwayMovementList.Image className={"railway_schedule_image"} src={museum?.railway_schedule_image} />
                                </Frame>
                            </Frame>
                            <RowWrapper
                                extra={css`
                                    align-items: stretch;
                                    gap: 2rem;

                                    @media only screen and (max-width: 770px) {
                                        flex-direction: column;
                                    }
                                `}
                            >
                                <TableOuterWrapper
                                    extra={css`
                                        @media only screen and (min-width: 769px) {
                                            width: 40% !important;
                                        }
                                    `}
                                >
                                    <TableWrapper borderType="dark">
                                        <TimeTable>
                                            <caption>
                                                <RailwayMovementList.Title serif>{dictionary?.railway_schedule_subheading}</RailwayMovementList.Title>
                                            </caption>
                                            <tbody>
                                                {museum?.railway_schedules?.map((element) => {
                                                    return (
                                                        <tr key={element?.id}>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary};`}>
                                                                    {moment(element.departure_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                                </Frame>
                                                            </td>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary};`}>~</Frame>
                                                            </td>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary};`}>
                                                                    {moment(element.arrival_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                                </Frame>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </TimeTable>
                                    </TableWrapper>
                                </TableOuterWrapper>

                                <TableOuterWrapper
                                    extra={css`
                                        @media only screen and (min-width: 769px) {
                                            width: 40% !important;
                                        }
                                    `}
                                >
                                    <TableWrapper borderType="dark">
                                        <TimeTable>
                                            <caption>
                                                <RailwayMovementList.Title serif>
                                                    {dictionary?.railway_break_time_subheading}
                                                </RailwayMovementList.Title>
                                            </caption>
                                            <tbody>
                                                {museum?.railway_schedule_break_times?.map((element) => {
                                                    return (
                                                        <tr key={element?.id}>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                    {moment(element.start_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                                </Frame>
                                                            </td>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>~</Frame>
                                                            </td>
                                                            <td>
                                                                <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                    {moment(element.end_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                                </Frame>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </TimeTable>
                                    </TableWrapper>
                                </TableOuterWrapper>
                            </RowWrapper>
                            <RowWrapper
                                extra={css`
                                    align-items: flex-start;
                                    gap: 2rem;

                                    @media only screen and (max-width: 770px) {
                                        flex-direction: column;
                                    }
                                `}
                            >
                                <Image
                                    src={museum?.railway_schedule_image_2}
                                    alt={museum?.railway_schedule_image_2?.alternativeText}
                                    extra={css`
                                        max-width: unset;
                                        max-height: unset;
                                    `}
                                />
                                <GoogleMapByIframe iframeAsString={museum?.contact?.contact_location} />
                            </RowWrapper>
                        </Frame>
                        {/* {museum?.railway_schedule_image_2 == undefined || museum?.railway_schedule_image_2 == "" ? null : (
                            <RowWrapper
                                extra={css`
                                    width: 100%;
                                    gap: 50px 70px;
                                    align-items: center;
                                    justify-content: center;
                                    @media (max-width: 1024px) {
                                        flex-direction: column;
                                        align-items: center;
                                    }
                                `}
                            >
                                <Frame extra={`position: relative; width: 100%; max-width: 610px;`}>
                                    <RailwayMovementList.Image
                                        style={{ backgroundSize: "contain" }}
                                        className={"railway_schedule_image2"}
                                        src={museum?.railway_schedule_image_2}
                                    />
                                </Frame>
                            </RowWrapper>
                        )}
                        <RowWrapper
                            extra={css`
                                align-items: flex-start;
                                flex-wrap: wrap;
                                @media (max-width: 1438px) {
                                    justify-content: center;
                                    gap: 100px 50px;
                                }
                            `}
                        >
                            <TableOuterWrapper
                                extra={css`
                                    @media only screen and (min-width: 769px) {
                                        width: 40% !important;
                                    }
                                `}
                            >
                                <TableWrapper borderType="dark">
                                    <TimeTable>
                                        <tbody>
                                            {museum?.railway_schedules?.map((element) => {
                                                return (
                                                    <tr key={element?.id}>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                {moment(element.departure_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                            </Frame>
                                                        </td>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>~</Frame>
                                                        </td>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                {moment(element.arrival_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                            </Frame>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </TimeTable>
                                </TableWrapper>
                            </TableOuterWrapper>

                            <TableOuterWrapper
                                extra={css`
                                    @media only screen and (min-width: 769px) {
                                        width: 40% !important;
                                    }
                                `}
                            >
                                <TableWrapper borderType="dark">
                                    <TimeTable>
                                        <caption>
                                            <RailwayMovementList.Title serif>{dictionary?.railway_break_time_subheading}</RailwayMovementList.Title>
                                        </caption>
                                        <tbody>
                                            {museum?.railway_schedule_break_times?.map((element) => {
                                                return (
                                                    <tr key={element?.id}>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                {moment(element.start_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                            </Frame>
                                                        </td>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>~</Frame>
                                                        </td>
                                                        <td>
                                                            <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                                {moment(element.end_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                            </Frame>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </TimeTable>
                                </TableWrapper>
                            </TableOuterWrapper>
                        </RowWrapper>
                        <GoogleMapByIframe iframeAsString={museum?.contact?.contact_location} /> */}
                    </Container>
                )}
            </Page>
        </>
    );
};
export const BottomButtonSVG = styled.div`
    width: 60%;
    height: 100%;
    background: url("${require(`../../assets/icons/main-arrow-white-down.svg`).default}") no-repeat center center / contain;
    margin: 0 auto;
    transition: 0.2s ease-in-out;
    ${({ extra }) => extra}
`;
export const BottomButton = styled.a`
    width: 66px;
    height: 66px;
    background: ${({ theme }) => theme.background.light};
    border-radius: 100%;
    position: fixed;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
    z-index: 20;

    &:before {
        content: "";
        width: 66px;
        height: 66px;
        border: 1px solid #ba7464;
        border-radius: 100%;
        position: absolute;
        top: 8px;
        left: -4px;
    }
    @media (max-width: 1438px) {
        z-index: 10;
    }
`;
export const CircleText = styled(Frame)`
    color: ${({ theme }) => theme.background.light};
    font-size: 50px;
    position: absolute;
    z-index: 0;
    @media (max-width: 1438px) {
        opacity: 0.5;
    }
    ${({ extra }) => extra}
`;

const PageNav = {
    SubmenuWrapper: styled(RowWrapper)`
        width: 100%;
        max-width: calc(1440px);
        flex-wrap: wrap;
        gap: 0.5rem;

        a {
            text-decoration: none;
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            color: ${({ theme }) => theme.background.primary};

            &:hover {
                border-bottom: 1px solid ${({ theme }) => theme.background.primary};
            }
            @media (max-width: 425px) {
                font-size: 14px;
            }
        }
    `,

    Submenu: styled(RowWrapper)`
        width: 100%;
        position: sticky;
        top: 100px;
        min-height: 70px !important;
        background: #eee2d1;
        padding: 1rem;
        box-sizing: border-box;

        z-index: 9;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            top: 69px;
        }
    `,
};

export const Events = {
    Item: {
        Wrapper: styled(Frame)`
            width: 100%;
            max-width: 610px;

            a {
                width: 100%;
                height: 100%;
            }
            ${({ extra }) => extra}
        `,
        Photo: styled(Frame)`
            max-width: 610px;
            max-height: 250px;
            min-width: ${({ src = {} }) => src?.width}px;
            height: ${({ src = {} }) => src?.height}px;
            background: url("${({ src }) => `${BASE_URL}${src?.url}`}") no-repeat top center / cover;
            /* background-size: 100% auto; */
            ${({ extra }) => extra}
        `,
        Body: styled(Frame)`
            padding: 20px;
            box-sizing: border-box;
            width: 100%;
            align-items: flex-start;
            background: #977d6b;
            color: #ffffff;
        `,
        Title: styled(Frame)`
            font-weight: bold;
            font-size: 24px;
            line-height: 48px;
            margin-bottom: 15px;
            text-transform: uppercase;
        `,
        Description: styled(Frame)`
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;
            margin-bottom: 20px;
        `,
        Footer: {
            Wrapper: styled(RowWrapper)`
                padding: 13px 36px;
                box-sizing: border-box;
                width: 100%;
                background: #8d7363;
                flex-wrap: wrap;
                gap: 20px 10px;
            `,
            ItemWrapper: styled(Frame)`
                width: 100%;
                flex: 1;
                align-items: flex-start;
                justify-content: flex-start;
            `,
            Label: styled(Frame)`
                font-size: 14px;
                line-height: 18px;
                color: #523634;
                margin-bottom: 10px;
                ${({ extra }) => extra}
            `,
            Value: styled(Frame)`
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                ${({ extra }) => extra}
            `,
        },
    },
    Wrapper: styled(RowWrapper)`
        > * {
            overflow: hidden;
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(2n) {
                border-top-right-radius: 100px;
            }
            &:nth-child(2n + 1) {
                border-top-left-radius: 100px;
            }
        }
    `,
    SeeMore: styled(Frame)`
        font-size: 24px;
        line-height: 48px;
        letter-spacing: -0.022em;
        color: #ffffff;
        flex-direction: row;

        position: relative;

        &:after {
            content: "";
            width: 53px;
            min-width: 53px;
            height: 20px;
            background: url("${require(`../../assets/icons/long-right-arrow-yellow.svg`).default}") no-repeat center center / contain;
            background-size: 100% auto;
            margin-left: 25px;
            transition: 0.2s;
        }
        &:before {
            content: "";
            height: 39px;
            width: 39px;
            border-radius: 100%;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            position: absolute;
            right: -14px;
        }
        cursor: pointer;
        &:hover {
            &:after {
                transform: translate(3px, 0);
            }
        }

        @media (max-width: 425px) {
            font-size: 20px;
        }
        ${({ extra }) => extra}
    `,
};

export const PedagogicalProgrammsImage = styled(Frame).attrs((props) => {
    return { ...props, serif: true };
})`
    max-width: 750px;
    width: 100%;
    height: 477px;
    border-radius: 0 ${({ theme }) => theme.borderRad.primary};
    background: url("${BASE_URL}${({ url }) => url?.program_photo?.url}") no-repeat center center / cover;
    /* background-position: 100% auto; */
    position: relative;

    @media screen and (max-width: 768px) {
        border-radius: 0 ${({ theme }) => theme.borderRad.primarySm};
    }
`;

export const PedagogicalProgrammsWrapper = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media only screen and (max-width: 770px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

export const PedagogicalProgrammsButton = styled(RowWrapper)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    gap: 1rem;

    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.paragraph};
    line-height: ${({ theme }) => theme.font.paragraphLh};

    color: ${({ theme }) => theme.text.secondary};
    background: ${({ theme }) => theme.background.light};
    cursor: pointer;
    padding: 26px 36px;
    box-sizing: border-box;

    &:after {
        content: "";
        max-width: 33px;
        width: 100%;
        height: 33px;
        background: url("${require(`../../assets/icons/plus.svg`).default}") no-repeat center center / contain;
        background-size: 100% auto;
    }
    &:hover {
        background: ${({ theme }) => theme.background.secondary};
    }

    @media (max-width: 1024px) {
        flex-basis: calc(50% - 0.5rem);
    }

    @media (max-width: 770px) {
        flex-basis: 100%;
    }

    @media (max-width: 425px) {
        font-size: ${({ theme }) => theme.font.paragraphSm};
        line-height: ${({ theme }) => theme.font.paragraphLh};
    }
`;

export const OpeningImage = styled(Frame)`
    max-width: ${({ src = {} }) => src?.width}px;
    width: 100%;
    height: ${({ src = {} }) => src?.height}px;
    ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / cover;`}
    position: relative;
    border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
`;

export const OpeningImageText = styled(Frame)`
    color: ${({ theme }) => theme.text.primary};
    margin-top: 14px;
    font-family: Constantia;
    font-size: 16px;
    line-height: 20px;
`;

export const Collections = {
    NavItem: styled(RowWrapper)`
        padding: 1.25rem 1.25rem 1.25rem 0;
        box-sizing: border-box;
        gap: 0px 10px;

        width: 100%;
        border-bottom: 2px solid #ffffff;
        cursor: pointer;
        color: ${({ theme }) => theme.background.secondary};
        position: relative;

        font-size: ${({ theme }) => theme.font.objectives};
        line-height: ${({ theme }) => theme.font.objectives};

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.objectivesSm};
            line-height: ${({ theme }) => theme.font.objectivesSm};
            padding: 1rem;
        }

        &:after {
            content: "";
            width: 53px;
            min-width: 53px;
            height: 10px;
            background: url("${require(`../../assets/icons/long-right-arrow-white.svg`).default}") no-repeat center center / contain;
            background-size: 100% auto;
            transition: 0.2s;
        }

        &:before {
            content: "";
            width: 39px;
            height: 39px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            position: absolute;
            right: 0;
        }

        &:hover {
            color: white;
            font-size: 30px;
            line-height: 37px;
            &:after {
                transform: translate(2px, 0);
            }
            /* &:before {
                transform: translate(2px, 0);
            } */
        }
    `,

    Back: styled.div`
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -10%;
        left: 10%;
        border: 2px solid ${({ theme }) => theme.background.secondary};
        border-radius: ${({ theme }) => theme.borderRad.primary} ${({ theme }) => theme.borderRad.primary} 0px 0px;
        /* transform: translate(-10%, -10%); */
        z-index: -1;

        @media (max-width: 768px) {
            top: -5%;
            left: 5%;
            padding: 1rem;
            border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
        }
    `,

    Content: styled.span`
        box-sizing: border-box;
        border-radius: ${({ theme }) => theme.borderRad.primary} ${({ theme }) => theme.borderRad.primary} 0px 0px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(2px);
        width: 100%;
        height: 100%;
        padding: 3rem 2.25rem 2.5rem 2.25rem;
        font-size: ${({ theme }) => theme.font.paragraph};
        line-height: ${({ theme }) => theme.font.paragraphLh};

        @media (max-width: 768px) {
            font-size: ${({ theme }) => theme.font.paragraphSm};
            line-height: ${({ theme }) => theme.font.paragraphLh};
            padding: 1rem;
            border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
        }
    `,

    TextWrapper: styled(Frame)`
        color: ${({ theme }) => theme.text.primary};
        max-width: 660px;
        border-radius: ${({ theme }) => theme.borderRad.primary} ${({ theme }) => theme.borderRad.primary} 0px 0px;
        box-sizing: border-box;
        position: relative;

        @media (max-width: 425px) {
            padding: 1rem 0;
            border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
        }
    `,
    BackText: styled(Frame)`
        font-weight: normal;
        font-size: 200px;
        line-height: 244px;
        opacity: 0.2;
        color: ${({ theme }) => theme.text.secondary};
        position: absolute;
        right: 0%;
        text-transform: capitalize;
        z-index: 1;
        ${({ extra }) => extra}
    `,
};

const Services = {
    NavItem: styled(RowWrapper)`
        padding: 1.25rem 1.25rem 1.25rem 0;
        box-sizing: border-box;
        max-width: 400px;
        border-bottom: 2px solid #523634;
        font-size: 24px;
        line-height: 29px;
        flex: 1;
        cursor: pointer;
        position: relative;

        &:after {
            content: "";
            width: 53px;
            min-width: 53px;
            height: 10px;
            display: flex;
            background: url("${require(`../../assets/icons/long-right-arrow.svg`).default}") no-repeat center center / contain;
            background-size: 100% auto;
            transition: 0.2s;
        }
        &:before {
            content: "";
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 39px;
            height: 39px;
            border: 1px solid #523634;
        }

        &:hover {
            font-size: 30px;
            line-height: 37px;
            &:after {
                transform: translate(2px, 0);
            }
        }
        @media (max-width: 425px) {
            font-size: 20px;
        }
    `,
    Item: styled(Frame)`
        padding: 40px 30px;
        box-sizing: border-box;
        /* max-width: 300px; */
        width: 100%;
        /* max-height: 500px; */
        min-height: 300px;
        justify-content: flex-start;
        align-items: flex-start;
        background: ${({ theme }) => theme.background.light};
        border-top-left-radius: ${({ theme }) => theme.borderRad.primary};
        font-size: ${({ theme }) => theme.font.servicesDesc};
        line-height: ${({ theme }) => theme.font.paragraphLh};
        color: #ffffff;
        overflow-y: scroll;
        gap: 30px;

        div,
        span {
            width: 100%;
        }

        &:before {
            content: "${({ name }) => name}";
            color: ${({ theme }) => theme.text.secondary};
            font-family: Playfair Display;
            font-size: ${({ theme }) => theme.font.services};
            line-height: ${({ theme }) => theme.font.paragraphLh};
            width: 100%;
            align-items: flex-end;
            text-align: right;
            word-wrap: break-word;
        }

        @media (max-width: 768px) {
            padding: 1.5rem 1rem;
            max-width: unset;
            border-top-left-radius: ${({ theme }) => theme.borderRad.primarySm};
            gap: 15px;
            font-size: ${({ theme }) => theme.font.servicesDescSm};
            line-height: ${({ theme }) => theme.font.paragraphLh};
            &:before {
                font-size: ${({ theme }) => theme.font.servicesSm};
                line-height: ${({ theme }) => theme.font.paragraphLh};
            }
        }
        ${({ extra }) => extra}
    `,
};

export const Expositions = {
    Wrapper: styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: ${({ museumAlias }) => (museumAlias === "seaside" ? `repeat(3, 1fr)` : `repeat(2, 1fr)`)};
        gap: 0rem 1rem;
        align-items: stretch;

        @media only screen and (max-width: 770px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    `,

    Photo: styled(Frame)`
        position: relative;
        justify-content: flex-start;
        align-items: flex-end;
        cursor: pointer;
    `,

    PhotoSubText: styled.span`
        max-width: 315px;
        width: 100%;
        height: max-content;
        font-family: "Heebo";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: ${({ theme }) => theme.text.primary};

        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(4px);
        padding: 0.5rem 1.5rem;
        box-sizing: border-box;

        transform: translate(0, -50%);
    `,
    Arrow: styled(Frame)`
        width: 60px;
        height: 55px;
        background: url("${require(`../../assets/icons/gallery-arrow-right.svg`).default}") no-repeat center center / contain;
        cursor: pointer;

        ${({ visible = false }) =>
            !visible &&
            css`
                display: none;
            `}
        ${({ extra }) => extra}
    `,
};
const DorbesExpositions = {
    Photo: styled.div`
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            border-radius: ${theme.borderRad.primary} ${theme.borderRad.primary} 0 0;
            box-sizing: border-box;
            transform: translate(5%, -5%);
            z-index: -1;
            border: 1px solid ${theme.background.secondary};

            @media screen and (max-width: 768px) {
                border-radius: ${theme.borderRad.primarySm} ${theme.borderRad.primarySm} 0 0;
            }
        }
    `,
};

export const GallerArrow = styled(Frame)`
    width: 80px;
    height: 80px;
    background: url("${require(`../../assets/icons/gallery-arrow-right.svg`).default}") no-repeat center center / contain;
    cursor: pointer;

    ${({ visible = false }) =>
        !visible &&
        css`
            display: none;
        `}
    ${({ extra }) => extra}
`;
const RailwayObjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const RailwayObjectItem = {
    Wrapper: styled(Frame)`
        gap: 1rem;
        justify-content: flex-start;
        align-items: flex-start;
        color: ${({ theme }) => theme.background.secondary};
    `,
    Title: styled.h3`
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: ${({ theme }) => theme.font.railwayObjectsTitle};
        font-family: "Playfair Display";
        text-align: center;
        width: 100%;

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.railwayObjectsTitleSm};
        }
    `,
    Content: styled(Frame)`
        align-items: center;
        width: 100%;
        gap: 1rem;
    `,
    Image: styled.img`
        max-width: 400px;
        width: 100%;
        height: 290px;
        /* background: url("${({ src = {} }) => `${BASE_URL}${src?.url}`}") no-repeat center center / cover; */
        border-radius: ${({ theme }) => theme.borderRad.primarySm} 0px;
    `,
    List: styled(Frame)`
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color: ${({ theme }) => theme.text.secondary};

        ol {
            margin-block-start: 0;
            padding-inline-start: 20px;
            margin-block-end: 0;
        }
        font-size: ${({ theme }) => theme.font.paragraph};
        line-height: ${({ theme }) => theme.font.paragraphLh};

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.paragraphSm};
            line-height: ${({ theme }) => theme.font.paragraphLh};
        }
    `,
};

const RailwayTicketOffice = {
    Title: styled(RailwayObjectItem.Title)`
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;

        ${({ extra }) => extra}

        @media (max-width: 425px) {
            font-size: 32px;
        }
    `,
    Image: styled(OpeningImage)`
        ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
    `,
};

export const RailwayMovementList = {
    Image: styled(Frame)`
        max-width: ${({ src = {} }) => src?.width}px;
        width: 100%;
        height: ${({ src = {} }) => src?.height}px;
        background: url("${({ src = {} }) => `${BASE_URL}${src?.url}`}") no-repeat center center / cover;
        border-radius: 150px 0px;
        z-index: 3;
    `,
    ImageWrapper: styled(Frame)`
        max-width: ${({ src = {} }) => src?.width}px;
        width: 100%;
        height: ${({ src = {} }) => src?.height}px;
        border-radius: 150px 0px;
        border: 2px solid ${({ theme }) => theme.background.primary};
        transform: translate(-8%, -8%);
        position: absolute;
        top: 0;
        z-index: 1;
    `,
    Map: styled(Frame)`
        width: 400px;
        height: 483px;
        ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
    `,
    Title: styled(Frame)`
        font-weight: bold;

        font-size: 20px;
        line-height: 118.57%;
        color: ${({ theme }) => theme.text.secondary};
        @media (max-width: 425px) {
            font-size: 18px;
        }
        ${({ extra }) => extra}
    `,
};

export const PhotoItem = {
    Wrapper: styled(Frame)`
        align-items: flex-end;
        position: relative;

        span {
            font-family: "Heebo";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 29px;
            color: ${({ theme }) => theme.text.secondary};
            background: rgba(82, 54, 52, 0.5);
            backdrop-filter: blur(4px);
            padding: 20px 25px;
            margin: 0;
            width: 80%;
            transform: translateY(-50%);
            min-height: 100px;
            box-sizing: border-box;
        }
    `,
};

const RailwayHistories = {
    Wrapper: styled(Frame)`
        align-items: flex-start;
        padding: 25px;
        max-width: 820px;
        width: 100%;
        min-height: 560px;
        ${({ theme, index }) => (index % 2 === 0 ? `border: 1px solid ${theme.background.secondary};` : `background: rgba(255, 255, 255, 0.2);`)}
        border-radius: ${({ theme }) => theme.borderRad.primarySm} 0px;
        box-sizing: border-box;

        @media only screen and (max-width: 770px) {
            min-height: unset;
        }

        @media only screen and (min-width: 768px) {
            border-radius: ${({ theme }) => theme.borderRad.primary} 0px;
            padding: 50px;
        }

        @media (max-width: 1438px) {
            max-width: 100%;
        }
    `,
    Image: styled(Frame)`
        /* max-width: 400px;
        height: 240px;
        width: 100%;
        z-index: 0;
        position: relative;

        @media (min-width: 1024px) {
            margin-bottom: 300px;
        } */
        /* max-width: 500px;
        width: 100%;
        height: 300px;
        z-index: 1;
        position: relative;

        ${({ index, theme }) =>
            index % 2 === 0
                ? css`
                      background: ${theme.background.light};
                  `
                : css`
                      background: transparent;
                      border: 1px solid #ffffff;
                  `}

        border-radius: ${({ index, theme }) =>
            index % 2 === 0 ? `${theme.borderRad.primarySm} 0 0 0` : `0 ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm}`};

        @media only screen and (min-width: 768px) {
            border-radius: ${({ index, theme }) =>
            index % 2 === 0 ? `${theme.borderRad.primary} 0 0 0` : `0 ${theme.borderRad.primary} 0 ${theme.borderRad.primary}`};
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
            background-size: cover;
            position: absolute;
            top: 0;
            z-index: 0;
            transform: translate(${({ index }) => (index % 2 === 0 ? "-0%, 0%" : "0%, -0%")});
            border-radius: ${({ index, theme }) =>
            index % 2 === 0 ? `${theme.borderRad.primarySm} 0 0 0` : `0 ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm}`};

            @media only screen and (min-width: 768px) {
                border-radius: ${({ index, theme }) =>
            index % 2 === 0 ? `${theme.borderRad.primary} 0 0 0` : `0 ${theme.borderRad.primary} 0 ${theme.borderRad.primary}`};
            }

            @media only screen and (min-width: 768px) {
                transform: translate(${({ index }) => (index % 2 === 0 ? "-5%, 5%" : "5%, -5%")});
            }
            @media only screen and (min-width: 1024px) {
                transform: translate(${({ index }) => (index % 2 === 0 ? "-10%, 10%" : "10%, -10%")});
            }
        }

        &:after {
            content: "";
            ${({ isTheLast = false }) => isTheLast && `display: none;`}
            width: 216px;
            height: 280px;
            background: url("${require(`../../assets/images/railway-history-line.svg`).default}") no-repeat center center / contain;
            position: absolute;
            z-index: 1;
            ${({ index }) => index % 2 === 1 && `transform: scale(-1, 1);`}
            top: calc(120% + ${({ index }) => +(index % 2 === 0) * 30}px);
            display: none;

            @media only screen and (min-width: 1438px) {
                display: block;
            }
        } */
    `,

    ImageBackground: styled.div`
        position: absolute;
        z-index: -1;
        background: ${({ imageIndex, theme }) => (imageIndex % 2 === 0 ? `${theme.background.light}` : `transparent`)};
        border: ${({ imageIndex }) => (imageIndex % 2 !== 0 ? `1px solid ${theme.borderColor.primary}` : `none`)};
        border-radius: ${({ imageIndex }) =>
            imageIndex % 2 === 0 ? `${theme.borderRad.primary} 0px 0px 0px` : ` 0px ${theme.borderRad.primary} 0px ${theme.borderRad.primary}`};
        width: 100%;
        height: 100%;

        top: 30px;
        left: -30px;

        @media screen and (max-width: 425px) {
            top: 0;
            left: 0;
        }
    `,

    ImageLine: styled.div`
        width: 216px;
        height: 280px;
        background: url("${require(`../../assets/images/railway-history-line.svg`).default}") no-repeat center center / contain;
        /* position: absolute;
        z-index: 1;
        ${({ index }) => index % 2 === 1 && `transform: scale(-1, 1);`}
        top: calc(120% + ${({ index }) => +(index % 2 === 0) * 30}px);

        @media screen and (max-width: 425px) {
            top: 110%;
        } */
    `,

    Title: styled(Frame)`
        font-style: normal;
        font-weight: normal;
        color: ${({ theme }) => theme.background.secondary};
        margin-bottom: 27px;

        font-size: ${({ theme }) => theme.font.railwayHistoryTitle};
        /* line-height: ${({ theme }) => theme.font.paragraphLh}; */

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.railwayHistoryTitleSm};
        }
    `,
    Description: styled(Frame)`
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 24px;

        font-size: ${({ theme }) => theme.font.paragraph};
        line-height: ${({ theme }) => theme.font.paragraphLh};

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.paragraphSm};
        }
    `,
};

const RailwayHistoriesLeft = {
    Wrapper: styled(Frame)`
        width: 100%;
    `,
    Image: styled(OpeningImage)`
        margin-bottom: 16px;
        width: 100%;
        height: 456px;
        border-radius: 0px;
    `,
    Title: styled(Frame)`
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 48px;
        letter-spacing: -0.022em;
        color: ${({ theme }) => theme.text.secondary};
        @media (max-width: 425px) {
            font-size: 24px;
        }
    `,
};

const RailwayHistoriesRight = {
    Wrapper: styled(Frame)`
        width: 100%;
    `,
    Image: styled(OpeningImage)`
        margin-bottom: 16px;
        width: 100%;
        height: 456px;
        border-radius: 0px;
    `,
    Title: styled(Frame)`
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 48px;
        letter-spacing: -0.022em;
        color: ${({ theme }) => theme.text.secondary};
        @media (max-width: 425px) {
            font-size: 24px;
        }
    `,
};

export const SectionHeader = styled.h2`
    font-size: ${({ theme }) => theme.font.header};
    font-weight: 500;
    font-family: "Playfair Display";
    line-height: ${({ theme }) => theme.font.header};
    color: ${({ theme, variant }) => (variant === "dark" ? theme.text.secondary : theme.text.primary)};
    margin: 0 0 2rem 0;

    ${({ extra }) => extra}
`;

export const NewsItem = {
    Title: styled(Frame)`
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #262626;
    `,
    PubDate: styled(Frame)`
        padding: 17px 25px;
        box-sizing: border-box;
        background: #8b7672;
        box-shadow: 0px 15px 30px rgba(77, 86, 196, 0.08);
        border-radius: 20px 0px 0px 0px;
        margin-right: 20px;
        font-family: Crimson Text;
        font-style: normal;
        font-weight: bold;
        line-height: 180%;
        text-align: center;
        color: #262626;
        > * {
            &:nth-child(1) {
                font-size: 28px;
            }
        }
    `,
    InnerWrapper: styled(Frame)`
        padding: 25px 8px 14px 20px;
        box-sizing: border-box;
        background: #eee2d1;
        width: 100%;
        min-height: 225px;
        gap: 0px 30px;
        justify-content: flex-start;

        @media (max-width: 1438px) {
            min-height: 280px;
        }
    `,
    MuseumName: styled(Frame)`
        font-family: Playfair Display;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 115%;
        color: #523634;
        margin-bottom: 10px;
    `,
    Wrapper: styled(Frame)`
        min-width: 300px;
        max-width: 400px;
        width: 100%;
        z-index: 5;
        &:before {
            content: "";
            width: 100%;
            height: 225px;
            background: url("${({ url }) => `${BASE_URL}${url}`}") no-repeat center center / contain;
            background-size: 100% 100%;
        }
        ${({ extra }) => extra}
    `,
    ContentWrapper: styled(Frame)`
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #3c4858;
        opacity: 0.5;
        margin-top: 15px;
        width: 100%;
    `,
};

export const ShortInfoRightImage = styled(Frame)`
    max-width: ${({ src = {} }) => src?.width}px;
    width: 100%;
    height: ${({ src = {} }) => src?.height}px;
    ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
`;

export const ShortInfoLeftImage = styled(Frame)`
    max-width: ${({ src = {} }) => src?.width}px;
    width: 100%;
    height: ${({ src = {} }) => src?.height}px;
    ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
    ${({ extra }) => extra}
`;

export const ShortInfoDescription = styled.div`
    color: ${({ theme }) => theme.text.primary};
    font-size: ${({ theme }) => theme.font.paragraph};
    line-height: ${({ theme }) => theme.font.paragraphLh};
`;

const RailwayHistory = {
    Text: styled(ShortInfoDescription)`
        font-style: normal;
        font-weight: 400;
        color: #ffffff;

        font-size: ${({ theme }) => theme.font.paragraph};
        line-height: ${({ theme }) => theme.font.paragraphLh};

        @media (max-width: 425px) {
            font-size: ${({ theme }) => theme.font.paragraphSm};
            line-height: ${({ theme }) => theme.font.paragraphLh};
        }
    `,
    Image: styled(ShortInfoRightImage)`
        border-radius: 100px 0px 0px 100px;
        max-width: 710px;
        width: 100%;
        height: 567px;
        ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / cover;`}
    `,
};

export const ShortInfoHeading = styled(SectionHeader)`
    color: ${({ theme }) => theme.text.primary};
`;

export const Page = styled(Frame)`
    width: 100%;
    align-self: flex-start;
    /* > * {
        min-height: calc(100vh - 100px * 2);

        &:first-child {
            height: calc(100vh - 106px + 2px);
        }
    }
    @media (max-width: 1438px) {
        > * {
            min-height: 0;

            &:first-child {
                height: auto;
            }
        }
    } */
    ${({ extra }) => extra}
`;

const Name = styled.h1`
    margin: 0;
    width: 100%;
    font-family: "Constantia";
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.font.topBannerName};
    letter-spacing: 0.03em;
    z-index: 1;
    color: ${({ theme }) => theme.text.secondary};

    ${({ extra }) => extra}
`;

const TopBanner = {
    Wrapper: styled.div`
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
    `,
    Subtext: styled.p`
        margin: 0;
        background: ${({ theme }) => theme.background.topBannerSubtext};
        color: ${({ theme }) => theme.text.primary};
        padding: 1rem;
        backdrop-filter: blur(7.5px);
        border-radius: 10px 0px;

        position: absolute;
        bottom: -6%;
        right: -20%;

        font-family: "Constantia";
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 44px;

        @media only screen and (max-width: 1024px) {
            bottom: 0;
            right: 0;
        }
    `,
};

export default MuseumPage;
/*eslint-enable*/
