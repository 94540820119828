import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const Button = ({ link = false, external = false, children, href = "", ...props }) => {
    if (link && !external) {
        return <ButtonLink {...props}>{children}</ButtonLink>;
    }

    if (link && external) {
        return (
            <ButtonLinkExternal href={href} {...props}>
                {children}
            </ButtonLinkExternal>
        );
    }

    return <ButtonWrapper {...props}>{children}</ButtonWrapper>;
};

const ButtonLink = styled(Link)`
    min-width: 220px;
    width: auto;
    height: 58px;
    background: ${({ theme, type }) => (type === "primary" ? theme.background.primary : theme.background.secondary)};
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: ${({ theme, type }) => (type === "primary" ? theme.background.secondary : theme.background.primary)};
    cursor: pointer;
    padding: 15px 10px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.3s all ease;

    &:hover {
        color: ${({ theme }) => theme.text.secondary};
    }

    ${({ extra }) => extra}
`;

const ButtonLinkExternal = styled.a`
    min-width: 220px;
    width: auto;
    height: 58px;
    background: ${({ theme, type }) => (type === "primary" ? theme.background.primary : theme.background.secondary)};
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: ${({ theme, type }) => (type === "primary" ? theme.background.secondary : theme.background.primary)};
    cursor: pointer;
    padding: 15px 10px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.3s all ease;

    &:hover {
        color: ${({ theme }) => theme.text.secondary};
    }

    ${({ extra }) => extra}
`;
const ButtonWrapper = styled.button`
    border: none;
    min-width: 220px;
    width: auto;
    height: 58px;
    background: ${({ theme, type }) => (type === "primary" ? theme.background.primary : theme.background.secondary)};
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: ${({ theme, type }) => (type === "primary" ? theme.background.secondary : theme.background.primary)};
    cursor: pointer;
    padding: 15px 10px;
    box-sizing: border-box;
    text-align: center;
    transition: 0.3s all ease;

    &:hover {
        color: ${({ theme }) => theme.text.secondary};
    }

    ${({ extra }) => extra}
`;

export default Button;
