import React from "react";
import { SectionHeader } from "../pages/museum-page";
import { Frame, Image } from "../ui-kit/styled-templates";
import styled, { css } from "styled-components";
import theme from "../../constants/theme-constants";
import Markdown from "../tools/markdown";
import CircledText from "../tools/circled-text";

const AboutBlock = ({ imageSrc, variant = "light", heading = "", paragraph = "", paragraphStyles = "", circleText = "", museumAlias = "" }) => {
    return (
        <>
            <SectionHeader serif variant={variant}>
                {heading}
            </SectionHeader>
            {/* Image around text */}
            <div style={{ position: "relative" }}>
                <Image
                    src={imageSrc}
                    extra={css`
                        float: right;
                        margin-bottom: 25px;
                        margin-left: 25px;

                        border-radius: ${theme.borderRad.primary} 0px;

                        @media screen and (max-width: 770px) {
                            border-radius: ${theme.borderRad.primarySm} 0px;
                        }

                        ${museumAlias === "railway" &&
                        css`
                            border-radius: 0px;
                            object-fit: contain;

                            @media screen and (max-width: 425px) {
                                border-radius: 0px;
                            }
                        `}
                    `}
                />
                <SectionParagraph extra={paragraphStyles}>
                    <Markdown>{paragraph}</Markdown>
                </SectionParagraph>
                {circleText && (
                    <CircledText
                        text={heading}
                        fontSize={0}
                        top={-15}
                        right={-15}
                        radius={heading.length <= 5 ? 25 : heading.length <= 10 ? 100 : heading.length <= 20 ? 150 : 200}
                        color={`#523634`}
                    />
                )}
            </div>
        </>
    );
};

export const SectionParagraph = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.text.primary};
    font-size: ${({ theme }) => theme.font.paragraph};
    line-height: ${({ theme }) => theme.font.paragraphLh};

    @media (max-width: 425px) {
        font-size: ${({ theme }) => theme.font.paragraphSm};
        line-height: ${({ theme }) => theme.font.paragraphLh};
    }

    ${({ extra }) => extra}
`;

export default AboutBlock;
