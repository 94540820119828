/*eslint-disable*/
import React, { useEffect } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { Frame } from "../ui-kit/styled-templates";

import Header from "../tools/header";
import Footer from "../tools/footer";
import MuseumPage, { GallerArrow } from "../pages/museum-page";
import PrivacyPolicyPage from "../pages/privacy-policy-page";
import NewsPage from "../pages/news-page";
import NewsArticlePage from "../pages/news-article-page";
import EventPage from "../pages/event-page";
import EventsPage from "../pages/events-page";
import HomePage from "../pages/home-page";
import ForVisitorsPage from "../pages/for-visitors-page";
import AboutMuseumPage from "../pages/about-museum-page";
import ProjectsPage from "../pages/projects-page";
import VirtualMuseumPage from "../pages/virtual-museum-page";
import PublicationsPage from "../pages/publications-page";

import Alerts from "../modals/alerts";
import Modality from "../modals/modality";
import PedagogicalModal from "../modals/pedagogical-modal";
import GalleryModal from "../modals/gallery-modal";
import EventsCalendarModal from "../modals/events-calendar-modal";

import MuseumsAPI from "../../api/museums-api";

import { putStorage, useStorageListener } from "../../hooks/useStorage";
import useHashScroller from "../../hooks/useHashScroller";
import useLocale from "../../hooks/useLocale";
import StoriesPage from "../pages/stories-page";

const useScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => window.scrollTo(0, 0), [pathname]);
};

const RouterApp = () => {
    const { locale } = useLocale();
    useEffect(() => {
        MuseumsAPI.getMuseumsData();
        MuseumsAPI.getNavBar();
        MuseumsAPI.getTicketLinks();
        MuseumsAPI.getDictionary();
    }, [locale]);

    useScrollToTop();
    useHashScroller();
    const museums = useStorageListener((state) => state?.museums ?? []);
    // if (!museums?.length) {
    //     return null;
    // }
    return (
        <>
            <Alerts />
            <Modality />

            <PedagogicalModal />
            <GalleryModal />
            <EventsCalendarModal />

            <Wrapper>
                <Header />
                <Switch>
                    {museums?.map?.((i) => (
                        <Route key={i?.alias} exact path={`/museum/${i?.alias}`} component={MuseumPage} />
                    ))}
                    <Route exact path={`/museum/event/:id`} component={EventPage} />
                    <Route exact path={`/news/article/:id`} component={NewsArticlePage} />
                    <Route exact path={`/news`} component={NewsPage} />
                    <Route exact path={`/events`} component={EventsPage} />
                    <Route exact path={`/privacy-policy`} component={PrivacyPolicyPage} />
                    <Route exact path={`/for-visitors`} component={ForVisitorsPage} />
                    <Route exact path={`/about-museum`} component={AboutMuseumPage} />

                    <Route exact path={`/projects`} component={ProjectsPage} />

                    <Route exact path={`/stories`} component={StoriesPage} />

                    <Route exact path={`/publications`} component={PublicationsPage} />
                    <Route exact path={`/virtual-museum`} component={VirtualMuseumPage} />
                    <Route path={`/`} component={HomePage} />
                </Switch>
                <Footer />
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Frame)`
    width: 100%;
`;

export default RouterApp;
/*eslint-enable*/
