/*eslint-disable*/
import React, { useEffect } from "react";
import styled from "styled-components";

import { GallerArrow } from "../pages/museum-page";
import PopUpWrapper from "./pop-up-wrapper";

import { BASE_URL, MODALS } from "../../constants/config";

import useModal from "../../hooks/useModal";
import { putStorage } from "../../hooks/useStorage";

const GalleryModal = () => {
    const { state = {} } = useModal(MODALS.GALLERY_MODAL);
    const { photos = [], current = 0 } = state;
    const handlers = {
        prev: () => {
            putStorage(`modals.${MODALS.GALLERY_MODAL}.state.current`, (current - 1 + photos.length) % photos.length);
        },
        next: () => {
            putStorage(`modals.${MODALS.GALLERY_MODAL}.state.current`, (current + 1 + photos.length) % photos.length);
        },
    };
    return (
        <PopUpWrapper name={MODALS.GALLERY_MODAL} extra={`padding: 0; background: unset; width: min-content; position: relative`} withCros={false}>
            <GallerArrow
                onClick={handlers.prev}
                extra={`transform: rotate(180deg) translate(100%, 50%); z-index: 10000000;`}
            />
            <GallerArrow
                onClick={handlers.next}
                extra={`transform: translate(100%, -50%); right: 0; z-index: 10000000;`}
            />
            <Photo src={photos?.[current]?.photo} />
        </PopUpWrapper>
    );
};

const Photo = styled.div`
    width: ${({ src = {} }) => src?.width}px;
    height: ${({ src = {} }) => src?.height}px;
    background: url("${({ src }) => `${BASE_URL}${src?.url}`}") no-repeat center center / contain;
`;

export default GalleryModal;
/*eslint-enable*/
