/*eslint-disable*/
import axios from "axios";
import moment from "moment-timezone";

import { eventDispatch } from "../hooks/useEventListener";
import { getStorage, putStorage } from "../hooks/useStorage";
import { objectPut, objectToQS, QSToObject, sleep } from "./common-helper";

const addLocaleToUrl = (url) => {
    const locale = getStorage((state) => state?.locale ?? localStorage.getItem("locale") ?? `lv`);
    const baseUrl = url.split("?")[0];
    if (baseUrl.includes("/ticket-links")) {
        return baseUrl;
    }
    if (baseUrl.includes("/social-medias")) {
        return baseUrl;
    }
    const queryString = url.split("?")[1] ?? ``;
    const updatedQueryObject = { ...QSToObject(queryString), _locale: locale };
    return baseUrl + objectToQS(updatedQueryObject);
};

export const setUpInterceptors = () => {
    axios.interceptors.request.use(
        async (config) => {
            config.url = addLocaleToUrl(config.url);
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const handleSuccess = (response) => {
    if (!Array.isArray(response) && typeof response.message === `string`) {
        response = [response];
    }
    (response || [])?.forEach?.((i, index) => {
        setTimeout(() => {
            eventDispatch(`THROW_SUCCESS`, i?.message ?? ``);
        }, 200 * index);
    });
    return response || [];
};

export let handleError = (error) => {
    console.error(error);
    if (!Array.isArray(error) && typeof error?.message === `string` && !Array.isArray(error?.response?.data)) {
        objectPut(error, `response.data`, [error?.response?.data]);
    }
    if (error?.response?.data) {
        (error?.response?.data ?? []).forEach((i, index) => {
            setTimeout(() => {
                if (i?.field) {
                    eventDispatch(`CALL_INPUT_ERROR`, { field: i?.field ?? ``, message: i?.message ?? `` });
                } else {
                    eventDispatch(`THROW_ERROR`, i?.message ?? error?.message ?? ``);
                }
            }, 200 * index);
        });
        return error?.response?.data ?? [];
    }
    if (error?.response?.data?.message) {
        eventDispatch(`THROW_ERROR`, error?.response?.data?.message);
        return error?.response?.data?.message;
    }
    return error;
};

export const loadingCounterWrapper = async (action) => {
    putStorage(`loading_counter`, (window?.storage?.loading_counter ?? 0) + 1);
    try {
        const response = await action();
        return response;
    } catch (error) {
        throw error;
    } finally {
        await sleep(100);
        putStorage(`loading_counter`, Math.max((window?.storage?.loading_counter ?? 0) - 1, 0));
    }
};

export const POSTOptions = (name) => {
    const table = getStorage((state) => state?.tables?.[name] ?? {});
    const { pagination = {}, sort = [], filters: filter = {} } = table;
    const { currentPage = 0, perPage = 10 } = pagination;
    return {
        params: {
            limit: perPage,
            offset: currentPage + 1,
            ...filter,
        },
    };
};

export const convertPaginatedResponse = (response) => {
    const data = {
        rows: _.map(response?.rows, `cells`)?.map?.((i) => Object.fromEntries(i?.map?.((i) => [i?.column, i?.value]))),
        pagination: {
            perPage: response?.rowCount,
            currentPage: Math.max(response?.page - 1, 0),
            totalCount: response?.totalRowCount,
        },
    };
    return data;
};

export const convertPaginatedResponse2 = (response) => {
    const data = {
        rows: response?.data,
        pagination: {
            perPage: response?.count,
            currentPage: Math.max(response?.page - 1, 0),
            totalCount: response?.totalCount,
        },
    };
    return data;
};

export const GETOptions = (options = {}) => {
    return POSTOptions(options);
};

/*eslint-enable*/
