/*eslint-disable*/
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import { useLocation, Link } from "react-router-dom";

import { BASE_URL } from "../../constants/config";

import { useStorageListener } from "../../hooks/useStorage";

import { Frame, Button, Input, Dropdown, H1, P, RowWrapper } from "../ui-kit/styled-templates";
import useLocale from "../../hooks/useLocale";
import MuseumsAPI from "../../api/museums-api";

const Footer = () => {
    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getSocialMedias();
    }, [locale]);

    const socialMedias = useStorageListener((state) => state?.["social-medias"] ?? []);

    return (
        <RowWrapper
            extra={css`
                min-height: 48px;
                box-sizing: border-box;
                padding: 1rem 0;
                width: 100%;
                font-family: Roboto;
                font-size: 18px;
                line-height: 48px;
                letter-spacing: -0.022em;
                color: #ffffff;
                max-width: 1240px;
                flex-wrap: wrap;
                align-items: center;
                * {
                    text-decoration: none;
                    color: #ffffff;
                }
                @media (max-width: 1438px) {
                    padding: 0px 15px 44px 15px;
                    gap: 10px 0px;
                }
                @media (max-width: 425px) {
                    justify-content: center;
                }
            `}
            id="bottom"
        >
            <Frame extra={`flex-direction: row; gap: 0px 5px; flex-wrap: wrap; justify-content: flex-start;`}>
                © {moment().format(`YYYY`)} Ventspils muzejs | Izstrāde: Ritmika SIA
                <span>
                    <Link to={`/privacy-policy`}>| Privacy Policy</Link>
                </span>
            </Frame>
            <Frame extra={`flex-direction: row; gap: 0px 26px;`}>
                {socialMedias?.map((social, index) => {
                    return <Socials key={index} icon={social?.icon_white} as={A} href={social?.social_media_museum_url} target={"_blank"} />;
                })}
            </Frame>
        </RowWrapper>
    );
};

export const A = styled.a``;

export const Socials = styled.button`
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ icon = {} }) =>
        icon === null ? `display: none;` : icon?.url && `background: url("${`${BASE_URL}${icon?.url}`}") no-repeat center center / contain;`}
    border: none;
    outline: none;
    cursor: pointer;
`;

export default Footer;
/*eslint-enable*/
