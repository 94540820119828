import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import { RowWrapper } from "../ui-kit/styled-templates";
import { SectionHeader } from "../pages/museum-page";
import SeeMore from "../ui-kit/see-more";
import NewsItem from "../ui-kit/newsItem";

const NewsBlock = ({ dictionary = {}, news = [], variant = "dark" }) => {
    // Sort by update data
    const sortedNews = news.sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));

    return (
        <>
            <RowWrapper
                extra={css`
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 15px;
                    box-sizing: border-box;
                    @media (max-width: 768px) {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 40px;
                    }
                `}
            >
                <SectionHeader variant={variant}>{dictionary?.news_heading}</SectionHeader>
                {news?.length >= 2 && <SeeMore to={`/news`}>{dictionary?.see_more_button}</SeeMore>}
            </RowWrapper>
            <NewsBlockWrapper>
                {sortedNews.length > 0 &&
                    sortedNews?.slice(0, 3)?.map?.((newsItem, index) => {
                        return <NewsItem newsItem={newsItem} key={index} />;
                    })}
            </NewsBlockWrapper>
        </>
    );
};

const NewsBlockWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    a {
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export default NewsBlock;
