import React from "react";
import { Container, Frame, RowWrapper } from "../ui-kit/styled-templates";
import GoogleMapByIframe from "../tools/google-map-by-iframe";

import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import Button from "../ui-kit/button";

const MuseumContactBlock = ({ contact = {}, sendMessageText = "", light = false }) => {
    return (
        <RowWrapper
            extra={css`
                justify-content: center;
                align-items: flex-start;
                gap: 50px 36px;
                flex-direction: column-reverse;

                @media (min-width: 768px) {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            `}
        >
            <GoogleMapByIframe iframeAsString={contact?.contact_location} />
            <Frame
                light={light}
                extra={css`
                    color: ${({ light, theme }) => (light ? theme.text.secondary : theme.text.primary)};
                    align-items: flex-start;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 20px;
                    gap: 40px 0px;
                    max-width: 400px;
                    width: 100%;
                    p {
                        font-weight: 300;
                        line-height: 20px;
                        margin: 10px 0px;
                    }
                    strong {
                        line-height: 40px;
                    }
                    @media (max-width: 425px) {
                        font-size: 18px;
                    }
                `}
            >
                <Markdown>{contact?.contact_info}</Markdown>

                <Button link external href={`mailto:${contact?.contact_email}`} type={light ? "secondary" : "primary"}>
                    {sendMessageText}
                </Button>
            </Frame>
        </RowWrapper>
    );
};

export default MuseumContactBlock;
