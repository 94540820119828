/*eslint-disable*/
import React, { useState, useEffect, lazy } from "react";
// import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
// import Markdown from "../tools/markdown";
// import moment from "moment-timezone";
import _ from "lodash";

import { Frame, RowWrapper, Container, Dropdown, Image } from "../ui-kit/styled-templates";
// import { Events } from "./museum-page";
// import { HeaderDropdown, DropdownItem, MenuItem } from "../tools/header";
// import { NewsItem, SectionHeader } from "./museum-page";
// import { News } from "./news-page";

import { BASE_URL, MODALS } from "../../constants/config";
// import { linkTo } from "../../utils/common-helper";

import { useStorageListener } from "../../hooks/useStorage";
// import usePagination from "../../hooks/usePagination";
// import { Events } from "../museums-ui-blocks/events-block";
import Button from "../ui-kit/button";
import useLocale from "../../hooks/useLocale";

// import "moment/dist/locale/ru";
// import "moment/dist/locale/lv";
import MuseumsAPI from "../../api/museums-api";
import ModalsHelper from "../../utils/modals-helper";
import { dateLocales } from "../../constants/i18n";

const EventsPage = () => {
    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getEvents();
    }, [locale]);

    const events = useStorageListener((state) => state?.events);

    // const news = useStorageListener((state) => state?.news);
    // const { pathname } = useLocation();
    // const path = pathname.split(`/`).slice(1);
    // const museumAlias = path[1];
    // const museums = useStorageListener((state) => state?.museums ?? []);
    // const dictionary = useStorageListener((state) => state?.dictionary ?? {});

    // const eventsWithAlias = museums
    //     ?.map((museum, index) => {
    //         const { alias, events } = museum;
    //         return events.map((event) => ({ ...event, alias, id: index }));
    //     })
    //     ?.flat();

    // const [seeMore, setSeeMore] = useState(2);
    // if (seeMore > news?.length) {
    //     setSeeMore(news?.length ?? 0);
    // }

    // const [search, setSearch] = useState();
    // const [category, setCategory] = useState();

    // // Sort by update data
    // const sortedEvents = eventsWithAlias.sort((a, b) => new Date(b.event_date) - new Date(a.event_date));

    // console.log(`sortedEvents ==>`, sortedEvents);
    // console.log(museums);

    // Calendar logic START
    const daysOfWeek = {
        en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        lv: ["Pirmd", "Otrd", "Trešd", "Ceturd", "Piektd", "Sestd", "Svētd"],
        ru: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    };

    const dateOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };

    const currentDate = new Date();

    const [selectedDate, setSelectedDate] = useState(currentDate);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    const selectedDay = selectedDate.getDate();

    // const firstDay = new Date(selectedYear, selectedMonth, 1);
    const lastDay = new Date(selectedYear, selectedMonth + 1, 0);

    const daysCountInMonth = lastDay.getDate();

    const getWeekDay = (weekDay) => {
        if (weekDay === 0) return 7;
        return weekDay;
    };

    const getDaysInMonth = () => {
        let daysArray = [];

        for (let i = 1; i <= daysCountInMonth; i++) {
            // Add empty fields based on day offset in week
            const weekday = getWeekDay(new Date(selectedYear, selectedMonth, i).getDay());

            if (i === 1 && weekday > 1) {
                for (let j = 0; j < weekday - 1; j++) {
                    daysArray.push("");
                }
            }

            daysArray.push(new Date(selectedYear, selectedMonth, i));

            if (i === daysCountInMonth && weekday < 7) {
                for (let z = 0; z < 7 - weekday; z++) {
                    daysArray.push("");
                }
            }
        }

        // Transform datesArray into array with 5 weeks subarrays
        let returnArray = [[], [], [], [], [], []];

        daysArray.forEach((day, index) => {
            const subarrayIndex = Math.floor(index / 7); // Calculate the index of the subarray
            returnArray[subarrayIndex].push(day); // Push the element into the appropriate subarray
        });

        return returnArray;
    };

    const daysInMonth = getDaysInMonth();

    const firstMonth = selectedMonth === 0;
    const lastMonth = selectedMonth === 11;

    const handleNavButtonClick = (forward) => {
        if (forward) {
            setSelectedDate(new Date(selectedYear, selectedMonth + 1, selectedDay));
        } else {
            setSelectedDate(new Date(selectedYear, selectedMonth - 1, selectedDay));
        }
    };

    const currentYearEvents = events?.filter((event) => new Date(event.event_date).getFullYear() === currentDate.getFullYear());

    // Calendar logic END
    return (
        <>
            <Container variant={`light`} extra={`align-items: flex-start; padding: 2rem 0;`}>
                <Frame>
                    <EventTable.Wrapper>
                        <caption>
                            <RowWrapper
                                extra={css`
                                    justify-content: center;
                                    align-items: center;
                                    gap: 1rem;
                                    margin-bottom: 1rem;
                                `}
                            >
                                {!firstMonth && (
                                    <NavButton onClick={() => handleNavButtonClick(false)}>
                                        <img src={require("../../assets/icons/gallery-arrow-left.svg").default} alt="Navigation previous month" />
                                    </NavButton>
                                )}
                                <NavDate>
                                    <span>{selectedDate.toLocaleString(dateLocales[locale], { month: "long" })}</span>
                                    <span>{selectedYear}</span>
                                </NavDate>
                                {!lastMonth && (
                                    <NavButton right onClick={() => handleNavButtonClick(true)}>
                                        <img src={require("../../assets/icons/gallery-arrow-left.svg").default} alt="Navigation previous month" />
                                    </NavButton>
                                )}
                            </RowWrapper>
                        </caption>
                        <thead>
                            <tr>
                                {daysOfWeek[locale].map((dayOfWeek) => {
                                    return (
                                        <th key={dayOfWeek} style={{ width: "14.28%" }}>
                                            {dayOfWeek}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {daysInMonth.map((week, weekIndex) => {
                                return (
                                    <tr key={weekIndex}>
                                        {week.map((day, dayIndex) => {
                                            return (
                                                <EventTable.TableData
                                                    key={dayIndex}
                                                    currentDate={
                                                        typeof day === "string"
                                                            ? false
                                                            : day.toLocaleString(dateLocales[locale], dateOptions) ===
                                                              currentDate.toLocaleString(dateLocales[locale], dateOptions)
                                                    }
                                                >
                                                    <Frame
                                                        extra={css`
                                                            gap: 0.5rem;
                                                        `}
                                                    >
                                                        <Frame
                                                            extra={css`
                                                                gap: 0.2rem;
                                                                width: 100%;
                                                                min-height: 100px;
                                                                justify-content: flex-start;
                                                            `}
                                                        >
                                                            {currentYearEvents
                                                                ?.filter(
                                                                    (event) =>
                                                                        new Date(event.event_date).toLocaleString(
                                                                            dateLocales[locale],
                                                                            dateOptions
                                                                        ) === day.toLocaleString(dateLocales[locale], dateOptions)
                                                                )
                                                                ?.slice(0, 2)
                                                                .map((event) => {
                                                                    return (
                                                                        <Button
                                                                            key={event.id}
                                                                            link
                                                                            to={`/museum/event/${event.title.toLowerCase().replaceAll(" ", "_")}?id=${
                                                                                event.id
                                                                            }`}
                                                                            type="primary"
                                                                            extra={css`
                                                                                height: auto;
                                                                                min-width: unset;
                                                                                font-size: 1rem;
                                                                                line-height: unset;
                                                                                font-weight: 400;
                                                                                width: 100%;
                                                                            `}
                                                                        >
                                                                            {event.title.slice(0, 13) + " " + "..."}
                                                                        </Button>
                                                                    );
                                                                })}
                                                        </Frame>
                                                        <RowWrapper>
                                                            {currentYearEvents?.filter(
                                                                (event) =>
                                                                    new Date(event.event_date).toLocaleString(dateLocales[locale], dateOptions) ===
                                                                    day.toLocaleString(dateLocales[locale], dateOptions)
                                                            ).length > 2 ? (
                                                                <Button
                                                                    type={
                                                                        typeof day === "string"
                                                                            ? "primary"
                                                                            : day.toLocaleString(dateLocales[locale], dateOptions) ===
                                                                              currentDate.toLocaleString(dateLocales[locale], dateOptions)
                                                                            ? "secondary"
                                                                            : "primary"
                                                                    }
                                                                    onClick={() =>
                                                                        ModalsHelper.showModal(MODALS.EVENTS_CALENDAR_MODAL, {
                                                                            date: day.toLocaleString(dateLocales[locale], dateOptions),
                                                                            events: currentYearEvents?.filter(
                                                                                (event) =>
                                                                                    new Date(event.event_date).toLocaleString(
                                                                                        dateLocales[locale],
                                                                                        dateOptions
                                                                                    ) === day.toLocaleString(dateLocales[locale], dateOptions)
                                                                            ),
                                                                        })
                                                                    }
                                                                    extra={css`
                                                                        height: auto;
                                                                        font-size: 1rem;
                                                                        padding: 0 0.2rem;
                                                                        margin: 0;
                                                                        font-weight: 600;
                                                                        min-width: unset;
                                                                    `}
                                                                >
                                                                    {locale === "en"
                                                                        ? "See all"
                                                                        : locale === "lv"
                                                                        ? "Visi"
                                                                        : locale === "ru"
                                                                        ? "Все"
                                                                        : ""}
                                                                </Button>
                                                            ) : (
                                                                <div />
                                                            )}

                                                            <EventTable.Day>
                                                                {typeof day === "string"
                                                                    ? ""
                                                                    : day.toLocaleString(dateLocales[locale], { day: "numeric" })}
                                                            </EventTable.Day>
                                                        </RowWrapper>
                                                    </Frame>
                                                </EventTable.TableData>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </EventTable.Wrapper>
                </Frame>
                {/* <Frame extra={`gap: 128px 0px; width: 100%; align-items: flex-start;`}>
                    <RowWrapper
                        extra={css`
                            gap: 30px 48px;
                            width: 100%;
                            @media (max-width: 1438px) {
                                justify-content: center;
                                flex-wrap: wrap;
                            }
                        `}
                    >
                        <News.Search>
                            <News.SearchIcon></News.SearchIcon>
                            <input
                                type="text"
                                placeholder={`${dictionary?.search_field_placeholder}`}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            ></input>
                        </News.Search>
                        <Frame
                            extra={`height: 58px;
                                max-width: 346px;
                                width: 100%;
                                background: #eee2d1;
                                border-radius: 5px;
                                padding: 0px 15px;`}
                        >
                            <News.Category
                                customToggleArrow
                                toggle={
                                    <News.CategoryMenuItem selectable>
                                        {museums?.find?.((i) => i?.alias === category)?.name ?? dictionary?.filter_field_placeholder}
                                    </News.CategoryMenuItem>
                                }
                                toggleStyles={`width: 100%;`}
                                menu={museums?.map?.((museum, index) => {
                                    return (
                                        <DropdownItem key={index} onClick={() => setCategory(museum.alias === category ? `` : museum.alias)}>
                                            {museum.name}
                                        </DropdownItem>
                                    );
                                })}
                                menuStyles={({ theme }) => css`
                                    width: 100%;
                                    left: 0;
                                    @media (max-width: 425px) {
                                        padding: 0;
                                        padding-left: 20px;
                                        background: ${theme.background.light};
                                        position: absolute;
                                        top: 24px;
                                    }
                                `}
                                extra={`width: 100%;`}
                            ></News.Category>
                        </Frame>
                    </RowWrapper>
                    <RowWrapper
                        extra={css`
                            align-items: flex-start;
                            flex-wrap: wrap;
                            gap: 30px 20px;
                            justify-content: space-between;
                            @media (max-width: 1438px) {
                            }
                        `}
                    >
                        {sortedEvents
                            ?.filter?.((i) => (search ? i?.title?.toLowerCase?.()?.includes?.(search?.toLowerCase()) : true))
                            ?.filter?.((i) => (category ? i?.alias === category : true))
                            ?.slice(0, seeMore)
                            ?.map?.(({ title, description, event_date, event_location, event_price, photo, id }, index) => {
                                return (
                                    <div>2</div>
                                    // <Events.Item.Wrapper
                                    //     key={index}
                                    //     extra={css`
                                    //         align-items: flex-start;
                                    //         flex-wrap: wrap;
                                    //         flex-basis: 100%;
                                    //         max-width: unset;

                                    //         @media (min-width: 768px) {
                                    //             flex-basis: calc(50% - 20px);
                                    //         }
                                    //     `}
                                    //     src={photo}
                                    // >
                                    //     <Link to={`/museum/event/${title.toLowerCase().replace(" ", "_")}`}>
                                    //         <Events.Item.Photo src={photo} />
                                    //         <Events.Item.Body>
                                    //             <Frame serif extra={`margin-bottom: 30px;`}>
                                    //                 {title}
                                    //             </Frame>
                                    //             <Events.Item.Description>{description}</Events.Item.Description>
                                    //         </Events.Item.Body>
                                    //         <Events.Item.Footer.Wrapper>
                                    //             {[
                                    //                 [dictionary?.date_subheading, event_date],
                                    //                 [dictionary?.price_subheading, event_price],
                                    //                 [dictionary?.location_subheading, event_location],
                                    //             ].map(([label, value], index) => (
                                    //                 <Events.Item.Footer.ItemWrapper key={index}>
                                    //                     <Events.Item.Footer.Label>{label}</Events.Item.Footer.Label>
                                    //                     <Events.Item.Footer.Value>{value}</Events.Item.Footer.Value>
                                    //                 </Events.Item.Footer.ItemWrapper>
                                    //             ))}
                                    //         </Events.Item.Footer.Wrapper>
                                    //     </Link>
                                    // </Events.Item.Wrapper>
                                );
                            })}
                    </RowWrapper>
                    <Frame
                        extra={
                            seeMore >=
                            eventsWithAlias
                                ?.filter?.((i) => (search ? i?.title?.toLowerCase?.()?.includes?.(search?.toLowerCase()) : true))
                                ?.filter?.((i) => (category ? i?.alias === category : true))?.length
                                ? `display: none;`
                                : `width:100%;`
                        }
                    >
                        {seeMore <
                            eventsWithAlias
                                ?.filter?.((i) => (search ? i?.title?.toLowerCase?.()?.includes?.(search?.toLowerCase()) : true))
                                ?.filter?.((i) => (category ? i?.alias === category : true))?.length -
                                1 && <News.Button onClick={() => setSeeMore(seeMore + 2)}>{dictionary?.see_more_button}</News.Button>}
                    </Frame>
                </Frame> */}
            </Container>
        </>
    );
};

const NavDate = styled.h2`
    display: flex;
    gap: 0.5rem;

    span:first-child {
        text-transform: capitalize;
    }
`;

const EventTable = {
    Wrapper: styled.table`
        width: 100%;
        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            thead {
                display: none;
            }
            tbody {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;
            }
            tr {
                display: flex;
                flex-direction: column;
                gap: 0.2rem;
            }
        }
    `,
    TableData: styled.td`
        border: 1px solid black;
        border-radius: 5px;
        padding: 0.5rem;
        text-align: center;

        ${({ currentDate, theme }) =>
            currentDate &&
            css`
                background: ${theme.background.light};
                color: ${theme.text.selected};
                border: 1px solid ${theme.background.light};
            `}
    `,
    Day: styled.h3`
        margin: 0;
        padding: 0;
    `,
};

const NavButton = styled(Button)`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: none;
    border: none;
    width: 46px;
    height: 46px;
    min-width: unset;

    img {
        display: block;
        width: 100%;
        height: 100%;

        ${({ right }) =>
            right &&
            css`
                transform: rotate(180deg);
            `}
    }
`;

export default EventsPage;
/*eslint-enable*/
