/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import moment from "moment-timezone";
import CircleType from "circletype";

import { Frame, RowWrapper, Container } from "../ui-kit/styled-templates";
import {
    Page,
    ShortInfoHeading,
    ShortInfoDescription,
    ShortInfoRightImage,
    CircleText,
    aboutCircleText,
    SectionHeader,
    Collections,
    OpeningImage,
    PedagogicalProgrammsImage,
    PedagogicalProgrammsButton,
    GallerArrow,
    PhotoItem,
    Events,
    NewsItem,
    ContactsItem,
    BottomButton,
    BottomButtonSVG,
} from "../pages/museum-page";
import { News } from "./news-page";

import { BASE_URL, MODALS } from "../../constants/config";

import { putStorage, useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import ModalsHelper from "../../utils/modals-helper";

const StoriesPage = () => {
    const projects = useStorageListener((state) => state?.["projects-page"]?.projects ?? []);

    const projects_headings = useStorageListener((state) => state?.["projects-page"]?.projects_headings ?? []);

    const projects_description = useStorageListener((state) => state?.["projects-page"]?.projects_description ?? []);

    const dictionary = useStorageListener((state) => state?.dictionary ?? []);

    const navBar = useStorageListener((state) => state?.["navbar"] ?? []);

    const [seeMore, setSeeMore] = useState([]);

    useEffect(() => {
        setSeeMore(new Array(projects_headings?.length).fill(3));
    }, [projects_headings]);

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () => {
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text });
            },
    };

    const navBarsID = navBar?.navbar_with_items?.menu_items?.[3]?.dropdown_menu_items?.map((item) => {
        return item.url.split("#").slice(-1);
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);

    return (
        <Page
            extra={`> * {
            min-height: 0;

            &:first-child {
                height: auto;
            }
        }`}
        >
            {/* MAIN */}
            <Container variant={`light`} extra={css``}>
                <Frame>
                    <SectionHeader
                        serif
                        extra={({ theme }) => `
                                        color: ${theme.text.primary};`}
                    >
                        {dictionary?.projects_heading}
                    </SectionHeader>
                    <Frame
                        extra={`font-weight: normal; font-size: 20px; line-height: 29px; margin-bottom: 60px; @media (max-width: 425px) {font-size: 16px;}`}
                    >
                        {projects_description}
                    </Frame>
                </Frame>
                <a href={`/projects#${buttonBottomToggle ? `top` : `bottom`}`}>
                    <BottomButton>
                        <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                    </BottomButton>
                </a>
            </Container>
            {/* PROJECTS */}
            <Container
                variant={`light`}
                extra={css`
                    gap: 90px 0px;
                `}
            >
                Stories Page
            </Container>
        </Page>
    );
};

const ProjectHeader = styled(Frame)`
    border-radius: 100px 0px;
    background: ${({ theme }) => theme.background.primary};
    max-width: 800px;
    width: 100%;
    min-height: 70px;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    color: ${({ theme }) => theme.text.secondary} !important;
    margin-bottom: 45px;
    box-sizing: border-box;
    padding: 20px 30px;
`;
export default StoriesPage;

/*eslint-enable*/
