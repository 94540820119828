/*eslint-disable*/
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";

import { Container } from "../ui-kit/styled-templates";

import { useStorageListener } from "../../hooks/useStorage";
import MuseumsAPI from "../../api/museums-api";
import useLocale from "../../hooks/useLocale";

const PrivacyPolicyPage = () => {
    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getPrivacyPolicy();
    }, [locale]);

    const policyText = useStorageListener((state) => state?.policy?.bodyText ?? ``);

    return (
        <Container
            extra={css`
                h1 {
                    font-family: Crimson Text;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                }
            `}
        >
            <Markdown>{policyText}</Markdown>
        </Container>
    );
};

export default PrivacyPolicyPage;
/*eslint-enable*/
