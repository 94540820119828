import React from "react";
import { Container, Frame, RowWrapper } from "../ui-kit/styled-templates";
import { OpeningImage, OpeningImageText, SectionHeader } from "../pages/museum-page";
import styled, { css } from "styled-components";
import { TableOuterWrapper, TableWrapper, TimeTable } from "./railway-schedule";
import moment from "moment-timezone";
import Markdown from "../tools/markdown";

const OpeningHoursBlock = ({ museum, openingHoursHeading }) => {
    return (
        <Container variant={`light`} extra={`align-items: flex-start; position: relative; overflow: hidden; gap: 50px;`} id="openinghours">
            <Frame
                extra={css`
                    gap: 2rem;
                    align-items: flex-start;
                `}
            >
                <SectionHeader serif>{openingHoursHeading}</SectionHeader>

                <RowWrapper
                    extra={css`
                        flex-direction: column;
                        gap: 2rem;
                        align-items: stretch;

                        ${!["livonijas", "seaside"].includes(museum?.alias) &&
                        css`
                            justify-content: center;
                        `}

                        @media only screen and (min-width: 770px) {
                            flex-direction: row;
                            justify-content: space-evenly;
                            gap: 5rem;
                        }
                    `}
                >
                    <TableOuterWrapper
                        extra={css`
                            @media only screen and (min-width: 769px) {
                                width: 40% !important;
                            }
                        `}
                    >
                        <TableWrapper
                            borderType="dark"
                            extra={css`
                                table {
                                    tr td:last-child {
                                        text-align: center;
                                    }
                                }
                            `}
                        >
                            <TimeTable>
                                <tbody>
                                    {museum?.opening_hours
                                        ?.sort?.((a, b) => a?.order - b?.order)
                                        ?.map(({ day, nonworking_day, opening_hour, closing_hour, id }, index) => {
                                            return (
                                                <tr key={id}>
                                                    <td>
                                                        <Frame
                                                            extra={css`
                                                                color: ${({ theme }) => theme.text.secondary};
                                                                align-items: flex-start;
                                                                ${index === 0 &&
                                                                css`
                                                                    font-size: 29px;
                                                                    line-height: 44px;
                                                                    @media (max-width: 425px) {
                                                                        font-size: 24px;
                                                                    }
                                                                `}
                                                            `}
                                                            serif={index === 0}
                                                        >
                                                            {day}
                                                        </Frame>
                                                    </td>
                                                    <td>
                                                        <Frame
                                                            extra={css`
                                                                color: ${({ theme }) => theme.text.secondary};
                                                                ${index === 0 &&
                                                                css`
                                                                    font-size: 29px;
                                                                    line-height: 44px;
                                                                    @media (max-width: 425px) {
                                                                        font-size: 24px;
                                                                    }
                                                                `}
                                                            `}
                                                            serif={index === 0}
                                                        >
                                                            {nonworking_day
                                                                ? nonworking_day
                                                                : moment(opening_hour, `HH:mm.SS.SSS`).format(`HH:mm`) +
                                                                  " " +
                                                                  "-" +
                                                                  " " +
                                                                  moment(closing_hour, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                        </Frame>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </TimeTable>
                            <Frame
                                serif
                                extra={css`
                                    max-width: 500px;
                                    width: 100%;
                                    margin-top: 25px;
                                    font-size: 16px;
                                    line-height: 29px;
                                    color: ${({ theme }) => theme.background.secondary};

                                    @media (min-width: 425px) {
                                        font-size: 20px;
                                    }
                                    @media (min-width: 768px) {
                                        font-size: 24px;
                                    }
                                `}
                            >
                                {museum?.working_hours_ticket_office}
                            </Frame>
                        </TableWrapper>
                    </TableOuterWrapper>

                    {museum?.alias === "livonijas" && (
                        <TableOuterWrapper
                            extra={css`
                                @media only screen and (min-width: 769px) {
                                    width: 40% !important;
                                }
                            `}
                        >
                            <TableWrapper
                                backgroundType="dark"
                                borderType="dark"
                                extra={css`
                                    table {
                                        tr td:last-child {
                                            text-align: center;
                                        }
                                    }
                                `}
                            >
                                <TimeTable>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Frame
                                                    extra={css`
                                                        color: ${({ theme }) => theme.text.secondary};
                                                        text-align: left;
                                                        align-items: flex-start;
                                                        justify-content: center;
                                                        h4 {
                                                            margin-top: 0;
                                                            font-family: Playfair Display;
                                                            font-size: 36px;
                                                            line-height: 44px;
                                                            text-align: center;

                                                            @media (max-width: 425px) {
                                                                font-size: 30px;
                                                            }
                                                        }
                                                    `}
                                                >
                                                    <Markdown>{museum?.working_hours_summer ?? ``}</Markdown>
                                                </Frame>
                                            </td>
                                        </tr>
                                    </tbody>
                                </TimeTable>
                            </TableWrapper>
                        </TableOuterWrapper>
                    )}

                    {museum?.alias === "seaside" && (
                        <Frame extra={`max-width: 338px; width:100%; min-height: 100px; @media (max-width: 768px){max-width: unset;} `}>
                            <OpeningImage src={museum?.opening_hours_photo_seaside} subtext={museum?.top_banner_sub_text} />
                            <OpeningImageText>{museum?.opening_hours_photo_seaside_description}</OpeningImageText>
                        </Frame>
                    )}
                </RowWrapper>
            </Frame>
        </Container>
    );
};

export default OpeningHoursBlock;
