import React from "react";
import styled, { css } from "styled-components";
import { Container, Frame, RowWrapper } from "../ui-kit/styled-templates";
import moment from "moment-timezone";
import { RailwayMovementList } from "../pages/museum-page";
import Markdown from "../tools/markdown";

const RailwaySchedule = ({ forVisitors }) => {
    return (
        <Container variant={`dark`}>
            <Frame
                extra={css`
                    gap: 2rem;
                `}
            >
                <Frame
                    extra={({ theme }) => css`
                        font-size: 24px;
                        line-height: 140%;
                        color: ${theme.text.secondary};
                        align-items: flex-start;
                        width: 100%;

                        strong {
                            color: ${({ theme }) => theme.text.selected};
                        }

                        @media (max-width: 425px) {
                            font-size: 20px;
                        }
                    `}
                    serif
                >
                    <Markdown>{forVisitors?.railway_schedule_alert}</Markdown>
                </Frame>

                <RowWrapper
                    extra={css`
                        gap: 50px 100px;
                        flex-direction: column;
                        flex-wrap: wrap;
                        align-items: flex-start;

                        @media only screen and (min-width: 769px) {
                            flex-direction: row !important;
                            flex-wrap: nowrap;
                        }
                    `}
                >
                    <TableOuterWrapper>
                        <TableWrapper>
                            <TimeTable>
                                <thead>
                                    <tr>
                                        <th>{forVisitors?.railway_timetable_subheading_museum}</th>
                                        <th>{forVisitors?.railway_timetable_subheading_mountain}</th>
                                        <th>{forVisitors?.railway_timetable_subheading_mountain}</th>
                                        <th>{forVisitors?.railway_timetable_subheading_museum}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {forVisitors?.railway_schedule_for_visitors?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item.museum ? moment(item.museum, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.kalns ? moment(item?.kalns, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.kalns_2 ? moment(item?.kalns_2, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.museum_2 ? moment(item?.museum_2, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4}>{forVisitors?.railway_break_time_subheading}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {forVisitors?.railway_schedule_break_time_for_visitors?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item.museum ? moment(item.museum, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.kalns ? moment(item?.kalns, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.kalns_2 ? moment(item?.kalns_2, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {item?.museum_2 ? moment(item?.museum_2, `HH:mm.SS.SSS`).format(`HH:mm`) : "-"}
                                                    </Frame>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </TimeTable>
                        </TableWrapper>
                    </TableOuterWrapper>

                    <TableOuterWrapper
                        extra={css`
                            @media only screen and (min-width: 770px) {
                                width: 35% !important;
                                margin-top: 100px;
                            }
                        `}
                    >
                        <TableWrapper>
                            <TimeTable>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>{forVisitors?.railway_timetable_subheading_museum_museum}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {forVisitors?.railway_schedules?.map((element) => {
                                        return (
                                            <tr key={element?.id}>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {moment(element.departure_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                    </Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>~</Frame>
                                                </td>
                                                <td>
                                                    <Frame extra={({ theme }) => `color: ${theme.text.secondary}`}>
                                                        {moment(element.arrival_time, `HH:mm.SS.SSS`).format(`HH:mm`)}
                                                    </Frame>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </TimeTable>
                        </TableWrapper>
                    </TableOuterWrapper>
                </RowWrapper>
            </Frame>
        </Container>
    );
};

export const TableOuterWrapper = styled(Frame)`
    position: relative;
    z-index: 0;
    width: 100%;
    justify-content: stretch;
`;

export const TableWrapper = styled(Frame)`
    border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
    padding: 1rem;
    background: ${({ backgroundType, theme }) => (backgroundType === "dark" ? theme.text.primary : theme.background.light)};
    gap: 20px 0px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 33px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
        content: "";

        z-index: -1;
        border: 2px solid ${({ borderType, theme }) => (borderType === "dark" ? theme.text.primary : theme.background.secondary)};
        border-radius: ${({ theme }) => theme.borderRad.primarySm} ${({ theme }) => theme.borderRad.primarySm} 0px 0px;
        position: absolute;
        top: -5%;
        right: -5%;
        bottom: 5%;
        left: 5%;
    }

    @media only screen and (min-width: 770px) {
        padding: 2.5rem;
        border-radius: ${({ theme }) => theme.borderRad.primary} ${({ theme }) => theme.borderRad.primary} 0px 0px;
        &:before {
            border-radius: ${({ theme }) => theme.borderRad.primary} ${({ theme }) => theme.borderRad.primary} 0px 0px;
        }
    }
`;

export const TimeTable = styled.table`
    width: 100%;

    td {
        padding: 12px 0;
    }

    td,
    th {
        color: ${({ theme }) => theme.text.secondary};
    }
    th {
        font-family: Playfair Display;
        font-weight: bold;
        font-size: ${({ theme }) => theme.font.railwayScheduleTh};
        color: #ffffff;
    }
`;

export default RailwaySchedule;
