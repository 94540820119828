import React, { lazy, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Frame, Image, RowWrapper } from "./styled-templates";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import Markdown from "../tools/markdown";
import ModalsHelper from "../../utils/modals-helper";
import { MODALS } from "../../constants/config";
import useLocale from "../../hooks/useLocale";
import { dateLocales } from "../../constants/i18n";

const NewsItem = ({ newsItem, project = false }) => {
    const { locale } = useLocale();

    const image = project ? newsItem?.project_cover : newsItem?.cover_image?.formats?.small;
    const alternartiveText = project ? newsItem?.project_cover?.alternartiveText : newsItem?.cover_image?.alternativeText;
    const pub_date = project ? newsItem?.project_date : newsItem?.pub_date;
    const title = project ? newsItem?.project_title : newsItem?.title;
    const description = project ? newsItem?.project_description : newsItem?.description;
    const name = project ? newsItem?.project_category : newsItem?.museum?.name;

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () => {
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text });
            },
    };

    const renderNewsItem = () => {
        return (
            <NewsItemCard.Wrapper>
                <Image
                    src={image}
                    alt={alternartiveText}
                    extra={css`
                        max-height: 200px;
                        height: 100%;
                    `}
                />
                <NewsItemCard.InnerWrapper>
                    <RowWrapper extra={`align-items: flex-start; justify-content: flex-start;`}>
                        <NewsItemCard.PubDate>
                            <span>{new Date(pub_date).getDate()}</span>
                            <span>{new Date(pub_date).toLocaleString(dateLocales[locale], { month: "short" })}</span>
                        </NewsItemCard.PubDate>
                        <Frame extra={`align-items: flex-start;`}>
                            <NewsItemCard.MuseumName>{name}</NewsItemCard.MuseumName>
                            <NewsItemCard.Title>{title}</NewsItemCard.Title>
                        </Frame>
                    </RowWrapper>
                    <NewsItemCard.ContentWrapper>
                        <Markdown>{description}</Markdown>
                    </NewsItemCard.ContentWrapper>
                </NewsItemCard.InnerWrapper>
            </NewsItemCard.Wrapper>
        );
    };

    if (!project) {
        return (
            <Link
                to={`/news/article/${newsItem?.title
                    .toLowerCase()
                    .replace(/[^a-zA-ZāēīūčņšžķļĀĒĪŪČŅŠŽĶĻ ]/g, "")
                    .replace(/ /g, "_")}?id=${newsItem?.id}`}
            >
                {renderNewsItem()}
            </Link>
        );
    } else {
        return (
            <ProjectWrapper onClick={handlers.openMuseumModal({ title: newsItem?.project_title, text: newsItem?.project_content })}>
                {renderNewsItem()}
            </ProjectWrapper>
        );
    }
};

const ProjectWrapper = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    text-align: initial;
    margin: 0;
    padding: 0;
`;

const NewsItemCard = {
    Title: styled(Frame)`
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #262626;
    `,
    PubDate: styled(Frame)`
        padding: 17px 25px;
        box-sizing: border-box;
        background: #8b7672;
        box-shadow: 0px 15px 30px rgba(77, 86, 196, 0.08);
        border-radius: 20px 0px 0px 0px;
        margin-right: 20px;
        font-family: Crimson Text;
        font-style: normal;
        font-weight: bold;
        line-height: 180%;
        text-align: center;
        color: #262626;
        > * {
            &:nth-child(1) {
                font-size: 28px;
            }
        }

        span {
            font-family: "Constantia";
            font-style: normal;
            text-transform: uppercase;
        }

        span:first-child {
            font-weight: 700;
            font-size: 28px;
            line-height: 140%;
        }
    `,
    InnerWrapper: styled(Frame)`
        padding: 25px 8px 14px 20px;
        box-sizing: border-box;
        background: #eee2d1;
        width: 100%;
        min-height: 225px;
        gap: 1rem;
        justify-content: flex-start;

        @media (max-width: 1438px) {
            min-height: 280px;
        }
    `,
    MuseumName: styled(Frame)`
        font-family: Playfair Display;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 115%;
        color: #523634;
        margin-bottom: 10px;
    `,
    Wrapper: styled(Frame)`
        max-width: 400px;
        width: 100%;
        z-index: 5;

        ${({ extra }) => extra}
    `,
    ContentWrapper: styled(Frame)`
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #3c4858;
        opacity: 0.5;
        width: 100%;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    `,
};

export default NewsItem;
