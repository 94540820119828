/*eslint-disable*/
import React, { useEffect } from "react";
import { putStorage, useStorageListener } from "./useStorage";

const useLocale = () => {
    const locale = useStorageListener((state) => state?.locale ?? localStorage.getItem("locale") ?? `lv`);
    const setLocale = (locale) => {
        putStorage(`locale`, locale);
        localStorage.setItem(`locale`, locale);
        // window.location.reload();
    };
    return { locale, setLocale };
};

export default useLocale;
/*eslint-enable*/
