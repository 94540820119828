export const BASE_URL = `https://panel.ventspils-museum.website`;

export const EVENTS = {
    UPDATE_STORAGE: "UPDATE_STORAGE",
    CLOSE_DROPDOWN: "CLOSE_DROPDOWN",
};

export const SORT_ORDERS = {
    ASC: "ASC",
    DESC: "DESC",
};

export const TABLES = {
    PROPERTY_OWNER: "property-owner",
    PROPERTY: "property",
    TASKS: "tasks",
    USERS: "users",
};

export const MODALS = {
    PEDAGOGICAL_MODAL: "PEDAGOGICAL_MODAL",
    GALLERY_MODAL: "GALLERY_MODAL",
    EVENTS_CALENDAR_MODAL: "EVENTS_CALENDAR_MODAL",
};

export const ROLES = {
    Representative: "Representative",
    PropertyOwner: "Property owner",
    ClIPADUser: "CliPAD user",
    Admin: "Admin",
};

export const STATUSES = [`New`, `Under Attorney Review`, `Changes Needed`, `Complete`, `Filed with the Tax Commission`];
