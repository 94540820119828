/*eslint-disable*/
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import { SectionHeader } from "./museum-page";

import { BASE_URL, MODALS } from "../../constants/config";

import { useStorageListener } from "../../hooks/useStorage";
import ModalsHelper from "../../utils/modals-helper";

const PublicationsPage = () => {
    const museums = useStorageListener((state) => state?.museums ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const [publications, setPublications] = useState([]);
    // console.log("museums >>", museums);

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () => {
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text });
            },
    };

    useEffect(() => {
        setPublications(() => {
            const publicationsArray = [];
            museums.forEach((museum) => {
                publicationsArray.push(...museum?.publications);
            });
            return publicationsArray;
        });
    }, [museums]);
    return (
        <>
            {/* PUBLICATIONS */}
            {publications?.length > 0 && (
                <Container variant={`light`} extra={`align-items: flex-start;`} id="publications">
                    <Frame
                        extra={css`
                            align-items: flex-start;
                            gap: 2rem;
                        `}
                    >
                        <RowWrapper
                            extra={css`
                                align-items: flex-end;
                            `}
                        >
                            <SectionHeader
                                serif
                                variant={`light`}
                                extra={css`
                                    margin: 0;
                                `}
                            >
                                {dictionary?.publications_heading}
                            </SectionHeader>
                            <PurchaseRemoteButton onClick={handlers.openMuseumModal({ text: museums?.[0]?.publications_text })}>
                                {dictionary?.publication_purchase_subheading}
                            </PurchaseRemoteButton>
                        </RowWrapper>
                        <PublicationsWrapper>
                            {publications?.map?.(({ photo = {}, name = ``, publication_description = `` }, index) => {
                                return (
                                    <PublicationsItem.Wrapper
                                        key={index}
                                        onClick={handlers.openMuseumModal({ title: name, text: publication_description })}
                                    >
                                        <Image
                                            src={photo}
                                            alt={photo?.alternativeText}
                                            extra={css`
                                                max-width: 295px;
                                                max-height: 423px;

                                                ${index % 2 !== 0
                                                    ? css`
                                                          border-radius: 0 ${({ theme }) => theme.borderRad.primarySm} 0 0;
                                                      `
                                                    : css`
                                                          border-radius: ${({ theme }) => theme.borderRad.primarySm} 0 0 0;
                                                      `}

                                                @media only screen and (max-width: 500px) {
                                                    max-width: unset;
                                                    max-height: unset;
                                                    border-radius: ${({ theme }) => theme.borderRad.primarySm}
                                                        ${({ theme }) => theme.borderRad.primarySm} 0 0;
                                                }
                                            `}
                                        />
                                        <span>{name}</span>
                                    </PublicationsItem.Wrapper>
                                );
                            })}
                        </PublicationsWrapper>
                    </Frame>
                </Container>
            )}
        </>
    );
};

const PublicationsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: stretch;

    gap: 1rem;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 770px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const PurchaseRemoteButton = styled.button`
    border: 0;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

    font-family: "Constantia";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.headerSm};
`;

const PublicationsItem = {
    Wrapper: styled.button`
        align-self: stretch;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 295px;
        cursor: pointer;

        &:hover span {
            background: rgba(203, 195, 194, 1);
        }

        span {
            min-height: 82px;
            width: 100%;
            font-family: Playfair Display;
            font-size: ${({ theme }) => theme.font.paragraph};
            color: ${({ theme }) => theme.text.primary};

            background: rgba(255, 255, 255, 0.5);
            border-radius: 0 0 10px 10px;
            backdrop-filter: blur(4px);
            padding: 1rem 1.5rem;
            box-sizing: border-box;
        }

        @media only screen and (max-width: 500px) {
            max-width: unset;
        }
        /* width: 348px;
        height: 497px;
        ${({ src = {} }) => src?.url && `background: url("${`${BASE_URL}${src?.url}`}") no-repeat center center / contain;`}
        background-size: auto 100%;
        transform: scale(0.9);
        position: relative;
        cursor: pointer;

        &:after {
            width: 360px;
            min-height: max-content;
            content: "${({ subtext }) => subtext}";
            font-family: Playfair Display;
            font-size: 24px;
            line-height: 29px;
            color: ${({ theme }) => theme.text.primary};

            background: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            backdrop-filter: blur(4px);
            padding: 20px 25px;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(25px, 50%);
        }
        &:hover::after {
            background: rgba(203, 195, 194, 1);
        }
        @media (max-width: 425px) {
            &:after {
                font-size: 20px;
            }
        } */
    `,
};

export default PublicationsPage;
/*eslint-enable*/
