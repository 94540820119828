/*eslint-disable*/
import React from "react";
import { css } from "styled-components";
import { Frame } from "../ui-kit/styled-templates";

const GoogleMapByIframe = ({ iframeAsString = `` }) => {
    if (!iframeAsString) {
        return null;
    }
    const iframeSrc = iframeAsString
        ?.split(` `)
        ?.find?.((i) => i?.includes?.(`src`))
        ?.replace?.(`src="`, ``)
        ?.slice?.(0, -1)
        ?.replaceAll("1sru", "1slv")
        ?.replaceAll("2sru", "2sen");

    return (
        <Frame
            extra={({ theme }) => css`
                border-radius: ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm} 0;
                width: 100%;
                max-width: 100%;
                height: auto;
                overflow: hidden;
                z-index: 5;
            `}
        >
            <iframe src={iframeSrc} width="100%" height="450" allowFullScreen loading="lazy" title="Google Maps location"></iframe>
        </Frame>
    );
};

export default GoogleMapByIframe;
/*eslint-enable*/
