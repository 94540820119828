import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const SeeMore = ({ children, ...props }) => {
    return (
        <SeeMoreButton {...props}>
            {children}
            <Arrow />
        </SeeMoreButton>
    );
};

const Arrow = styled.div`
    width: 53px;
    min-width: 53px;
    height: 20px;
    display: block;
    background: url("${require(`../../assets/icons/long-right-arrow-yellow.svg`).default}") no-repeat center center / contain;
    background-size: 100% auto;
    transition: 0.2s;
    position: relative;

    &:before {
        content: "";
        height: 39px;
        width: 39px;
        border-radius: 100%;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        position: absolute;
        top: -9.5px;
        right: -14.5px;
    }
`;

const SeeMoreButton = styled(Link)`
    border: none;
    background: transparent;
    padding: 0;
    font-family: "Constantia";
    font-size: 24px;
    letter-spacing: -0.022em;
    color: ${({ theme }) => theme.text.secondary};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    cursor: pointer;

    &:hover {
        div:before {
            transition: 0.3s ease;
            transform: translate(3px, 0);
        }
    }

    ${({ extra }) => extra}
`;

export default SeeMore;
