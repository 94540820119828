/*eslint-disable*/
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import { Article } from "./news-article-page";
import { Socials } from "../tools/footer";

import { useStorageListener } from "../../hooks/useStorage";
import useLocale from "../../hooks/useLocale";
import MuseumsAPI from "../../api/museums-api";

const EventPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getEvents();
        MuseumsAPI.getTicketLinks();
        MuseumsAPI.getBookTickets();
        MuseumsAPI.getSocialMedias();
    }, [locale]);

    const bookTickets = useStorageListener((state) => state?.[`book-tickets`] ?? {});
    const ticketLinks = useStorageListener((state) => state?.[`ticket-links`] ?? {});
    const socialMedias = useStorageListener((state) => state?.["social-medias"] ?? []);
    const events = useStorageListener((state) => state?.events);
    const event = events?.filter((event) => event.id === Number(id))[0];

    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    // const { goBack } = useHistory();

    const handlers = {
        redirectToBook: () => {
            ticketLinks?.map((link) => {
                if (event?.ticket_link === link?.id) {
                    return window.open(link?.mobilly_link, "_blank");
                }
                // return window.open(dictionary?.tickets_redirect_url, "_blank");
            });
        },
    };
    // useEffect(() => {
    //     if (!event) {
    //         goBack();
    //     }
    // }, [event]);

    // console.log(ticketLinks);
    // console.log(events);
    // console.log(event);
    // console.log(dictionary);
    return (
        <>
            <Container extra={`background: none; padding:1rem 0; position:relative; z-index:0;`}>
                <Image
                    src={event?.photo}
                    alt={event?.photo?.alternativeText}
                    extra={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        width: 100%;
                        max-width: unset;
                    `}
                />
                <Frame
                    extra={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                        z-index: 1;
                    `}
                />
                <Frame
                    extra={css`
                        min-height: 360px;
                        justify-content: flex-end;
                        align-items: flex-start;

                        gap: 1rem;
                        z-index: 2;
                        position: relative;
                    `}
                >
                    <Article.Title serif>{event?.title}</Article.Title>
                    <RowWrapper
                        extra={css`
                            align-items: flex-start;
                            flex-wrap: wrap;
                            gap: 1rem;
                        `}
                    >
                        <Article.MuseumName>{event?.event_location}</Article.MuseumName>
                        <RowWrapper
                            extra={css`
                                width: auto;
                                gap: 1rem;
                                color: ${({ theme }) => theme.text.primary} !important;
                                background: ${({ theme }) => theme.background.secondary};
                                box-sizing: border-box;
                                border-radius: 10px;
                                padding: 0.5rem 1rem;
                            `}
                        >
                            {[
                                [dictionary?.date_subheading, event?.event_date],
                                [dictionary?.price_subheading, event?.event_price],
                                [dictionary?.location_subheading, event?.event_location],
                            ].map(([label, value], index) => (
                                <Event.Footer.Wrapper key={index}>
                                    <Event.Footer.Label extra={`color: #523634; font-weight: normal; font-size: 14px; line-height: 18px;`}>
                                        {label}
                                    </Event.Footer.Label>
                                    <Event.Footer.Value extra={`color: #523634; font-weight: bold; font-size: 18px; line-height: 18px;`}>
                                        {value}
                                    </Event.Footer.Value>
                                </Event.Footer.Wrapper>
                            ))}
                        </RowWrapper>
                    </RowWrapper>
                </Frame>
            </Container>

            <Container variant={`light`} extra={`justify-content: flex-start; align-items: flex-start;.`}>
                <RowWrapper
                    extra={css`
                        align-items: flex-start;
                        gap: 50px 50px;
                        @media (max-width: 768px) {
                            flex-wrap: wrap;
                            justify-content: center;
                        }
                    `}
                >
                    <Frame extra={`align-items: flex-start; justify-content: flex-start;`}>
                        <Frame
                            extra={css`
                                max-width: 1000px;
                                width: 100%;
                                margin-bottom: 56px;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 22px;
                                letter-spacing: 0.3px;
                                div {
                                    width: 100%;
                                }
                                div > p > img {
                                    max-width: 100%;
                                    object-fit: contain;
                                }
                            `}
                        >
                            <Markdown>{event?.content ?? ``}</Markdown>
                        </Frame>
                        <RowWrapper extra={`max-width: 205px; width:100%; font-weight: bold; font-size: 12px; line-height: 48px;`}>
                            {dictionary?.share_button}{" "}
                            <Frame extra={`flex-direction: row; gap: 0px 26px;`}>
                                {socialMedias?.map((social, index) => {
                                    if (social?.social_media_url.split("https://")[1].includes("facebook")) {
                                        return (
                                            <FacebookShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                                <Socials icon={social?.icon_brown_news} />
                                            </FacebookShareButton>
                                        );
                                    } else if (social?.social_media_url.split("https://")[1].includes("twitter")) {
                                        return (
                                            <TwitterShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                                <Socials icon={social?.icon_brown_news} />
                                            </TwitterShareButton>
                                        );
                                    } else if (social?.social_media_url.split("https://")[1].includes("instagram")) {
                                        return null;
                                    } else if (social?.social_media_url.split("https://")[1].includes("draugiem")) {
                                        return null;
                                    } else {
                                        return (
                                            <EmailShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                                <Socials icon={social?.icon_brown_news} />
                                            </EmailShareButton>
                                        );
                                    }
                                })}
                            </Frame>
                        </RowWrapper>
                    </Frame>
                    {/* <Event.Wrapper
                        extra={css`
                            background: #523634;
                            border-radius: 150px 0px;
                           
                            height: min-content;
                            max-width: 368px;
                            width: 100%;
                            justify-content: flex-start;
                        `}
                    >
                        <Event.Title serif>{bookTickets?.book_tickets_heading}</Event.Title>
                        <Event.Subtitle>{bookTickets?.book_tickets_text}</Event.Subtitle>
                        {/* <Frame extra={`gap: 19px 0px; width: 100%; margin-bottom: 36px;`}>
                            <Frame
                                extra={`height: 50px; max-width: 282px; width: 100%; background: #eee2d1; border-radius: 5px; padding: 14px 42px 14px 20px; box-sizing: border-box; position: relative;`}
                            >
                                <input type="text" placeholder="Your name"></input>
                            </Frame>
                            <Frame
                                extra={`height: 50px; max-width: 282px; width: 100%; background: #eee2d1; border-radius: 5px; padding: 14px 42px 14px 20px; box-sizing: border-box; position: relative;`}
                            >
                                <input type="email" placeholder="Email"></input>
                            </Frame>
                        </Frame> */}
                    {/* <Event.Count>
                            <RowWrapper extra={`padding: 0px 10px; box-sizing: border-box;`}>
                                <Event.Minus
                                onClick={() => {
                                    setTicketCount(ticketCount - 1);
                                    if (ticketCount === 0) {
                                        setTicketCount(0);
                                    }
                                }}
                                />
                                {ticketCount}0
                                <Event.Plus
                                onClick={() => {
                                    setTicketCount(ticketCount + 1);
                                }}
                                />
                            </RowWrapper>
                        </Event.Count> 
                        <Event.Button onClick={handlers.redirectToBook}>{bookTickets?.book_button}</Event.Button>
                    </Event.Wrapper> */}
                </RowWrapper>
            </Container>
        </>
    );
};

const Event = {
    Wrapper: styled(Frame)`
        box-sizing: border-box;
        padding: 65px 43px;
        input[type="text"],
        input[type="email"] {
            width: 100%;
            background: none;
            outline: none;
            border: none;
            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: ${({ theme }) => theme.text.primary};
                font-size: 18px;
                line-height: 28px;
                opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: ${({ theme }) => theme.text.primary};
                font-size: 18px;
                line-height: 28px;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: ${({ theme }) => theme.text.primary};
                font-size: 18px;
                line-height: 28px;
            }
        }
    `,
    Title: styled(Frame)`
        font-weight: normal;
        font-size: ${({ theme }) => theme.font.articleMainHeader};
        color: ${({ theme }) => theme.text.secondary};
        margin-bottom: 36px;
        text-align: center;
    `,
    Subtitle: styled(Frame)`
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: ${({ theme }) => theme.text.secondary};
        opacity: 0.7;
        text-align: center;
        margin-bottom: 27px;
    `,
    Count: styled(Frame)`
        width: 203px;
        height: 50px;
        border: 1px solid ${({ theme }) => theme.background.secondary};
        box-sizing: border-box;
        border-radius: 5px;
        color: ${({ theme }) => theme.background.secondary};
        margin-bottom: 61px;
    `,
    Plus: styled(Frame)`
        width: 20px;
        height: 20px;
        background: url("${require(`../../assets/icons/event-plus.svg`).default}") no-repeat center center / contain;
        background-size: 100% auto;
        cursor: pointer;
        ${({ extra }) => extra}
    `,
    Minus: styled(Frame)`
        background: url("${require(`../../assets/icons/event-minus.svg`).default}") no-repeat center center / contain;
        width: 20px;
        height: 20px;
        background-size: 100% auto;
        cursor: pointer;
        ${({ extra }) => extra}
    `,
    Button: styled(Frame)`
        height: 58px;
        width: 216px;
        border-radius: 5px;
        background: ${({ theme }) => theme.background.secondary};
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        cursor: pointer;
        color: ${({ theme }) => theme.background.primary};
    `,
    Footer: {
        Wrapper: styled(Frame)`
            justify-content: flex-start;
        `,
        Label: styled.p`
            margin: 0;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
        `,
        Value: styled.p`
            margin: 0;
            font-family: "Heebo";
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 18px;
        `,
    },
};
export default EventPage;
/*eslint-enable*/
