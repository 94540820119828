import { Suspense } from "react/cjs/react.production.min";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import RouterApp from "./components/apps/router-app";

import theme from "./constants/theme-constants";
import { setUpInterceptors } from "./utils/api-helper";

setUpInterceptors();

const App = () => {
    return (
        <Suspense fallback={`Loading...`}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <RouterApp />
                </BrowserRouter>
            </ThemeProvider>
        </Suspense>
    );
};

export default App;
