import React from "react";
import styled, { css } from "styled-components";
import { Frame, Image, RowWrapper } from "./styled-templates";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useStorageListener } from "../../hooks/useStorage";
import Markdown from "../tools/markdown";

const EventItem = ({ title = "", index = 0, event_id = 0, photo = {}, description = "", event_date = "", event_location = "", event_price = "" }) => {
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    return (
        <Events.Item.Wrapper to={`/museum/event/${title.toLowerCase().replaceAll(" ", "_")}?id=${event_id}`} index={index}>
            <Image
                src={photo}
                extra={css`
                    height: 250px;
                    max-width: unset;
                `}
            />
            <Events.Item.Body>
                <Events.Item.Title>{title}</Events.Item.Title>
                <Events.Item.Description>
                    <Markdown>{description}</Markdown>
                </Events.Item.Description>
            </Events.Item.Body>
            <Events.Item.Footer.Wrapper>
                {[
                    [dictionary?.date_subheading, event_date],
                    [dictionary?.price_subheading, event_price],
                    [dictionary?.location_subheading, event_location],
                ].map(([label, value], index) => (
                    <Events.Item.Footer.ItemWrapper key={index}>
                        <Events.Item.Footer.Label>{label}</Events.Item.Footer.Label>
                        <Events.Item.Footer.Value>{value}</Events.Item.Footer.Value>
                    </Events.Item.Footer.ItemWrapper>
                ))}
            </Events.Item.Footer.Wrapper>
        </Events.Item.Wrapper>
    );
};

const Events = {
    Item: {
        Wrapper: styled(Link)`
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            flex-wrap: wrap;
            overflow: hidden;
            border-radius: ${({ index, theme }) => (index % 2 === 0 ? `${theme.borderRad.primary} 0 0 0` : `0 ${theme.borderRad.primary} 0 0`)};

            @media only screen and (max-width: 768px) {
                border-radius: ${({ index, theme }) =>
                    index % 2 === 0 ? `${theme.borderRad.primarySm} 0 0 0` : `0 ${theme.borderRad.primarySm} 0 0`};
            }

            ${({ extra }) => extra}
        `,
        Body: styled(Frame)`
            padding: 20px;
            box-sizing: border-box;
            width: 100%;
            align-items: flex-start;
            background: #977d6b;
            color: ${({ theme }) => theme.text.secondary};
            gap: 1rem;
        `,
        Title: styled.h3`
            font-family: "Constantia";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            text-transform: uppercase;
            color: ${({ theme }) => theme.text.secondary};
            margin: 0;
        `,
        Description: styled(Frame)`
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;
            margin-bottom: 20px;
        `,
        Footer: {
            Wrapper: styled(RowWrapper)`
                padding: 20px;
                box-sizing: border-box;
                width: 100%;
                background: #8d7363;
                flex-wrap: wrap;
                gap: 20px 10px;
                align-items: flex-start;
            `,
            ItemWrapper: styled(Frame)`
                width: 100%;
                flex: 1;
                align-items: flex-start;
                justify-content: flex-start;
            `,
            Label: styled(Frame)`
                font-size: 14px;
                line-height: 18px;
                color: #523634;
                margin-bottom: 10px;
                ${({ extra }) => extra}
            `,
            Value: styled(Frame)`
                font-size: 18px;
                line-height: 18px;
                color: #ffffff;
                ${({ extra }) => extra}
            `,
        },
    },
    Wrapper: styled(RowWrapper)`
        > * {
            overflow: hidden;
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(2n) {
                border-top-right-radius: 50px;
            }
            &:nth-child(2n + 1) {
                border-top-left-radius: 50px;
            }
            @media only screen and (min-width: 768px) {
                &:nth-child(2n) {
                    border-top-right-radius: 100px;
                }
                &:nth-child(2n + 1) {
                    border-top-left-radius: 100px;
                }
            }
        }
    `,
};
export default EventItem;
