/*eslint-disable*/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useHashScroller = () => {
    const { hash } = useLocation();
    useEffect(() => hash && document.querySelector(`${hash}`)?.scrollIntoView(), [hash]);
};

export default useHashScroller;
/*eslint-enable*/
