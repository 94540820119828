/*eslint-disable*/
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Frame, RowWrapper, Container } from "../ui-kit/styled-templates";
import { Page, SectionHeader, BottomButton, BottomButtonSVG } from "../pages/museum-page";
import { News } from "./news-page";
import { useStorageListener } from "../../hooks/useStorage";
import NewsItem from "../ui-kit/newsItem";
import useLocale from "../../hooks/useLocale";
import MuseumsAPI from "../../api/museums-api";
import { SectionParagraph } from "../museums-ui-blocks/about-block";

const ProjectsPage = () => {
    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getProjectsPage();
    }, [locale]);

    const projects = useStorageListener((state) => state?.["projects-page"]?.projects ?? []);
    console.log(`projects ==>`, projects);

    const projects_headings = useStorageListener((state) => state?.["projects-page"]?.projects_headings ?? []);

    const projects_description = useStorageListener((state) => state?.["projects-page"]?.projects_description ?? []);

    const dictionary = useStorageListener((state) => state?.dictionary ?? []);

    const navBar = useStorageListener((state) => state?.["navbar"] ?? []);

    const [seeMore, setSeeMore] = useState([]);

    useEffect(() => {
        setSeeMore(new Array(projects_headings?.length).fill(3));
    }, [projects_headings]);

    const navBarsID = navBar?.navbar_with_items?.menu_items?.[3]?.dropdown_menu_items?.map((item) => {
        return item.url.split("#").slice(-1);
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);

    return (
        <Page
            extra={`> * {
            min-height: 0;

            &:first-child {
                height: auto;
            }
        }`}
        >
            {/* MAIN */}
            <Container variant={`light`}>
                <Frame
                    extra={css`
                        align-items: flex-start;
                        gap: 2rem;
                    `}
                >
                    <Frame
                        extra={css`
                            align-items: flex-start;
                        `}
                    >
                        <SectionHeader variant={`light`} serif extra={({ theme }) => `color: ${theme.text.primary};`}>
                            {dictionary?.projects_heading}
                        </SectionHeader>
                        <SectionParagraph>{projects_description}</SectionParagraph>
                    </Frame>

                    <a href={`/projects#${buttonBottomToggle ? `top` : `bottom`}`}>
                        <BottomButton>
                            <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                        </BottomButton>
                    </a>

                    {/* PROJECTS */}
                    <Frame
                        extra={css`
                            gap: 4rem;
                        `}
                    >
                        {projects_headings?.map?.((projectGroup, headingIndex) => {
                            return (
                                <Frame key={headingIndex}>
                                    <ProjectHeader serif id={navBarsID?.[headingIndex]}>
                                        {projectGroup?.heading}
                                    </ProjectHeader>
                                    <Frame extra={`gap: 2rem`}>
                                        <ProjectsWrapper>
                                            {projects
                                                ?.filter((item) => {
                                                    return item.projects_heading === projectGroup.id;
                                                })
                                                ?.slice(0, seeMore[headingIndex])
                                                ?.map?.((project, index) => {
                                                    return <NewsItem key={index} newsItem={project} project />;
                                                })}
                                        </ProjectsWrapper>
                                        <Frame
                                            extra={
                                                seeMore[headingIndex] >=
                                                projects?.filter((item) => {
                                                    return item?.projects_heading === projectGroup?.id;
                                                }).length
                                                    ? `display: none;`
                                                    : `width:100%;`
                                            }
                                            onClick={() => {
                                                setSeeMore(seeMore.map((i, j) => (j === headingIndex ? i + 3 : i)));
                                            }}
                                        >
                                            <News.Button>{dictionary?.see_more_button}</News.Button>
                                        </Frame>
                                    </Frame>
                                </Frame>
                            );
                        })}
                    </Frame>
                </Frame>
            </Container>
        </Page>
    );
};

const ProjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 1rem;

    @media only screen and (max-width: 770px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;

const ProjectHeader = styled(Frame)`
    border-radius: ${({ theme }) => theme.borderRad.primary} 0;
    background: ${({ theme }) => theme.background.primary};
    max-width: 800px;
    width: 100%;
    min-height: 70px;
    font-weight: normal;
    font-size: ${({ theme }) => theme.font.projectGroupHeader};
    color: ${({ theme }) => theme.text.secondary} !important;
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 20px 40px;

    @media only screen and (max-width: 770px) {
        border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
    }
`;
export default ProjectsPage;

/*eslint-enable*/
