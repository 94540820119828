/*eslint-disable*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import CircleType from "circletype";

import Markdown from "../tools/markdown";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import {
    Page,
    ShortInfoHeading,
    ShortInfoDescription,
    ShortInfoRightImage,
    CircleText,
    aboutCircleText,
    SectionHeader,
    Collections,
    OpeningImage,
    PedagogicalProgrammsImage,
    PedagogicalProgrammsButton,
    GallerArrow,
    PhotoItem,
    Events,
    NewsItem,
    ContactsItem,
    Expositions,
    BottomButton,
    BottomButtonSVG,
    PedagogicalProgrammsWrapper,
} from "../pages/museum-page";
import { AccreditationImage } from "./about-museum-page";

import { Strategy } from "./about-museum-page";

import { BASE_URL, MODALS } from "../../constants/config";

import { putStorage, useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import ModalsHelper from "../../utils/modals-helper";
import GoogleMapByIframe from "../tools/google-map-by-iframe";
import ContactsBlock from "../museums-ui-blocks/contacts-block";
import MuseumContactBlock from "../museums-ui-blocks/museum-contact-block";
import AboutBlock, { SectionParagraph } from "../museums-ui-blocks/about-block";
import MuseumsAPI from "../../api/museums-api";
import useLocale from "../../hooks/useLocale";

const VirtualMuseumPage = () => {
    const { locale } = useLocale();
    useEffect(() => {
        MuseumsAPI.getVirtualMuseum();
    }, [locale]);

    const museum = useStorageListener((state) => state?.["virtual-museum"] ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});

    const videosPagination = usePagination(museum?.videos ?? [], { perPage: window.innerWidth <= 425 ? 1 : 2, scrollDisabled: true });

    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);
    // console.log(videosPagination);

    const handlers = {
        openMuseumModal:
            ({ title, text }) =>
            () => {
                ModalsHelper.showModal(MODALS.PEDAGOGICAL_MODAL, { title, text });
            },
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    // console.log(dictionary);
    // console.log(`museum ==>`, museum);
    return (
        <Page>
            {/* ABOUT MUSEUM */}
            <Container variant={`light`} extra={`overflow: hidden; height: auto !important;`} id="about">
                <AboutBlock
                    heading={dictionary?.virtual_museum_heading}
                    paragraph={museum?.virtual_museum_description}
                    imageSrc={museum?.virtual_museum_photo?.[0]}
                />

                <a href={`/virtual-museum#${buttonBottomToggle ? `top` : `bottom`}`}>
                    <BottomButton>
                        <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                    </BottomButton>
                </a>
                {/* <RowWrapper
                    extra={css`
                        flex-direction: row;
                        justify-content: space-between;
                        position: relative;
                        @media (max-width: 1438px) {
                            flex-direction: column;
                            gap: 50px 0px;
                        }
                    `}
                >
                    <Frame
                        extra={css`
                            width: 550px;
                            align-items: flex-start;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 140%;
                            @media (max-width: 1438px) {
                                width: 100%;
                            }
                        `}
                    >
                        <ShortInfoHeading serif>{dictionary?.virtual_museum_heading}</ShortInfoHeading>
                        <ShortInfoDescription
                            extra={css`
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 170%;
                            `}
                        >
                            {museum?.virtual_museum_description}
                        </ShortInfoDescription>
                    </Frame>
                    {/* <ShortInfoRightImage
                        src={museum?.virtual_museum_photo?.[0]}
                        extra={css`
                            border-radius: 180px 0px;
                            z-index: 5;
                            position: relative;
                        `}
                    ></ShortInfoRightImage> 
                    <Image
                        src={museum?.virtual_museum_photo?.[0]}
                        extra={css`
                            border-radius: 180px 0px;
                            z-index: 5;
                            position: relative;
                        `}
                    /> 
                </RowWrapper>*/}
            </Container>
            {/* READING ROOM */}
            <Container variant={`dark`} id="reading" extra={`overflow: hidden; position: relative;`}>
                <Frame
                    extra={css`
                        z-index: 1;
                        position: relative;
                    `}
                >
                    <RowWrapper
                        extra={css`
                            gap: 50px 50px;
                            z-index: 2;
                            height: 100%;
                            align-items: flex-start;
                            @media (max-width: 1278px) {
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }
                        `}
                    >
                        <Image
                            src={museum?.reading_room_photo}
                            extra={css`
                                border-radius: ${({ theme }) => theme.borderRad.primary} 0;
                                @media only screen and (max-width: 425px) {
                                    border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
                                }
                            `}
                        />
                        <Strategy.Desc>
                            <SectionHeader variant={`dark`} serif>
                                {dictionary?.reading_room_heading}
                            </SectionHeader>
                            <SectionParagraph
                                extra={css`
                                    color: ${({ theme }) => theme.text.secondary};
                                `}
                            >
                                <Markdown>{museum?.reading_room_description}</Markdown>
                            </SectionParagraph>
                        </Strategy.Desc>
                    </RowWrapper>
                    <PedagogicalProgrammsWrapper>
                        {museum?.reading_room_options?.map?.((item) => {
                            return (
                                <PedagogicalProgrammsButton
                                    key={item.id}
                                    onClick={handlers.openMuseumModal({ title: item?.option_name, text: item?.option_description })}
                                >
                                    {item?.option_name}
                                </PedagogicalProgrammsButton>
                            );
                        })}
                    </PedagogicalProgrammsWrapper>
                    <Collections.BackText serif extra={`bottom:0; z-index: -1;`}>
                        {dictionary?.reading_room_heading}
                    </Collections.BackText>
                </Frame>
            </Container>
            {/* STORIES */}
            <Container variant={`dark`} id="stories" extra={`overflow: hidden; position: relative;`}>
                <Frame>
                    <SectionHeader variant={`dark`} serif>
                        {dictionary?.stories_heading}
                    </SectionHeader>
                    <RowWrapper
                        extra={css`
                            gap: 2rem;
                            align-items: flex-start;
                            @media only screen and (max-width: 770px) {
                                flex-direction: column;
                                align-items: center;
                            }
                        `}
                    >
                        <SectionParagraph
                            extra={css`
                                color: ${({ theme }) => theme.text.secondary};
                                p p:first-child {
                                    margin-top: 0%;
                                }
                            `}
                        >
                            <Markdown>{museum?.stories_description}</Markdown>
                        </SectionParagraph>

                        <Image
                            src={museum?.stories_photo}
                            extra={css`
                                border-radius: 0 ${({ theme }) => theme.borderRad.primary};
                                @media only screen and (max-width: 770px) {
                                    border-radius: 0 ${({ theme }) => theme.borderRad.primarySm};
                                }
                            `}
                        />
                    </RowWrapper>
                </Frame>
            </Container>
            {/* VIDEO */}
            <Container variant={`dark`} id="video" extra={`overflow: hidden; position: relative;`}>
                <SectionHeader variant={`dark`} serif>
                    {dictionary?.video_heading}
                </SectionHeader>
                <Frame
                    extra={css`
                        gap: 1rem;
                    `}
                >
                    <RowWrapper
                        extra={css`
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            width: 100%;
                            gap: 2rem;

                            iframe,
                            video {
                                width: 100%;
                                border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
                            }

                            @media (max-width: 770px) {
                                gap: 1rem;
                                grid-template-columns: 1fr;
                            }
                        `}
                    >
                        {videosPagination?.visibleItems?.map?.((item, index) => {
                            return <Markdown key={index}>{item?.video_item ?? ``}</Markdown>;
                        })}
                    </RowWrapper>
                    <Frame extra={`flex-direction: row; gap: 0px 15px;`}>
                        <Expositions.Arrow
                            visible={!videosPagination.isFirstPage}
                            onClick={videosPagination.handlePrevPage}
                            extra={`transform: rotate(180deg); position: relative !important;`}
                        />
                        <Expositions.Arrow visible={!videosPagination.isLastPage} onClick={videosPagination.handleNextPage} />
                    </Frame>
                </Frame>
            </Container>
            {/* PEDAGOGY */}
            <Container variant={`dark`} extra={`align-items: flex-start; position: relative; word-break: break-all;`} id="pedagogy">
                <SectionHeader variant={`dark`} serif>
                    {dictionary?.museum_pedagogy_heading}
                </SectionHeader>
                <RowWrapper
                    extra={css`
                        position: relative;
                        align-items: flex-start;
                        z-index: 5;
                        gap: 1rem;

                        @media only screen and (max-width: 770px) {
                            flex-direction: column;
                            align-items: center;
                        }
                    `}
                >
                    <SectionParagraph
                        extra={css`
                            color: ${({ theme }) => theme.text.secondary};
                        `}
                    >
                        <Markdown>{museum?.museum_pedagogy_description ?? ``}</Markdown>
                    </SectionParagraph>
                    {/* <AccreditationImage url={museum?.museum_pedagogy_photo} /> */}
                    <Image
                        src={museum?.museum_pedagogy_photo}
                        extra={css`
                            border-radius: ${({ theme }) => theme.borderRad.primary} 0;
                            @media only screen and (max-width: 425px) {
                                border-radius: ${({ theme }) => theme.borderRad.primarySm} 0;
                            }
                        `}
                    />
                </RowWrapper>
                <PedagogicalProgrammsWrapper>
                    {museum?.museum_pedagogy_infos?.map?.((info) => {
                        return (
                            <PedagogicalProgrammsButton
                                key={info.id}
                                onClick={handlers.openMuseumModal({ title: info?.option_name, text: info?.option_description })}
                            >
                                {info.option_name}
                            </PedagogicalProgrammsButton>
                        );
                    })}
                </PedagogicalProgrammsWrapper>
            </Container>
            {/* CONTACTS */}
            <Container variant={`dark`} extra={`align-items: flex-start; gap: 100px;`} id="people">
                <SectionHeader variant={`dark`} serif>
                    {dictionary?.contacts_heading}
                </SectionHeader>
                {museum?.people?.length > 0 && <ContactsBlock people={museum?.people} />}
            </Container>
            <Container variant={`dark`}>
                <MuseumContactBlock light contact={museum?.contact} sendMessageText={dictionary?.send_message_button} />
            </Container>
        </Page>
    );
};

const Stories = {
    Image: styled(Frame)`
        width: 100%;
        height: 100%;
        max-height: 660px;
        border-radius: 0px 100px;
        background: url("${({ src = {} }) => `${BASE_URL}${src?.url}`}") no-repeat center center / cover;
    `,
};
export default VirtualMuseumPage;
/*eslint-enable*/
