/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import moment from "moment-timezone";
import _ from "lodash";

const SLIDER_ENABLED = false;

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import {
    Page,
    SectionHeader,
    NewsItem,
    ShortInfoDescription,
    ShortInfoHeading,
    GallerArrow,
    PhotoItem,
    BottomButton,
    BottomButtonSVG,
} from "./museum-page";

import { BASE_URL } from "../../constants/config";
import { linkTo } from "../../utils/common-helper";

import { useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import GoogleMapByIframe from "../tools/google-map-by-iframe";
import NewsBlock from "../museums-ui-blocks/news-block";
import EventsBlock, { Events } from "../museums-ui-blocks/events-block";
import MuseumContactBlock from "../museums-ui-blocks/museum-contact-block";
import MuseumsAPI from "../../api/museums-api";
import useLocale from "../../hooks/useLocale";
import SeeMoreButton from "../ui-kit/see-more";
import SeeMore from "../ui-kit/see-more";

const HomePage = () => {
    const { pathname } = useLocation();
    const { locale } = useLocale();
    const path = pathname.split(`/`).slice(1);

    // const sliderPagination = usePagination(news ?? [], { perPage: 1, scrollDisabled: true });
    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    useEffect(() => {
        MuseumsAPI.getMainPage();
        MuseumsAPI.getMuseumsNewsData();
        MuseumsAPI.getEvents();
        MuseumsAPI.getDictionary();
    }, [locale]);

    const museums = useStorageListener((state) => state?.museums ?? []);
    const mainPage = useStorageListener((state) => state?.["main-page"] ?? []);
    const news = useStorageListener((state) => state?.news ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const events = useStorageListener((state) => state?.events ?? []);
    // console.log(`events ==>`, events);

    return (
        <Page>
            <a href={`/#${buttonBottomToggle ? `top` : `bottom`}`}>
                <BottomButton>
                    <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                </BottomButton>
            </a>
            {/* SLIDER */}
            {/* {SLIDER_ENABLED && sliderPagination?.visibleItems?.length > 0 && (
                <Container variant={`dark`} extra={`align-items: flex-start; min-height: 60vh !important;`}>
                    <RowWrapper extra={`position: relative; width: 100%;`}>
                        <GallerArrow
                            visible={!sliderPagination.isFirstPage}
                            onClick={sliderPagination.handlePrevPage}
                            extra={`transform: rotate(180deg) translate(0px, 50%); width: 60px; height: 60px;`}
                        />
                        <GallerArrow
                            visible={!sliderPagination.isLastPage}
                            onClick={sliderPagination.handleNextPage}
                            extra={`transform: translate(0px, -50%); right: 0; width: 60px; height: 60px;`}
                        />
                        <RowWrapper
                            extra={`flex-wrap: wrap; justify-content: flex-start; padding: 0px 70px; 
                        @media (max-width: 768px) {
                            padding: 0;
                        }`}
                        >
                            {sliderPagination?.visibleItems?.map?.(({ title, cover_image, description }, index, self) => {
                                return (
                                    <RowWrapper
                                        key={index}
                                        extra={css`
                                            gap: 0px 30px;
                                            @media (max-width: 768px) {
                                                flex-direction: column-reverse;
                                                gap: 50px 0px;
                                            }
                                        `}
                                    >
                                        <Frame extra={`justify-content: flex-start; align-items: flex-start;  width: 100%;`}>
                                            <Frame
                                                serif
                                                extra={`font-weight: bold; font-size: 64px; line-height: 120%; margin-bottom: 12px;
                                                @media (max-width: 768px) {
                                                    font-size: 30px; 
                                                    line-height: 120%;
                                                }`}
                                            >
                                                {title}
                                            </Frame>
                                            <Frame
                                                extra={`font-weight: 300; font-size: 20px; line-height: 120%; margin-bottom: 45px;
                                            @media (max-width: 768px) {
                                                font-size: 15px; 
                                                line-height: 120%;
                                            }`}
                                            >
                                                {description.slice(0, 100)}
                                                {" [...]"}
                                            </Frame>
                                            <Link to={`/news`}>
                                                <Events.SeeMore serif>{dictionary?.see_more_button}</Events.SeeMore>
                                            </Link>
                                        </Frame>
                                        <Slider.Image
                                            src={cover_image?.formats?.medium ?? cover_image?.formats?.small ?? cover_image?.formats?.thumbnail}
                                            extra={index >= self?.length - 3 ? `margin-bottom: 0 !important;` : `margin-bottom: 105px;`}
                                        />
                                    </RowWrapper>
                                );
                            })}
                        </RowWrapper>
                    </RowWrapper>
                </Container>
            )} */}
            {/* ABOUT */}
            <Container variant={`light`}>
                <Frame extra={`gap: 2rem;`}>
                    <Frame
                        extra={css`
                            align-items: flex-start;
                        `}
                    >
                        <ShortInfoHeading>{mainPage?.about_heading}</ShortInfoHeading>
                        <ShortInfoDescription>{mainPage?.about_text}</ShortInfoDescription>
                    </Frame>

                    {/* MUSEUMS */}
                    <Museums.Wrapper>
                        {museums?.map?.((museum, index) => {
                            return (
                                <Frame
                                    key={index}
                                    extra={css`
                                        width: 100%;
                                        max-width: 400px;
                                        height: 469px;
                                        overflow: hidden;
                                        position: relative;
                                        border-radius: ${({ theme }) =>
                                            index % 2 === 0
                                                ? `0 ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm}`
                                                : `${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm} 0`};

                                        @media (max-width: 1024px) {
                                            flex-wrap: wrap;
                                        }
                                    `}
                                >
                                    <Image src={museum?.museum_preview_picture} alt={museum?.museum_preview_picture?.alternativeText} />

                                    <Museums.Title index={index}>
                                        <SeeMore
                                            to={`/museum/${museum?.alias}`}
                                            extra={css`
                                                flex-direction: column;
                                                align-items: flex-start;
                                            `}
                                        >
                                            {museum?.name}
                                        </SeeMore>
                                    </Museums.Title>
                                </Frame>
                            );
                        })}
                    </Museums.Wrapper>
                </Frame>
            </Container>
            {/* NEWS */}
            {news.length > 0 && (
                <Container variant={`dark`} extra={`align-items: flex-start;`} id="news">
                    <NewsBlock dictionary={dictionary} news={news} variant={`dark`} />
                </Container>
            )}
            {/* EVENTS */}
            {events.length > 0 && (
                <Container variant={`dark`} extra={`align-items: flex-start;`} id="events">
                    <EventsBlock dictionary={dictionary} events={events} variant={`dark`} />
                </Container>
            )}
            {/* CONTACTS */}
            <Container variant={`dark`} extra={`align-items: flex-start;`}>
                <MuseumContactBlock light contact={mainPage?.contact} sendMessageText={dictionary?.send_message_button} />
            </Container>
        </Page>
    );
};

const Museums = {
    Title: styled(Frame)`
        font-weight: normal;
        font-size: 24px;
        line-height: 170%;
        color: ${({ theme }) => theme.text.secondary};
        width: 100%;
        height: 139px;
        background: ${({ theme }) => theme.background.light};
        position: absolute;
        bottom: 0;
        border-radius: 0px 60px;
        box-sizing: border-box;
        padding: 0px 19px 0px 26px;
        align-items: flex-start;
        cursor: pointer;
        gap: 20px 0px;
        border-radius: ${({ theme, index }) =>
            index % 2 === 0
                ? `0 ${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm}`
                : `${theme.borderRad.primarySm} 0 ${theme.borderRad.primarySm} 0`};

        a div {
            display: none;
        }

        &:hover {
            height: 239px;
            background: ${({ theme }) => theme.background.primary};
            div {
                display: flex;
            }
        }

        @media (max-width: 425px) {
            font-size: 20px;
        }

        ${({ extra }) => extra}
    `,

    Wrapper: styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 0.5rem;
        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    `,
};

// const Slider = {
//     Image: styled(Frame)`
//         max-width: 500px;
//         height: 374px;
//         width: 100%;
//         background: url("${({ src = {} }) => `${BASE_URL}${src?.url}`}") no-repeat center center / cover;
//         border-radius: 80px 0px;
//     `,
// };

export default HomePage;
/*eslint-enable*/
