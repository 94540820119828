/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { Button, Frame, Dropdown, RowWrapper, Image } from "../ui-kit/styled-templates";
import MobileMenu from "./mobile-menu";

import { putStorage, useStorageListener } from "../../hooks/useStorage";
import useLocale from "../../hooks/useLocale";
import { BASE_URL } from "../../constants/config";
import { linkTo, path } from "../../utils/common-helper";
import { array } from "yup";
import { set } from "lodash";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import MuseumsAPI from "../../api/museums-api";

const Header = () => {
    const { pathname } = useLocation();
    const { locale, setLocale } = useLocale();

    const [mobileMenuState, setMobileMenuState] = useState(false);
    const [menuItemToggle, setMenuItemToggle] = useState(null);
    const [languageItemToggle, setLanguageItemToggle] = useState(false);

    // console.log(`menuItemToggle`, menuItemToggle);
    // console.log(navBar?.navbar_with_items?.menu_items?.map((i) => (i = false)));

    const closeMobileMenu = () => {
        const body = document.querySelector("body");

        body.style.overflowY = "auto";
        setMobileMenuState(false);
    };

    useEffect(() => {
        MuseumsAPI.getNavBar();
    }, [locale]);

    const navBar = useStorageListener((state) => state?.["navbar"] ?? {});

    return (
        <HeaderWrapper>
            <HeaderContainer>
                <Link to={`/`} onClick={() => closeMobileMenu()} aria-label="Link to home page">
                    <Image
                        src={navBar?.navbar_with_items?.logo}
                        alt={navBar?.navbar_with_items?.logo?.alternativeText}
                        extra={css`
                            @media only screen and (max-width: 768px) {
                                height: 40px;
                            }
                        `}
                    />
                </Link>
                <RowWrapper
                    extra={css`
                        ${!mobileMenuState
                            ? css`
                                  @media (max-width: 770px) {
                                      position: absolute;
                                      top: -100%;
                                      display: none;
                                  }
                              `
                            : css`
                                  @media (max-width: 770px) {
                                      height: 100vh;
                                      width: 100vw;
                                      display: flex;
                                      background: ${({ theme }) => theme.background.primary};
                                      position: fixed;
                                      top: 0%;
                                      left: 0;
                                      padding: 70px 15px 30px;
                                      box-sizing: border-box;
                                      overflow-y: scroll;
                                  }
                              `}
                    `}
                >
                    <RowWrapper
                        extra={css`
                            width: 100%;
                            height: max-content;
                            flex-wrap: wrap;
                            gap: 0 1rem;
                            justify-content: center;

                            a {
                                color: ${({ theme }) => theme.text.secondary};
                            }
                            a.active {
                                color: ${({ theme }) => theme.text.selected};
                            }

                            @media (max-width: 1210px) {
                                justify-content: center;
                            }
                            @media (max-width: 770px) {
                                align-items: flex-start;
                                flex-direction: column;
                                gap: 40px 0px;
                            }
                        `}
                    >
                        {navBar?.navbar_with_items?.menu_items?.map?.((navBarItem, index) => {
                            if (typeof navBarItem.dropdown_menu_items !== "undefined") {
                                return (
                                    <HeaderDropdown
                                        key={index}
                                        wrapperStyles={css`
                                            width: min-content;
                                            @media (max-width: 770px) {
                                                max-width: 100%;
                                                width: 100%;
                                            }
                                        `}
                                        toggle={<MenuItem>{navBarItem.title}</MenuItem>}
                                        onClickOutside={() => {
                                            setMenuItemToggle(null);
                                        }}
                                        menuStyles={css`
                                            top: 40px;
                                            left: 0;
                                            min-width: max-content !important;

                                            @media (max-width: 770px) {
                                                padding: 0;
                                                padding-left: 20px;
                                                background: none;
                                                position: relative;
                                                top: 0;
                                            }
                                        `}
                                        toggleStyles={`color:#E9BC7C;`}
                                        menu={
                                            <>
                                                {navBarItem.dropdown_menu_items.map((dropDownItem, index) => {
                                                    return (
                                                        <Link to={`${dropDownItem.url}`} key={index} onClick={() => closeMobileMenu()}>
                                                            <DropdownItem
                                                                selected={
                                                                    dropDownItem.url === pathname
                                                                        ? true
                                                                        : dropDownItem.url.slice(1, dropDownItem.url.length) ===
                                                                          window.location.href.split("/").slice(-1)[0]
                                                                        ? true
                                                                        : false
                                                                }
                                                                icon={dropDownItem.url === pathname ? dropDownItem.icon_selected : dropDownItem.icon}
                                                                onClick={() => {
                                                                    setMenuItemToggle(null);
                                                                    setMobileMenuState(false);
                                                                }}
                                                            >
                                                                {dropDownItem.title}
                                                            </DropdownItem>
                                                        </Link>
                                                    );
                                                })}
                                            </>
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <NavLink to={`${navBarItem.url}`} key={index} onClick={() => closeMobileMenu()}>
                                        <MenuItem>{navBarItem.title}</MenuItem>
                                    </NavLink>
                                );
                            }
                        })}
                    </RowWrapper>
                    {/* Language DropDown */}
                    <HeaderDropdown
                        key={"lang"}
                        wrapperStyles={css`
                            margin-left: 20px;
                            position: relative;
                            width: min-content;
                            @media (max-width: 770px) {
                                position: fixed;
                                top: 2%;
                                left: 4%;
                                z-index: 10;
                                border-radius: unset;
                                margin-left: 0px;
                            }
                        `}
                        menuStyles={css`
                            top: 40px;
                            left: 0;
                            min-width: min-content !important;
                            border-radius: 0 0 20px 20px;

                            /* @media only screen and (max-width: 425px) {
                                position: absolute;
                                top: 0;
                                left: 0;
                            } */
                        `}
                        onClickOutside={() => {
                            setMenuItemToggle(null);
                            setLanguageItemToggle(false);
                        }}
                        toggle={<MenuItem>{locale.toUpperCase()}</MenuItem>}
                        toggleStyles={css`
                            color: #e9bc7c;
                        `}
                        menu={
                            <>
                                {[`en`, `lv`, `ru`].map((lang, index) => {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={() => {
                                                setLocale(lang);
                                                setLanguageItemToggle(false);
                                            }}
                                            selected={locale === lang}
                                        >
                                            {lang.toUpperCase()}
                                        </DropdownItem>
                                    );
                                })}
                            </>
                        }
                    />
                </RowWrapper>
                <MobileMenu
                    state={mobileMenuState}
                    onClick={() => {
                        // To stop scroll when mobile menu open
                        const body = document.querySelector("body");

                        if (mobileMenuState) {
                            body.style.overflowY = "auto";
                        } else {
                            body.style.overflowY = "hidden";
                        }

                        setMobileMenuState(!mobileMenuState);
                        setMenuItemToggle(null);
                    }}
                />
            </HeaderContainer>
        </HeaderWrapper>
    );
};

export const HeaderDropdown = styled(Dropdown).attrs((props) => {
    return {
        ...props,
        menuStyles: css`
            border: 0px;
            border-radius: 0 0 20px 20px;
            min-width: 220px !important;
            width: 100%;
            /* max-width: 320px !important; */
            align-items: flex-start;
            padding: 20px;
            box-sizing: border-box;
            left: calc(40px);
            background: ${({ theme }) => theme.background.light};

            a {
                width: 100%;
            }

            ${props.menuStyles ?? ``}
        `,
    };
})``;

export const DropdownItem = styled(Frame)`
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 38px;
    margin: 10px 0;
    flex-direction: row;
    cursor: pointer;
    gap: 0px 30px;

    flex-direction: row;
    justify-content: flex-start;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;

    ${({ selected = false, theme = {} }) =>
        selected &&
        css`
            color: ${theme.text.selected};
        `}

    &:hover {
        transform: translate(2px, 0);
    }

    ${({ icon }) =>
        icon &&
        css`
            &:before {
                content: "";
                width: 50px;
                height: 50px;
                /* margin-right: 30px; */
                background: url("${`${BASE_URL}${icon}`}") no-repeat center center / contain;
                display: block;
                @media (max-width: 425px) {
                    width: 40px;
                    height: 40px;
                }
            }
        `}
    @media (max-width: 770px) {
        font-size: 14px;
        line-height: 140%;
        gap: 0px 15px;
    }
`;

export const MenuItem = styled(Frame)`
    color: inherit;
    font-weight: 500;
    line-height: 22px;
    flex-direction: row;
    cursor: pointer;
    padding: 10px 5px 10px;
    box-sizing: border-box;
    width: max-content;
    text-transform: capitalize;
`;

const HeaderContainer = styled(RowWrapper)`
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    gap: 1rem;
`;

const HeaderWrapper = styled.header`
    width: 100%;
    min-height: 100px;
    background: ${({ theme }) => theme.background.primary};
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    z-index: 12;
    padding: 0 1rem;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        height: 70px;
        min-height: unset;
        padding: 1rem;
    }
`;

export default Header;
/*eslint-enable*/
