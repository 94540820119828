/*eslint-disable*/
import React from "react";
import M from "markdown-to-jsx";

const Markdown = ({ children = `` }) => {
    children
        ?.match?.(/@video \S*/g)
        ?.map?.((i) => i.replace(/@video /g, ``))
        ?.map?.((link) => ({
            link,
            id: link?.match?.(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/)?.[7],
            md: `[![]()](${link})`,
        }))
        ?.forEach?.((i) => {
            // https://youtu.be/l6hks1x0Fjw
            // children = children.replaceAll(`@video ${i.link}`, `[![IMAGE ALT TEXT HERE](https://img.youtube.com/vi/l6hks1x0Fjw/0.jpg)](https://www.youtube.com/watch?v=l6hks1x0Fjw)`);
            children = children.replaceAll(
                `@video ${i.link}`,
                `<iframe width="740" height="460" src="https://www.youtube.com/embed/${i.id}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            );
        });
    children = children?.replaceAll?.(`/uploads/`, `https://strapi.ventspils.sabir.pro/uploads/`);

    return <M>{children ?? ``}</M>;
};

export default Markdown;
/*eslint-enable*/
