/*eslint-disable*/
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";

import { Frame, H1 } from "../ui-kit/styled-templates";
import PopUpWrapper from "./pop-up-wrapper";

import { MODALS } from "../../constants/config";

import useModal from "../../hooks/useModal";
import { useStorageListener } from "../../hooks/useStorage";

const PedagogicalModal = () => {
    const state = useStorageListener((state) => state?.modals?.PEDAGOGICAL_MODAL?.state ?? {});
    return (
        <PopUpWrapper
            name={MODALS.PEDAGOGICAL_MODAL}
            extra={`img { max-width: 500px; width: 100%; }; justify-content: unset; align-items: unset; margin: auto;`}
        >
            <H1 serif extra={`text-align: center;`}>
                {state?.title}
            </H1>

            <Frame
                extra={css`
                    max-height: 500px;
                    overflow: auto;
                    justify-content: flex-start;
                `}
            >
                <Markdown>{state?.text}</Markdown>
            </Frame>
        </PopUpWrapper>
    );
};

export default PedagogicalModal;
/*eslint-enable*/
