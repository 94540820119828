import React from "react";
import { GallerArrow, PhotoItem, SectionHeader } from "../pages/museum-page";
import { Frame, Image, RowWrapper } from "../ui-kit/styled-templates";
import styled, { css } from "styled-components";
import { BASE_URL } from "../../constants/config";

const GalleryBlock = ({ dictionary = {}, galleryPagination = {}, variant = "light" }) => {
    return (
        <>
            <SectionHeader serif variant={variant}>
                {dictionary?.gallery_heading}
            </SectionHeader>
            <Frame>
                <GalleryWrapper>
                    {galleryPagination?.visibleItems?.map?.(({ photo = {}, name = `` }, index, self) => {
                        return (
                            <PhotoItem.Wrapper
                                key={index}
                                onClick={() => {
                                    // ModalsHelper.showModal(MODALS.GALLERY_MODAL, {
                                    //     photos: galleryPagination?.items,
                                    //     current: galleryPagination.currentPage * galleryPagination.perPage + index,
                                    // });
                                }}
                            >
                                <Image src={photo} />
                                <span>{name}</span>
                            </PhotoItem.Wrapper>
                        );
                    })}
                </GalleryWrapper>
                <RowWrapper extra={`justify-content: center; gap: 1rem;`}>
                    <GallerArrow
                        visible={!galleryPagination.isFirstPage}
                        onClick={galleryPagination.handlePrevPage}
                        extra={css`
                            transform: rotate(180deg);
                            transform-origin: center;

                            @media (max-width: 768px) {
                                width: 70px;
                                height: 70px;
                            }
                            @media (max-width: 425px) {
                                width: 60px;
                                height: 60px;
                            }
                        `}
                    />
                    <GallerArrow
                        visible={!galleryPagination.isLastPage}
                        onClick={galleryPagination.handleNextPage}
                        extra={css`
                            @media (max-width: 768px) {
                                width: 70px;
                                height: 70px;
                            }

                            @media (max-width: 425px) {
                                width: 60px;
                                height: 60px;
                            }
                        `}
                    />
                </RowWrapper>
            </Frame>
        </>
    );
};

const GalleryWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: stretch;
    gap: 0rem 1rem;
    width: 100%;

    @media only screen and (min-width: 430px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    @media only screen and (min-width: 770px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    @media only screen and (min-width: 1026px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

export default GalleryBlock;
