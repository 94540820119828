/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import moment from "moment-timezone";

import { Frame, RowWrapper, Container, Dropdown } from "../ui-kit/styled-templates";
import { HeaderDropdown, DropdownItem, MenuItem } from "../tools/header";
// import { NewsItem } from "./museum-page";

import { BASE_URL } from "../../constants/config";
import { linkTo } from "../../utils/common-helper";

import { useStorageListener } from "../../hooks/useStorage";
import usePagination from "../../hooks/usePagination";
import MuseumsAPI from "../../api/museums-api";
import NewsItem from "../ui-kit/newsItem";
import useLocale from "../../hooks/useLocale";

const NewsPage = () => {
    const { locale } = useLocale();
    useEffect(() => {
        MuseumsAPI.getMuseumsNewsData();
    }, [locale]);

    const news = useStorageListener((state) => state?.news ?? []);
    const museums = useStorageListener((state) => state?.museums ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});

    const [seeMore, setSeeMore] = useState(3);
    const [search, setSearch] = useState(``);
    const [category, setCategory] = useState();

    useEffect(() => {
        setSeeMore(Math.max(Math.min(news.length, seeMore), 3));
    }, [seeMore]);

    // console.log(news);

    let enhNews = news.map((x) => ({ ...x, t: +new Date(x.pub_date) }));
    let futureNews = enhNews.filter((x) => +x.t > +new Date()).sort((a, b) => +b.t - +a.t);

    // console.log('futuew news = ', futureNews);

    let pastNews = enhNews.filter((x) => +x.t < +new Date()).sort((a, b) => +b.t - +a.t);
    let goodNews = futureNews.concat(pastNews);

    // console.log('good news = ', goodNews);

    return (
        <>
            <Container variant={`light`} extra={`align-items: flex-start;`}>
                <Frame extra={`gap: 128px 0px; width: 100%;`}>
                    <RowWrapper
                        extra={css`
                            gap: 30px 48px;
                            @media (max-width: 1438px) {
                                justify-content: center;
                                flex-wrap: wrap;
                            }
                        `}
                    >
                        <News.Search>
                            <News.SearchIcon></News.SearchIcon>
                            <input
                                type="text"
                                placeholder={`${dictionary?.search_field_placeholder}`}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            ></input>
                        </News.Search>
                        <Frame
                            extra={css`
                                height: 58px;
                                max-width: 346px;
                                width: 100%;
                                background: #eee2d1;
                                border-radius: 5px;
                                padding: 0px 15px;
                            `}
                        >
                            <News.Category
                                customToggleArrow
                                toggle={
                                    <News.CategoryMenuItem selectable>
                                        {museums?.find?.((i) => i?.alias === category)?.name ?? dictionary?.filter_field_placeholder}
                                    </News.CategoryMenuItem>
                                }
                                menu={museums?.map?.((museum, index) => {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={() => {
                                                setCategory(museum.alias === category ? `` : museum.alias);
                                                setSeeMore(3);
                                            }}
                                        >
                                            {museum.name}
                                        </DropdownItem>
                                    );
                                })}
                                menuStyles={({ theme }) => css`
                                    width: 100%;
                                    left: 0;
                                    @media (max-width: 425px) {
                                        padding: 0;
                                        padding-left: 20px;
                                        background: ${theme.background.light};
                                        position: absolute;
                                        top: 24px;
                                    }
                                `}
                                wrapperStyles={`width: 100%;`}
                            ></News.Category>
                        </Frame>
                        {/* <News.Button>{dictionary?.search_button}</News.Button> */}
                    </RowWrapper>
                    <News.Wrapper>
                        {goodNews
                            ?.filter?.((i) => (search ? i?.title?.toLowerCase?.()?.includes?.(search?.toLowerCase()) : true))
                            ?.filter?.((i) => (category ? i?.museum?.alias === category : true))
                            ?.slice(0, seeMore)
                            ?.map?.((newsItem, index) => {
                                console.log(`newsItem ==>`, newsItem);
                                return (
                                    <NewsItem newsItem={newsItem} key={newsItem?.id} />
                                    // <Link key={index} to={`/news/article/${newsItem?.id}`}>
                                    //     <NewsItem.Wrapper url={newsItem?.cover_image?.formats?.small?.url}>
                                    //         <NewsItem.InnerWrapper>
                                    //             <RowWrapper extra={`align-items: flex-start; justify-content: flex-start;`}>
                                    //                 <NewsItem.PubDate>
                                    //                     <span>{moment(newsItem?.pub_date).format(`DD`)}</span>
                                    //                     <span>{moment(newsItem?.pub_date).format(`MMM`)}</span>
                                    //                 </NewsItem.PubDate>
                                    //                 <Frame extra={`align-items: flex-start;`}>
                                    //                     <NewsItem.MuseumName>{newsItem?.museum?.name}</NewsItem.MuseumName>
                                    //                     <NewsItem.Title>{newsItem?.title}</NewsItem.Title>
                                    //                 </Frame>
                                    //             </RowWrapper>
                                    //             <NewsItem.ContentWrapper extra={`width:100%;`}>
                                    //                 <Markdown>{newsItem?.description?.slice(0, 150) + "[...]"}</Markdown>
                                    //             </NewsItem.ContentWrapper>
                                    //         </NewsItem.InnerWrapper>
                                    //     </NewsItem.Wrapper>
                                    // </Link>
                                );
                            })}
                    </News.Wrapper>
                    <Frame
                        extra={
                            seeMore >=
                            news
                                ?.filter?.((i) => (search ? i?.title?.toLowerCase?.()?.includes?.(search?.toLowerCase()) : true))
                                ?.filter?.((i) => (category ? i?.museum?.alias === category : true)).length
                                ? `display: none;`
                                : `width:100%;`
                        }
                        onClick={() => {
                            setSeeMore(seeMore + 3);
                        }}
                    >
                        <News.Button>{dictionary?.see_more_button}</News.Button>
                    </Frame>
                </Frame>
            </Container>
        </>
    );
};

export const News = {
    Wrapper: styled.div`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        gap: 1.2rem;

        a {
            width: 100%;
        }

        @media only screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 770px) {
            grid-template-columns: repeat(1, 1fr);
        }
    `,
    Button: styled(Frame)`
        height: 58px;
        max-width: 216px;
        width: 100%;
        background: ${({ theme }) => theme.background.primary};
        border-radius: 5px;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        color: ${({ theme }) => theme.text.secondary};
        cursor: pointer;
    `,
    Search: styled(Frame)`
        height: 58px;
        /* max-width: 872px; */
        min-width: 300px;
        width: 100%;
        background: #eee2d1;
        border-radius: 5px;
        padding: 0px 20px 0px 48px;
        box-sizing: border-box;
        position: relative;
        input[type="text"] {
            width: 100%;
            background: none;
            outline: none;
            border: none;
            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: ${({ theme }) => theme.text.primary};
                font-size: 14px;
                line-height: 24px;
                opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: ${({ theme }) => theme.text.primary};
                font-size: 14px;
                line-height: 24px;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: ${({ theme }) => theme.text.primary};
                font-size: 14px;
                line-height: 24px;
            }
        }
    `,
    SearchIcon: styled(Frame)`
        width: 19px;
        height: 19px;
        background: url("${require(`../../assets/icons/Search.svg`).default}") no-repeat center center / contain;
        position: absolute;
        top: 20px;
        left: 19px;
    `,
    Category: styled(HeaderDropdown)`
        width: 100%;
    `,
    CategoryMenuItem: styled(MenuItem)`
        color: ${({ theme }) => theme.text.primary} !important;
        width: 100%;
        justify-content: space-between;
        margin: 0;
        font-weight: 400;

        /* ${({ selectable = false }) =>
            selectable &&
            css`
                &:after {
                    content: "";
                    color: ${({ theme }) => theme.text.primary} !important;
                    width: 24px;
                    height: 24px;
                    background: url("${require(`../../assets/icons/news-arrow-down.svg`).default}") no-repeat center center / contain;
                    margin-left: 5px;
                }
            `} */
    `,
};

export default NewsPage;
/*eslint-enable*/
