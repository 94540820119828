/*eslint-disable*/
import React from "react";
import styled, { css } from "styled-components";

import { RowWrapper, Frame, Dropdown } from "../ui-kit/styled-templates";

const MobileMenu = ({ state, onClick }) => {
    let openStatus = ``;
    state === false ? (openStatus = `open`) : (openStatus = `close`);
    return <Menu.Wrapper openStatus={openStatus} onClick={onClick}></Menu.Wrapper>;
};

const Menu = {
    Wrapper: styled.div`
        display: none;
        height: 32px;
        width: 32px;
        cursor: pointer;
        position: absolute;
        top: 25%;
        right: 4%;
        ${({ openStatus }) =>
            `background: url("${require(`../../assets/icons/mobile-menu-${openStatus}-icon.svg`).default}") no-repeat center center / contain`};
        @media (max-width: 770px) {
            display: initial;
        }
    `,
};

export default MobileMenu;
/*eslint-enable*/
