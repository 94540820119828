/*eslint-disable*/
const theme = {
    background: {
        primary: `#523634`,
        secondary: `#DDC5A2`,
        light: `#86554C`,
        topBannerSubtext: `rgba(221, 197, 162, 0.5)`,
    },

    borderColor: { primary: `#FFFFFF` },

    text: {
        primary: `#262626`,
        secondary: `#FFFFFF`,
        selected: `#E9BC7C`,
    },

    font: {
        railwayHistoryBottomHeader: `clamp(1.5rem, 2.5vw, 2rem)`,
        railwayHistoryBottomSubHeader: `clamp(1.2rem, 2.5vw, 1.7rem)`,
        topBannerName: `clamp(2rem, 5vw, 6rem)`,
        header: `clamp(1.5rem, 2.5vw, 3rem)`,
        headerSm: `clamp(1.1rem, 2vw, 1.5rem)`,
        seeMoreBtn: `clamp(1rem, 2.5vw, 1.5rem)`,
        railwayObjectsTitle: `1.5rem`,
        railwayObjectsTitleSm: `1.2rem`,
        paragraph: `clamp(1rem, 2.5vw, 1.1rem)`,
        paragraphLh: `140%`,
        objectives: `1.5rem`,
        objectivesSm: `1.5rem`,
        services: `1.5rem`,
        servicesSm: `1.5rem`,
        servicesDesc: `1rem`,
        servicesDescSm: `1rem`,
        contacts: `1.5rem`,
        contactsLh: `140%`,
        railwayHistoryTitle: `2rem`,
        railwayHistoryTitleSm: `1.75rem`,
        projectGroupHeader: `clamp(1.5rem, 2vw, 3rem)`,
        railwayScheduleTh: `clamp(1.2rem, 2vw, 1.5rem)`,
        articleMainHeader: `clamp(1.5rem, 2vw, 3rem)`,
    },

    borderRad: {
        primary: `6.25rem`,
        primarySm: `3.125rem`,
        small: `1rem`,
    },

    shadow: {
        small: `box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);`,
    },
};

export default theme;
/*eslint-enable*/
