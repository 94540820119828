/*eslint-disable*/
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";

import { Frame, RowWrapper, Container, Dropdown, Image } from "../ui-kit/styled-templates";
import { Socials, A } from "../tools/footer";
import NewsItem from "../ui-kit/newsItem";

import { BASE_URL } from "../../constants/config";

import { useStorageListener } from "../../hooks/useStorage";
import useLocale from "../../hooks/useLocale";
import MuseumsAPI from "../../api/museums-api";
import { dateLocales } from "../../constants/i18n";
import { SectionHeader } from "../pages/museum-page";

const NewsArticlePage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const { locale } = useLocale();

    useEffect(() => {
        MuseumsAPI.getMuseumsNewsData();
        MuseumsAPI.getTicketLinks();
        MuseumsAPI.getBookTickets();
        MuseumsAPI.getSocialMedias();
    }, [locale]);

    const news = useStorageListener((state) => state?.news ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const socialMedias = useStorageListener((state) => state?.["social-medias"] ?? []);
    const newsFiltered = _.find(news, { id: +id }) ?? {};

    return (
        <>
            <Container extra={`background: none; padding:1rem 0; position:relative; z-index:0;`}>
                <Image
                    src={newsFiltered?.cover_image}
                    alt={newsFiltered?.cover_image?.alternativeText}
                    extra={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        width: 100%;
                        max-width: unset;
                    `}
                />
                <Frame
                    extra={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.6);
                        z-index: 1;
                    `}
                />
                <Frame
                    extra={css`
                        min-height: 360px;
                        justify-content: flex-end;
                        align-items: flex-start;

                        gap: 1rem;
                        z-index: 2;
                        position: relative;
                    `}
                >
                    <Article.Title serif>{newsFiltered?.title}</Article.Title>

                    <RowWrapper
                        extra={css`
                            align-items: flex-start;
                            flex-wrap: wrap;
                            gap: 1rem;
                        `}
                    >
                        <Article.MuseumName>{newsFiltered?.museum?.name}</Article.MuseumName>
                        <Article.MuseumName>
                            {new Date(newsFiltered?.pub_date).toLocaleString(dateLocales[locale], { month: "long" }) +
                                " " +
                                new Date(newsFiltered?.pub_date).toLocaleString(dateLocales[locale], { day: "2-digit" }) +
                                "/" +
                                new Date(newsFiltered?.pub_date).toLocaleString(dateLocales[locale], { year: "numeric" })}
                        </Article.MuseumName>
                    </RowWrapper>
                </Frame>
            </Container>
            <Container variant={`light`}>
                <Frame
                    extra={css`
                        max-width: 1000px;
                        width: 100%;
                        margin-bottom: 56px;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 0.3px;
                        div {
                            width: 100%;
                        }
                        div > p > img {
                            max-width: 100%;
                        }
                    `}
                >
                    <Markdown>{newsFiltered?.content}</Markdown>
                </Frame>
                <RowWrapper
                    extra={`max-width: 205px; width:100%; font-weight: bold; font-size: 12px; line-height: 48px; margin-bottom: 177px; gap: 0px 20px;`}
                >
                    <Frame>{dictionary?.share_button}</Frame>
                    <Frame extra={`flex-direction: row; gap: 0px 26px;`}>
                        {socialMedias?.map((social, index) => {
                            if (social?.social_media_url.split("https://")[1].includes("facebook")) {
                                return (
                                    <FacebookShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                        <Socials icon={social?.icon_brown_news} />
                                    </FacebookShareButton>
                                );
                            } else if (social?.social_media_url.split("https://")[1].includes("twitter")) {
                                return (
                                    <TwitterShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                        <Socials icon={social?.icon_brown_news} as={A} href={social?.social_media_url} target={"_blank"} />
                                    </TwitterShareButton>
                                );
                            } else if (social?.social_media_url.split("https://")[1].includes("instagram")) {
                                return null;
                            } else if (social?.social_media_url.split("https://")[1].includes("draugiem")) {
                                return null;
                            } else {
                                return (
                                    <EmailShareButton url={social?.social_media_museum_url} quote={``} key={index}>
                                        <Socials icon={social?.icon_brown_news} />
                                    </EmailShareButton>
                                );
                            }
                        })}
                    </Frame>
                </RowWrapper>
                <Frame extra={`align-items:flex-start;`}>
                    <SectionHeader serif variant={`light`}>
                        {dictionary?.other_news_heading}
                    </SectionHeader>
                    <NewsBlocWrapper>
                        {news?.slice(0, 3)?.map?.((newsItem) => {
                            return <NewsItem newsItem={newsItem} key={newsItem?.id} />;
                        })}
                    </NewsBlocWrapper>
                </Frame>
            </Container>
        </>
    );
};

const NewsBlocWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    a {
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const Article = {
    Wrapper: styled(Frame)`
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
            url("${({ src = {} }) => `${BASE_URL}${src?.url}`}") no-repeat center center / cover;
    `,
    Title: styled.h1`
        font-weight: normal;
        font-family: "Constantia";
        color: ${({ theme }) => theme.text.secondary} !important;
        font-style: normal;
        font-size: ${({ theme }) => theme.font.articleMainHeader};
        margin: 0;
    `,
    MuseumName: styled(Frame)`
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: ${({ theme }) => theme.text.primary} !important;
        background: ${({ theme }) => theme.background.secondary};
        box-sizing: border-box;
        padding: 4px 17px;
        border-radius: 10px;
    `,
};

export default NewsArticlePage;
/*eslint-enable*/
