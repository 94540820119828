/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Markdown from "../tools/markdown";
import CircledText from "../tools/circled-text";

import { Frame, RowWrapper, Container, Image } from "../ui-kit/styled-templates";
import { Page, SectionHeader, ShortInfoLeftImage, BottomButtonSVG, BottomButton } from "./museum-page";

import PricesBlock from "../museums-ui-blocks/prices-block";

import { useStorageListener } from "../../hooks/useStorage";
import RailwaySchedule from "../museums-ui-blocks/railway-schedule";
import OpeningHoursBlock from "../museums-ui-blocks/opening-hours-block";
import MuseumContactBlock from "../museums-ui-blocks/museum-contact-block";
import useLocale from "../../hooks/useLocale";
import MuseumsAPI from "../../api/museums-api";
import Button from "../ui-kit/button";

const ForVisitorsPage = () => {
    const { pathname } = useLocation();
    const { locale } = useLocale();
    const path = pathname.split(`/`).slice(1);

    useEffect(() => {
        MuseumsAPI.getForvisitors();
    }, [locale]);

    const museums = useStorageListener((state) => state?.museums ?? []);
    const dictionary = useStorageListener((state) => state?.dictionary ?? {});
    const forVisitors = useStorageListener((state) => state?.["for-visitors"] ?? {});
    const ticketLinks = useStorageListener((state) => state?.[`ticket-links`] ?? []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollBottom = scrollTop + window.innerHeight;
            const scrollHeight = document.body.scrollHeight;
            const scrollPercent = (scrollTop / (scrollHeight - window.innerHeight)) * 100;
            const isBottom = scrollPercent > 90;
            const isTop = scrollPercent < 10;
            if (isTop) {
                setbuttonBottomToggle(false);
            } else {
                setbuttonBottomToggle(true);
            }
        };
        window.addEventListener(`scroll`, handleScroll);
        return () => window.removeEventListener(`scroll`, handleScroll);
    }, []);

    const [buttonBottomToggle, setbuttonBottomToggle] = useState(false);

    const [priceToggle, setPriceToggle] = useState(false);

    // console.log(dictionary);
    return (
        <Page extra={`overflow: hidden;`}>
            <Container variant={`light`} extra={`align-items: flex-start;`}>
                <SectionHeader serif id="contacts">
                    {dictionary?.for_visitors_heading}
                </SectionHeader>

                <Frame
                    extra={({ theme }) =>
                        `color: ${theme.text.primary}; max-width: 950px; width: 100%; margin-bottom: 59px; font-weight: normal; font-size: 16px; line-height: 22px;`
                    }
                >
                    {forVisitors?.short_info}
                </Frame>

                <a href={`/for-visitors#${buttonBottomToggle ? `top` : `bottom`}`}>
                    <BottomButton>
                        <BottomButtonSVG extra={buttonBottomToggle ? `transform: rotate(180deg);` : ``}></BottomButtonSVG>
                    </BottomButton>
                </a>

                {/* FOR VISITORS CONTACTS */}
                <MuseumContactBlock
                    contact={forVisitors?.contacts?.filter((i) => i.museum === null)[0]}
                    sendMessageText={dictionary?.send_message_button}
                />
            </Container>

            {/* MUSEUMS */}
            {museums?.map?.((museum) => {
                const { id, name, alias, prices, railway_schedule_text, short_info_left_image } = museum;
                console.log(`museum ==>`, museum);
                return (
                    <React.Fragment key={id}>
                        <Container variant={`light`} extra={({ theme }) => ``} id={`${alias}`}>
                            <Frame
                                extra={({ theme }) =>
                                    css`
                                        width: 100%;
                                        height: 91px;
                                        background: ${theme.background.primary};
                                        color: ${theme.text.secondary} !important;
                                        font-weight: normal;
                                        font-size: 60px;
                                        line-height: 48px;
                                        margin-bottom: 4rem;

                                        @media (max-width: 1438px) {
                                            min-height: 91px;
                                            height: auto;
                                            padding: 10px;
                                            box-sizing: border-box;
                                            text-align: center;
                                            line-height: 60px;
                                        }
                                        @media only screen and (max-width: 770px) {
                                            margin-bottom: 2rem;
                                        }

                                        @media (max-width: 425px) {
                                            font-size: 40px;
                                        }
                                    `
                                }
                                serif
                            >
                                {name}
                            </Frame>
                            {/* MUSEUM CONTACTS */}
                            <MuseumContactBlock contact={museum?.contact} sendMessageText={dictionary?.send_message_button} />
                        </Container>

                        {/* OPENING HOURS */}
                        {museum?.alias !== "railway" ? (
                            <OpeningHoursBlock museum={museum} openingHoursHeading={dictionary?.opening_hours_heading} />
                        ) : (
                            <Container variant={`light`} extra={`overflow: hidden; z-index: 2; padding-top: 50px !important; `}>
                                <SectionHeader serif variant={`light`}>
                                    {dictionary?.opening_hours_heading}
                                </SectionHeader>
                                <RowWrapper
                                    extra={css`
                                        position: relative;
                                        gap: 50px;
                                        flex-direction: column;

                                        @media (min-width: 1000px) {
                                            flex-direction: row;
                                            justify-content: center;
                                        }
                                    `}
                                >
                                    <Frame
                                        extra={({ theme }) =>
                                            css`
                                                font-weight: normal;
                                                font-size: ${theme.font.paragraph};
                                                font-family: "Playfair Display";

                                                background: ${theme.background.light};
                                                color: ${theme.text.secondary};
                                                border-radius: ${theme.borderRad.primarySm} ${theme.borderRad.primarySm} 0px 0px;
                                                box-sizing: border-box;
                                                padding: 1rem;
                                                z-index: 5;

                                                @media only screen and (min-width: 768px) {
                                                    padding: 3rem;
                                                    border-radius: ${theme.borderRad.primary} ${theme.borderRad.primary} 0px 0px;
                                                }

                                                p strong {
                                                    color: ${theme.text.selected};
                                                }

                                                em {
                                                    color: ${theme.text.selected};
                                                    font-size: ${theme.font.headerSm};
                                                }
                                            `
                                        }
                                    >
                                        <Markdown>{railway_schedule_text}</Markdown>
                                    </Frame>
                                    <Image
                                        src={short_info_left_image}
                                        alt={short_info_left_image?.alternativeText}
                                        extra={css`
                                            max-width: 495px;
                                            max-height: 650px;
                                        `}
                                    />
                                    <CircledText text={dictionary?.opening_hours_heading} top={800} left={0} radius={100} color={`#523634`} />
                                </RowWrapper>
                            </Container>
                        )}
                        {/* OPENING HOURS DORBES MORE BUTTON */}
                        {alias === "dorbes" && (
                            <Container
                                variant={`light`}
                                extra={css`
                                    height: max-content;
                                    min-height: unset !important;
                                    padding-top: 0;
                                `}
                            >
                                <Frame
                                    extra={css`
                                        align-items: center;
                                    `}
                                >
                                    <Button link to={`/museum/${alias}`} type="primary">
                                        {dictionary?.For_visitors_more_button}
                                    </Button>
                                </Frame>
                            </Container>
                        )}

                        {/* PRICES */}
                        <>
                            {alias === "railway" && <RailwaySchedule forVisitors={forVisitors} />}
                            <PricesBlock
                                museumAlias={alias}
                                museum={museum}
                                pricesHeading={dictionary?.prices_heading}
                                prices={prices}
                                priceToggle={priceToggle}
                                setPriceToggle={setPriceToggle}
                                publicationButtonName={dictionary?.publications_button}
                                buyButtonName={dictionary?.buy_button}
                                ticketLinks={ticketLinks}
                            />
                            {alias !== "dorbes" && (
                                <Frame extra={`width: 100%; min-height: 100px !important;`}>
                                    <ForVisitorsMoreButton>
                                        <Link to={`/museum/${alias}`}>{dictionary?.For_visitors_more_button}</Link>
                                    </ForVisitorsMoreButton>
                                </Frame>
                            )}
                        </>
                    </React.Fragment>
                );
            })}
        </Page>
    );
};

const ForVisitorsMoreButton = styled(Frame)`
    width: 216px;
    height: 58px;
    background: ${({ theme }) => theme.background.secondary};
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: ${({ theme }) => theme.background.primary};
    cursor: pointer;
    padding: 0px 10px;
    box-sizing: border-box;
    text-align: center;
    user-select: none;
    &:hover {
        color: ${({ theme }) => theme.text.secondary};
    }
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.background.primary};
        &:hover {
            color: ${({ theme }) => theme.text.secondary};
        }
    }
    ${({ extra }) => extra}
`;

export default ForVisitorsPage;
/*eslint-enable*/
