/*eslint-disable*/
import axios from "axios";

import { handleError, handleSuccess, loadingCounterWrapper, POSTOptions } from "../utils/api-helper";
import CaseHalper from "../utils/case-helper";

import { BASE_URL } from "../constants/config";
import { getStorage, mergeStorage, putStorage } from "../hooks/useStorage";
import { objectPut, downloadURI } from "../utils/common-helper";

const MuseumsAPI = {
    async getEvents() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/events`)).data;
                putStorage(`events`, response);
                return response;
            } catch (error) {
                putStorage(`events`, {});
                throw handleError(error);
            }
        });
    },
    async getMuseumsData() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/museums`)).data;
                putStorage(`museums`, response);
                return response;
            } catch (error) {
                putStorage(`museums`, {});
                throw handleError(error);
            }
        });
    },

    async getMuseumsNewsData() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/news`)).data;
                putStorage(`news`, response);
                return response;
            } catch (error) {
                putStorage(`news`, {});
                throw handleError(error);
            }
        });
    },

    async getPeople() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/people`)).data;
                putStorage(`people`, response);
                return response;
            } catch (error) {
                putStorage(`people`, {});
                throw handleError(error);
            }
        });
    },

    async getGallery() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/gallery-photos`)).data;
                putStorage(`gallery`, response);
                return response;
            } catch (error) {
                putStorage(`gallery`, {});
                throw handleError(error);
            }
        });
    },

    async getPublications() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/publications`)).data;
                putStorage(`publications`, response);
                return response;
            } catch (error) {
                putStorage(`publications`, {});
                throw handleError(error);
            }
        });
    },

    async getPrivacyPolicy() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/privacy-policy`)).data;
                putStorage(`policy`, response);
                return response;
            } catch (error) {
                putStorage(`policy`, {});
                throw handleError(error);
            }
        });
    },

    async getProjectsPage() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/projects-page`)).data;
                putStorage(`projects-page`, response);
                return response;
            } catch (error) {
                putStorage(`projects-page`, {});
                throw handleError(error);
            }
        });
    },

    async getProjects() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/projects`)).data;
                putStorage(`projects`, response);
                return response;
            } catch (error) {
                putStorage(`projects`, {});
                throw handleError(error);
            }
        });
    },

    async getAboutMuseum() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/about-museum`)).data;
                putStorage(`about-museum`, response);
                return response;
            } catch (error) {
                putStorage(`about-museum`, {});
                throw handleError(error);
            }
        });
    },

    async getVirtualMuseum() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/virtual-museum`)).data;
                putStorage(`virtual-museum`, response);
                return response;
            } catch (error) {
                putStorage(`virtual-museum`, {});
                throw handleError(error);
            }
        });
    },
    async getMainPage() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/main-page`)).data;
                putStorage(`main-page`, response);
                return response;
            } catch (error) {
                putStorage(`main-page`, {});
                throw handleError(error);
            }
        });
    },
    async getNavBar() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/navbar`)).data;
                putStorage(`navbar`, response);
                return response;
            } catch (error) {
                putStorage(`navbar`, {});
                throw handleError(error);
            }
        });
    },
    async getDictionary() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/dictionary`)).data;
                putStorage(`dictionary`, response);
                return response;
            } catch (error) {
                putStorage(`dictionary`, {});
                throw handleError(error);
            }
        });
    },
    async getForvisitors() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/for-visitors`)).data;
                putStorage(`for-visitors`, response);
                return response;
            } catch (error) {
                putStorage(`for-visitors`, {});
                throw handleError(error);
            }
        });
    },
    async getBookTickets() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/book-tickets`)).data;
                putStorage(`book-tickets`, response);
                return response;
            } catch (error) {
                putStorage(`book-tickets`, {});
                throw handleError(error);
            }
        });
    },
    async getTicketLinks() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/ticket-links`)).data;
                putStorage(`ticket-links`, response);
                return response;
            } catch (error) {
                putStorage(`ticket-links`, {});
                throw handleError(error);
            }
        });
    },
    async getSocialMedias() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${BASE_URL}/social-medias`)).data;
                putStorage(`social-medias`, response);
                return response;
            } catch (error) {
                putStorage(`social-medias`, {});
                throw handleError(error);
            }
        });
    },
};

export default MuseumsAPI;

/*eslint-enable*/
